import { useState ,createRef, useEffect, useRef} from "react";
import { Button, Card, Col, ListGroup, Row, Tooltip } from "react-bootstrap";
import { ToastError } from "../../../../../services/Toasts";
import { TransitionGroup,CSSTransition } from "react-transition-group";
import { AccountColumn, GenderColumn, OperationColumn, SubjectColumn, TagListColumn } from "../../../Elements/Elements";
import { ChangeHandler, ChangeInList, CheckEmpty, GenderCheckbox } from "../../../Elements/Functions";

export default function PollForm (props) {
  const [selectedOption] = useState(false)
  const [SocialList, setSocialList] = useState([])
  const [OperationList, setOperationList] = useState([])
  const [AccountList1, setAccountList1] = useState([])
  const [Options, setOptions] = useState(
    {
      operationId:null,
      socialAreas:[],
      tagCollectionId:null,
      trendMethodAddType:"",
      text:"",
      isNeedtextRepetition:false,
      privateRobotAccountId:null,
      countRepetition:0,
      robotAccountTweetType:"Poll",
      genderType:"FemaleMale",
    }
  );
    const [PollsList, setPollsList] = useState([
        {
            Text:"",
            id:0,
            nodeRef: createRef(null),
        }
,        {
            Text:"",
            id:0,
            nodeRef: createRef(null),
        },

  ]);
  const MaleCheckRef = useRef(null)
  const FemaleCheckRef = useRef(null)
    
    const AddOptions = (Number) =>{
      if(Number <4)
      {
        const NewPoll = 
        { 
        Text:"",
        id:PollsList.length,
        nodeRef: createRef(null),
        }
        setPollsList(PollsList => [...PollsList, NewPoll]);
      }
      else
      ToastError("Cannot add more than 4 options",4)
    }

    const RemoveOptions = (index,Number,keys) =>
    {
      
        if(Number >2) 
        {
          if(PollsList.length>1)
          {
            const ActionItemsList = [...PollsList];
            ActionItemsList.splice(index, 1);
            setPollsList(ActionItemsList);
            let newObject = {};
            for (let [key, value] of Object.entries(Options)) {
                if (key != keys) {
                    newObject[key] = value;
                }
            }
            setOptions(newObject);
          }
        }
      else
      ToastError("At least 2 Options is needed",2)
        
    }

    const SendPoll = () => {
      let TempObj ={} ;
      for (let i = 0; i < PollsList.length; i++) 
      {
        if(CheckEmpty(PollsList[i].Text,"Polls",i))
            Object.assign(TempObj, {["ansText"+(i+1)]: PollsList[i].Text});
        else
          return
      }
      setOptions({ ...Options, ...TempObj });
    }


    useEffect(() => {
      if(JSON.parse(localStorage.getItem("SocialList")) !== null )
          setSocialList(JSON.parse(localStorage.getItem("SocialList")))
      
        if(JSON.parse(localStorage.getItem("OperationList")) !== null )
          setOperationList(JSON.parse(localStorage.getItem("OperationList")))


          if(JSON.parse(localStorage.getItem("AccountsList")) !== null )
          setAccountList1(JSON.parse(localStorage.getItem("AccountsList")))
    }, [])
    return(
        <>
        <div className=" mobile-send-button">
        <Button onClick={()=>SendPoll()}>Send </Button>
        </div>
          <Card>
            <ListGroup>
              <div className="d-flex flex-row justify-content-between">
                  <h4>Poll Form</h4>
              </div>
              <textarea   dir="auto" className="form-control input-default rounded-lg text-black " onChange={(e) => ChangeHandler('text',e.target.value,setOptions,Options)} placeholder="Poll Text" rows="2"></textarea>
              <TransitionGroup>
                  <ListGroup.Item  className="rounded mb-2 mt-2 border " >
                    {PollsList.map((Tweet,index) =>(
                    <CSSTransition key={index}nodeRef={Tweet.nodeRef}timeout={500}classNames="item">
                      <div className='input-group mb-3 input-primary ' ref={Tweet.nodeRef}>
                        <div className='input-group-prepend'>
                          <span className='input-group-text'>{index+1}</span>
                        </div>
                        <input
                          type='text'
                          className='form-control'
                          placeholder={'Option'+(index+1)}
                          id={"Polls"+index}
                          onChange={(e) => ChangeInList(e,PollsList,setPollsList,index,999)}
                        />
                        {index !== 0 ? <div className="d-flex h-100 ml-2 pt-3" style={{gap:"0.5rem"}}>
                          <div onClick={()=>RemoveOptions(index,PollsList.length,("ansText" + (index+1)))} className="p-1 rounded-circle d-flex justify-content-center align-items-center c-pointer" style={{width:"20px",height:"20px",background:"#9c0606"}}>
                                          <i className="fa-light fa-xmark text-white"></i>
                          </div>
                          <div onClick={()=>AddOptions(PollsList.length)} className="p-1 rounded-circle d-flex justify-content-center align-items-center c-pointer" style={{width:"20px",height:"20px",background:"#026e31"}}>
                            <i className="fa-light fa-plus text-white"></i>
                          </div>
                        </div>:""}
                      </div>
                    </CSSTransition> 
                    ))}
                  </ListGroup.Item>
              </TransitionGroup>
            </ListGroup>
            <Row className="w-100 ml-0 pt-3 mt-4"  style={{borderTop:"1px solid #dbdbdb"}} >
              <OperationColumn OperationList={OperationList} Options={Options} setOptions={setOptions}/>
              <SubjectColumn SocialList={SocialList} Options={Options} setOptions={setOptions}/>
              <TagListColumn Options={Options} setOptions={setOptions}/>
            </Row>
            <Row className="w-100 ml-0 pt-3 mt-4 pb-3"  style={{borderTop:"1px solid #dbdbdb"}}>
              <AccountColumn AccountList={AccountList1} Options={setOptions} setOptions={setOptions} />
              <GenderColumn customID={1} CustomClass={"mt-1"} Options={Options} setOptions={setOptions} MaleRef={MaleCheckRef} FemaleRef={FemaleCheckRef} />
            </Row>
          </Card>
       <Row className="flex-row-reverse" style={{gap:"0.6rem"}}>
          <Button onClick={()=>SendPoll()}>Send </Button>
          <Button variant='outline-primary ' onClick={()=>console.log('object :>> ', Options)}>Clear </Button>
       </Row>
       </>
    )
}