import { CardWithTabs } from "../../Elements/Elements";
import ReportTweetForm from "./TweetsReport/ReportTweetForm";
import ReportAccountForm from "./AccountReport/ReportAccountForm";
import ReportHashtagForm from "./HashtagReport/ReportHashtagForm";
import React, { useEffect, useState } from "react";
import { AxiosGetRequest } from "../../../../services/Connections";
import { Urls } from "../../../../services/ServerandUrls";
import { ToastError } from "../../../../services/Toasts";
import TableElement from "../../FilteringTable/TableElment";
import { ReportColumns } from "./Columns";






export default function ReportContainer(props) {
	const [Loader, setLoader] = useState(false);
    const [ReportsData, setReportsData] = useState({
        acounts:[],
        hashtags:[],
        tweets:[]
    });
	const [RefreshData, setRefreshData] = useState(false);

    

      const tabData = [
        {
          name: 'Report Account',
          icon: 'user',
          content:<ReportAccountForm  RefreshData={RefreshData} setRefreshData={setRefreshData} />
        },
        {
            name: 'Report Tweet',
            icon: 'envelope',
            content:<ReportTweetForm  RefreshData={RefreshData} setRefreshData={setRefreshData}/>
        },
        {
            name: 'Report Hashtag',
            icon: 'hashtag',
            content:<ReportHashtagForm  RefreshData={RefreshData} setRefreshData={setRefreshData}/>
        },
        {
            name: 'Account List',
            icon: 'ballot',
            content:<TableElement DataList={ReportsData.acounts} Headers={ReportColumns.acounts} />
        },
        {
            name: 'Tweet List',
            icon: 'ballot',
            content: <TableElement DataList={ReportsData.tweets} 

            Headers={ReportColumns.tweets}/>
        }
        ,
        {
            name: 'Hashtag List',
            icon: 'ballot',
            content:<TableElement DataList={ReportsData.hashtags}

            Headers={ReportColumns.hashtags}/>
        }
    ]

    
    // useEffect(() => {
    //     setLoader(true);
    //     AxiosGetRequest(Urls.ReportOrder.views.acounts,[{"HeadKey":"pageSize","HeadValue":1500}]).then(response => {
    //         setLoader(false);
    //         if(response[0])
    //         {
    //             console.log('acounts ', response[1].data);
    //             setReportsData(
    //                 {
    //                 ...ReportsData,
    //                 acounts: response[1].data,
    //                 });
    //         }
    //         else
    //             ToastError("acounts Reports List didn't load")
    //         })
    //     AxiosGetRequest(Urls.ReportOrder.views.hashtags,[{"HeadKey":"pageSize","HeadValue":1500}]).then(response => {
    //     setLoader(false);
    //     if(response[0])
    //     {
    //         console.log('hashtags ', response[1].data);

    //         setReportsData({
    //             ...ReportsData,
    //             hashtags: response[1].data,
    //             });
    //     }
    //     else
    //         ToastError("hashtags Reports List didn't load")
    //     })
    //     AxiosGetRequest(Urls.ReportOrder.views.tweets,[{"HeadKey":"pageSize","HeadValue":1500}]).then(response => {
    //     setLoader(false);
    //     if(response[0])
    //     {
    //         console.log('tweets ', response[1].data);
    //         setReportsData({
    //             ...ReportsData,
    //             tweets: response[1].data,
    //             });
    // }
    //     else
    //         ToastError("tweets Reports List didn't load")
    //     })
      
    //   }, [RefreshData])
    useEffect(() => {
        setLoader(true);
    
        const fetchData = async (url, dataKey) => {
          try {
            const response = await AxiosGetRequest(url, [{ "HeadKey": "pageSize", "HeadValue": 1500 }]);
            setLoader(false);
            if (response[0]) {
              
              setReportsData((prevData) => ({
                ...prevData,
                [dataKey]: response[1].data,
              }));
            } else {
              ToastError(`${dataKey} Reports List didn't load`);
            }
          } catch (error) {
            console.error(`Error fetching ${dataKey} data:`, error);
            ToastError(`Error fetching ${dataKey} data`);
          }
        };
    
        fetchData(Urls.ReportOrder.views.acounts, 'acounts');
        fetchData(Urls.ReportOrder.views.hashtags, 'hashtags');
        fetchData(Urls.ReportOrder.views.tweets, 'tweets');
    
      }, [RefreshData]);

    return(
        <>
        <CardWithTabs tabData={tabData} ></CardWithTabs>
        </>
        
    )
}