import {format} from 'date-fns';
import { ColumnFilter } from '../../../FilteringTable/ColumnFilter';
import {TweetCOLUMNS} from "../../Tweets/TweetTable/Columns";
import {RTCOLUMNS} from "../../RetweetsWithComment/Columns";
import {HashtagCoverCOLUMNS , CoverCOLUMNS} from "../../Covers/Columns"
import {DirectCOLUMNS} from "../../DirectionMassage/Columns"
import {VotesCOLUMNS} from "../../PollsAndVotes/Columns"
import { RepliesCOLUMNS } from '../../Replies/Columns';


export const COLUMNS = [
	{
		Header : 'Name',
		Footer : 'Name',
		accessor: 'name',
		Filter: ColumnFilter,
	},
	{
		Header : 'Start Date ',
		Footer : 'Start Date ',
		accessor: 'startDate',
		Cell: ({ value }) => {return format(new Date(value), 'dd/MM/yyyy hh:mm a')},
		Filter: ColumnFilter,
	},
	{
		Header : 'Finish Date ',
		Footer : 'Finish Date ',
		accessor: 'endDate',
		Cell: ({ value }) => {return format(new Date(value), 'dd/MM/yyyy hh:mm a')},
		Filter: ColumnFilter,
	},
	{
		Header : 'Combined',
		Footer : 'Combined',
		accessor: 'combineBotOrder',
		Filter: ColumnFilter,
		Cell: ({ value }) => {return value ? "True":"False"},
	},
	{
		Header : 'Active',
		Footer : 'Active',
		accessor: 'isActive',
		Filter: ColumnFilter,
		Cell: ({ value }) => {return value ? "True":"False"},
	}
]

export const OperationDetailsItems = 
[
	{
		Title : 'Tweets',
		Icon : 'fab fa-twitter',
		accessor:"tweets"
	},
	{
		Title : 'Quotes',
		Icon : 'fa-solid fa-quote-left',
		accessor:"retweetWithComments"
	},
	{
		Title : 'Retweets',
		Icon : 'fa-solid fa-retweet',
		accessor:"supportTweets",
		CustomAccessor:"Retweet"
	},
	{
		Title : 'Likes',
		Icon : 'fa-solid fa-thumbs-up',
		accessor:"supportTweets",
		double:true,
		CustomAccessor:"Like"

	},
	{
		Title : 'Comments',
		Icon : 'fa-solid fa-comment',
		accessor:"comments"
	},
	{
		Title : 'Direct Masseges',
		Icon : 'fa-solid fa-user-plus',
		accessor:"directMasseges"
	},
	{
		Title : 'Support Hashtag',
		Icon : 'fa-solid fa-user-minus',
		accessor:"supportHashtags"
	},
	{
		Title : 'Votes',
		Icon : 'fa-solid fa-handshake',
		accessor:"votes"
	},
	{
		Title : 'Interactions',
		Icon : 'fa-solid fa-arrows-left-right',
		accessor:"votes"
	}
	
]

export const OperationDetailsHeaders =
{
	tweets:TweetCOLUMNS,
	supportTweets:CoverCOLUMNS,
	retweetWithComments:RTCOLUMNS,
	votes:VotesCOLUMNS,
	directMasseges:DirectCOLUMNS,
	supportHashtags:HashtagCoverCOLUMNS,
	comments:RepliesCOLUMNS
}
export const Pending = ["Created","Exported","Login"]
export const Errors = ["InternalError","AccuntLoginProblem","AccuntGetLimit","AccuntGetProblem","UnSuccessed", "ProblemInOtherOrder","OrderFristElementError","TwitterPageLoadError",]
export const Success = ["Success"]