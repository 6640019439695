import React,{ useEffect, useMemo } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination, useRowSelect} from 'react-table';
import { COLUMNS } from './Columns';
import { Button, Col,  Modal,  Row, Spinner,  } from 'react-bootstrap';
import { useState } from 'react';
import { ToasSucces, ToastError } from '../../../../services/Toasts';
import { AxiosDeleteRequest, AxiosPostRequest, AxiosPutRequest } from '../../../../services/Connections';
import { Urls } from '../../../../services/ServerandUrls';
import { CheckboxSelection1 } from '../../Elements/Elements';
import swal from 'sweetalert';
import { ChangeHandler } from '../../Elements/Functions';
import Select  from "react-select";



export const HashtagList = (props) => {
	const [show, setShow] = useState(false);
	const [Loader, setLoader] = useState(false);
	const [type, settype] = useState(0);
	const [SocialList, setSocialList] = useState([])
	const [RowData, setRowData] = useState({title:"",followFromHashtag:false,socialAreaId:""});
	const columns = useMemo( () => COLUMNS, [] )
	const data = useMemo( () => props.List, [props.List] )


	const tableInstance = useTable({
		columns,
		data,	
		initialState : {pageIndex : 0,pageSize:25},  
	}, useFilters, useGlobalFilter, usePagination,useRowSelect,
	(hooks)=>{
		hooks.visibleColumns.push((columns)=>{
			return [
				{
					id:"Counter",
					Header:({getToggleAllRowsSelectedProps}) => (
						<div style={{paddingTop:"2.5rem"}}>
						#
						</div>
					),
					Cell:({row}) => (
						<div>
							{row.index +1 }
						</div>
					),
					width:50
				},
				{
					id:"selection",
					Header:({getToggleAllRowsSelectedProps}) => (
						<CheckboxSelection1 {...getToggleAllRowsSelectedProps()}/>
					),
					Cell:({row}) => (
						<CheckboxSelection1 {...row.getToggleRowSelectedProps()}/>
					),
					width:50
				},
				...columns
			]
		})
	});
	
	const { 
		getTableProps, 
		getTableBodyProps, 
		headerGroups, 
		prepareRow,
		setPageSize,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		selectedFlatRows
	} = tableInstance
	

	const handleShow = (type,Data) => {
		setShow(true);
		if(type == 1 )
		{
		setRowData({title:"",followFromHashtag:false,socialAreaId:""});
		settype(0)
		}
		else if(type == 2)
		{
		setRowData(Data);
		settype(1)
		}

	};
	
	const handleClose = () => setShow(false);

	const handleChange = (Value,Type) => {
		setRowData(() => {
		  return {
			...RowData,
			[Type]: Value,
		  };
		});
	};

	const DeleteRow = () => {
	if(selectedFlatRows.length > 0){
		var IDList = []
		for (let i = 0; i < selectedFlatRows.length; i++) {
			IDList.push({id:selectedFlatRows[i].original.id})
		}
		swal((selectedFlatRows.length > 1 ? "Are you sure you want to delete selected rows ?":" Are you sure you want to delete selected row?"), {
			buttons: {
				cancel: "Cancel",
				delete: true,
				},
			},
			).then((value)=>{
			switch (value) 
			{
				case "delete":
					AxiosDeleteRequest(Urls.Inlfuencers.delete,IDList).then(response => {
						if(response[0])
						{
							ToasSucces("Hashtag has been deleted ");
							props.setRefreshData(!props.RefreshData)
						}
						else
							ToastError("Hashtag didn't deleted")
						})
				break;
				default:
					break;
			}
			
			})
			
	}
	else
		ToastError("Please select at least one row",6)
	};


	const AddNew = () =>
	{
		console.log('RowData :>> ', RowData);
		if(RowData.title != undefined && RowData.title.replace(/^\s+|\s+$/g, "").length>0 )
		{
			setLoader(true)
			let newobj = 
			{
				title:RowData.title,
				followFromHashtag:false,
				socialAreaId:RowData.socialAreaId,

			}
			if(type === 0)
			{
			console.log(newobj)
			AxiosPostRequest(Urls.Inlfuencers.add,newobj).then(response => {
				setLoader(false)
				if(response[0])
				{
					ToasSucces("Hashtag has been added successfully ");
					props.setRefreshData(!props.RefreshData)
					setShow(false);
				}
				else
						ToastError("Hashtag  didn't added")
				})
			}
			else if(type === 1)
			{
				newobj["id"] = RowData.value
				AxiosPutRequest(Urls.Inlfuencers.update,newobj).then(response => {
					setLoader(false)
					if(response[0])
					{
					ToasSucces("Hashtag Updated successfully ");
					props.setRefreshData(!props.RefreshData)
					setShow(false);
					}
					else
					ToastError("Hashtag  didn't Updated")
				})
			}
		}
		else
			ToastError("Please enter a name for Hashtag",5)
	};

	
	const enter = (e)=>{
		if(e.key == "Enter")
			AddNew()
	};


	useEffect(() => {
        if(JSON.parse(localStorage.getItem("SocialList")) !== null )
            setSocialList(JSON.parse(localStorage.getItem("SocialList")))
      }, [])
	useEffect(() => {
	  if(show)
	  document.querySelector("#insert_input").focus()
}, [show])

	const {pageIndex,pageSize} = state

	return(
		<>
				<div className="card-body">
					<div className="table-responsive">
						<div className='d-flex justify-content-end' style={{width:"100%",gap:"1rem"}}>
							<Button variant='outline-danger' onClick={()=>DeleteRow()}>Delete </Button>
							<Button variant='outline-primary ' onClick={()=>handleShow(1)}>Add New </Button>
						</div>
						{props.reportLoader ? <Spinner animation="border" variant="primary" /> : "" }
						<table {...getTableProps()} className="table filtering-table table-responsive-lg">
							<thead>
							   {headerGroups.map(headerGroup => (
									<tr {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map(column => (
											<th {...column.getHeaderProps()}>
												{column.render('Header')}
												{column.canFilter ? column.render('Filter') : null}
											</th>
										))}
									</tr>
							   ))}
							</thead> 
							<tbody {...getTableBodyProps()} className="" >
								{page.map((row,index) => {
									prepareRow(row)
									return(
										
										<tr {...row.getRowProps()}>

											{row.cells.map((cell) => {
												return <td {...cell.getCellProps()} className={"c-pointer"}>
													 {cell.column.id =="value" ?
												cell.render('Cell')
												:cell.render('Cell')} 
												 </td>
											})}
										</tr>
									)
								})}
							</tbody>
						</table>
						<div className="d-flex justify-content-between">
							<span>
								Page{' '}
								<strong>
									{pageIndex + 1} of {pageOptions.length}
								</strong>{''}
							</span>
							<span className="table-index">
								Rows Count : {' '}
								<select
								value={pageSize}
								onChange={e => {
									setPageSize(Number(e.target.value))
								}}
								>
								{[10, 25, 50, 100,200].map(pageSize => (
									<option key={pageSize} value={pageSize}>
									Show {pageSize}
									</option>
								))}
								</select>
							</span>
							<span className="table-index">
								Go to page : {' '}
								<input type="number" 
									className="ml-2"
									defaultValue={pageIndex + 1} 
									onChange = {e => { 
										const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
										gotoPage(pageNumber)
									} } 
								/>
							</span>
						</div>
						<div className="text-center">	
							<div className="filter-pagination  mt-3">
								<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
								
								<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
									Previous
								</button>
								<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
									Next
								</button>
								<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
							</div>
						</div>
					</div>
				</div>
				<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{type === 0 ? "Add" : "Edit"} Hashtag</Modal.Title>
        </Modal.Header>
        <Modal.Body>
		<Row>
			<Col className='pr-0 d-flex  align-items-center' xl={3}>
				<h6> Hashtag:</h6>
			</Col>
			<Col xl={6}>
				<input type='text' value={RowData.title} onKeyPress={(e) => enter(e)} onChange={(e) => handleChange(e.target.value,"title")} className=' form-control  text-black  ' id={"insert_input"} placeholder='Hashtag' autoFocus/>
			</Col>
		</Row>
		<Row className='mt-3'>
			<Col className='pr-0 d-flex  align-items-center' xl={3}>
				<h6> Social Subject:</h6>
			</Col>
			<Col xl={6}>
				<div className='form-group mt-2'>
				<Select  onChange={(e) => ChangeHandler('socialAreaId',e !=null ? e.value:null,setRowData,RowData)} options={SocialList}/>
				</div>
			</Col>
		</Row>
		</Modal.Body>
		<Modal.Footer>
		{Loader ? <Spinner animation="border" variant="primary" /> : "" }
          <Button variant="outline-primary" onClick={handleClose}>
            cancel
          </Button>
          <Button variant="primary" onClick={AddNew}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
		</>
	)
	
}
export default HashtagList;