import React,{ useMemo } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination, useRowSelect,useFlexLayout } from 'react-table';
import { GlobalFilter } from './GlobalFilter'; 
import './filtering.css';
import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { useState } from 'react';
import { CheckboxSelection1 } from '../Elements/Elements';
import { ColumnFilter } from './ColumnFilter';
import { CheckSpace, DeleteRow, UpdateRow, createAndDownloadTXTFile } from '../Elements/Functions';
import { Urls } from '../../../services/ServerandUrls';
import { ToastError } from '../../../services/Toasts';
import { AxiosGetRequest } from '../../../services/Connections';


export const TableElement = (props) => {
	const columns = useMemo( () => props.Headers, [props.Headers] )
	const data = useMemo( () => props.DataList, [props.DataList] )
	const [DataCount, setDataCount] = useState(1500);
	const [show, setShow] = useState(false);
	const [EditedRow, setEditedRow] = useState({});
	const tableInstance = useTable({
		columns,
		data,	
		initialState : {pageIndex : 0,pageSize:25}
	}, useFilters, useGlobalFilter, usePagination , useRowSelect,useFlexLayout ,
	(hooks)=>{
		hooks.visibleColumns.push((columns)=>{
			return [
				
				props.hasEdit ? {
					id: "edit",
					Filter: ColumnFilter,
					Header: <div style={{paddingTop:"2.5rem"}}>
						
					<i className='fa-solid fa-edit'> </i>
				</div>,
					Cell: ({row}) => (
						<i  onClick={()=>handleShow(row.original)} className='fa-solid fa-edit c-pointer'> </i>
					),
					width: 30
				  } : null,
				{
					id:"selection",
					Filter: ColumnFilter,
					Header:({getToggleAllRowsSelectedProps}) => (
						<div style={{paddingTop:"2.5rem"}}>
						<CheckboxSelection1 {...getToggleAllRowsSelectedProps()}/>
						</div>
					),
					Cell:({row}) => (
						<CheckboxSelection1 {...row.getToggleRowSelectedProps()}/>
					),
					width:30
				},
				...columns
			].filter(Boolean);
		})
	}
	)
	
	const { 
		getTableProps, 
		getTableBodyProps, 
		headerGroups, 
		prepareRow,
		setPageSize,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
		selectedFlatRows
	} = tableInstance


	const handleClose = () => setShow(false);

	const handleShow = (Data,) => {
		 if(props.hasEdit){
			console.log(Data)
		 }
		setShow(true);
		setEditedRow(Data)
	};
	
	const handleChangeedited = (Value,Type) => {
		setEditedRow(() => {
		  return {
			...EditedRow,
			[Type]: Value,
		  };
		});
	};

	const EditElementBlock = (Data,index) =>{
		return(
			<Row key={index} className={"mb-1"}>
							<Col className='pr-0 d-flex  align-items-center' xl={4}>
								<h6>{Data}:</h6>
							</Col>
							<Col className='pl-0  'xl={5}>
								<input type='text' onBlur={e => Data == "userName" ? CheckSpace(e.target.value,Data,1):""} id={Data+1} value={EditedRow[Data]} onChange={(e) => handleChangeedited(e.target.value,Data)} className=' form-control  text-black  ' placeholder={Data}/>
							</Col>
						</Row>
		)
	}

	const writeEdit = (Data,index) => {
		if(props.ExcludedFields !== null && props.ExcludedFields !== undefined){
			if(Data =="id" || props.ExcludedFields.includes(Data) ){
				return
			}
			else
			{
				return(EditElementBlock(Data,index))
			}
		}
		else
			return(EditElementBlock(Data,index))
	}

	const SendItem = () => {
		if(CheckSpace(EditedRow.text,"Text",1))
		{
		props.setLoader(true);
		UpdateRow(Urls[props.Type].update,[EditedRow],"Tweet",props.setLoader,props.setRefreshData,!props.RefreshData,setShow);
		}
		else
		return
	  }



	  const downloadButton = () => {
		if(selectedFlatRows.length > 0)
			createAndDownloadTXTFile(selectedFlatRows,props.downloadList[0],props.downloadList[1])
		else
			ToastError("Please a select an item",1)
	  };

	  const ReloadData = () => {
		props.setDataList([])
        props.setLoader(true)
		AxiosGetRequest(Urls[props.Type].view,[{"HeadKey":"pageSize","HeadValue":DataCount}]).then(response => {
			if(response[0])
			{
			  props.setDataList(response[1].data)
			  props.setLoader(false)
			}
			else
			  ToastError("Tweet List didnt loaded")
		  })
	}


	  const tempDataWrite = (Data) =>{
		console.log(Data)
	  }

	const {globalFilter, pageIndex,pageSize} = state
	return(
		<>

			<div className="card-body">
					<div className="table-responsive">
						<div className='d-flex  w-100 align-items-end' style={{gap:"1.5rem"}}>
							<GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
							{props.hasDelete ? <Button variant='outline-danger' style={{height:"fit-content"}} onClick={()=>DeleteRow(selectedFlatRows,props.Type,Urls[props.Type].delete,props.setRefreshData,!props.RefreshData,props.setLoader)}>Delete </Button>:""}
							{props.hasDownload ? <Button variant='primary' style={{height:"fit-content"}} onClick={()=>downloadButton()}>Download List</Button>:""}
							{props.Loader ? <Spinner animation="border" className='mb-3' variant="primary" /> : "" }
						</div>
						<table {...getTableProps()} className="table filtering-table table-responsive-lg">
							<thead>
							   {headerGroups.map(headerGroup => (
									<tr {...headerGroup.getHeaderGroupProps()}>
										<th style={{paddingTop:"3.2rem",width:"25px"}}  >#</th>
										{headerGroup.headers.map(column => (
											<th {...column.getHeaderProps()}>
												{column.render('Header')}
												{column.canFilter ? column.render('Filter') : null}
											</th>
										))}
									</tr>
							   ))}
							</thead> 
							<tbody {...getTableBodyProps()} className="" >
								{
								page.length > 0 ? 
									page.map((row,index) => {
										prepareRow(row)
										return(
											<tr {...row.getRowProps()} className={row.isSelected ? "TableRows SelectedRow":"TableRows"}>
													<td onClick={()=>tempDataWrite(row.original)}>{pageIndex * pageSize + index + 1}</td>
												{row.cells.map((cell) => {
													return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>
												})}
											</tr>
										)
									})
									:
									<p className='d-flex w-100 justify-content-center'> No items to show</p>}
							</tbody>
						</table>
						<div className="d-flex justify-content-between">
							<span className='d-flex flex-column'>
								<span>
									Page{' '}
									<strong>
										{pageIndex + 1} of {pageOptions.length}
									</strong>{''}
								</span>
								<strong className='pl-3'>{tableInstance.filteredRows.length}/{tableInstance.data.length} </strong>
							</span>
							<span className="table-index">
								Rows Count : {' '}
								<select
								value={pageSize}
								onChange={e => {
									setPageSize(Number(e.target.value))
								}}
								>
								{[10, 25, 50, 100,200].map(pageSize => (
									<option key={pageSize} value={pageSize}>
									Show {pageSize}
									</option>
								))}
								</select>
							</span>
							<span className="table-index">
							<Button variant="outline-primary" onClick={ReloadData} className="rounded" size="sm">Custom Data: {' '}</Button>
							<input type="number" 
								className="ml-2"
								defaultValue={DataCount} 
								onChange = {e => { 
									setDataCount (e.target.value ? Number(e.target.value): 0 )
								} } 
							/>
						</span>
						</div>
						<div className="text-center">	
							<div className="filter-pagination  mt-3">
								<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
								
								<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
									Previous
								</button>
								<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
									Next
								</button>
								<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
							</div>
						</div>
					</div>
				</div>
				{props.hasEdit ? 
					<Modal show={show} onHide={handleClose}>
						<Modal.Header closeButton>
							<Modal.Title>Edit Row</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							{Object.keys(EditedRow).map((row,index) => 
								writeEdit(row,index)
							)}

						</Modal.Body>
						<Modal.Footer>
						{props.Loader ? <Spinner animation="border" className='mb-3' variant="primary" /> : "" }
						<Button variant="outline-primary" onClick={handleClose}>
							cancel
						</Button>
						<Button variant="primary" onClick={SendItem}>
							Save Changes
						</Button>
						</Modal.Footer>
					</Modal>:""}
				</>
	)
	
}
export default TableElement;