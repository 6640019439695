import { useEffect, useState } from "react";
import { Card, Col, Nav, Tab } from "react-bootstrap";
import { AxiosGetRequest } from "../../../../services/Connections";
import { Urls } from "../../../../services/ServerandUrls";
import { ToastError } from "../../../../services/Toasts";
import GeneralSetting from "./GeneralSetting";




export default function Setting(props) {
  const [TweetList, setTweetList] = useState([]);
  const [Loader, setLoader] = useState(false);
  const [GeneralOptions, setGeneralOptions] = useState({
    Trend24Url:"",
    GetdaytrendsUrl:"",
    Language:{},
  });
	const [RefreshData, setRefreshData] = useState(false);
    const tabData = [
        {
          name: 'General Setting',
          icon: 'cogs',
          content:<GeneralSetting RefreshData={RefreshData} setRefreshData={setRefreshData} GeneralOptions={GeneralOptions} setGeneralOptions={setGeneralOptions}  />
        },
        
      ]

      useEffect(() => {
       
        Promise.all([
          AxiosGetRequest(Urls.Settings.getAppLanguge),
          AxiosGetRequest(Urls.Settings.getTrend24Link),
          AxiosGetRequest(Urls.Settings.getGetDayTrendsLink)
        ])
          .then((responses) => {
            const [languageResponse, trend24Response, dayTrendsResponse] = responses;
            
            if (languageResponse[0] && trend24Response[0] && dayTrendsResponse[0]) {
              console.log(languageResponse[1])
              setGeneralOptions({
                ...GeneralOptions,
                Language: languageResponse[1].data.value,
                Trend24Url: trend24Response[1].data,
                GetdaytrendsUrl: dayTrendsResponse[1].data
              });
            } else {
              if (!languageResponse[0]) ToastError("Failed to load app language settings.");
              if (!trend24Response[0]) ToastError("Failed to load Trend24 URL.");
              if (!dayTrendsResponse[0]) ToastError("Failed to load Day Trends URL.");
            }
          })
          .catch((error) => {
            ToastError("An error occurred while loading settings.");
            console.error(error); 
          });

          
      }, [])

      
    return(
        <Col xl={12}>
          <Card>
            <Card.Body>
              {/* <!-- Nav tabs --> */}
              <div className='default-tab'>
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                  <Nav as='ul' className='nav-tabs'>
                    {tabData.map((data, i) => (
                      <Nav.Item as='li' key={i}>
                        <Nav.Link eventKey={data.name.toLowerCase()}>
                          <i className={`la la-${data.icon} mr-2`} />
                          <i className={`fa fa-cog mr-2`} />
                          {data.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content className='pt-0'>
                    {tabData.map((data, i) => (
                      <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                        {data.content}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Card.Body>
          </Card>
        </Col>
    )
}