import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link,} from 'react-router-dom'
import { loadingToggleAction,loginAction} from '../../store/actions/AuthActions';
import { Urls } from '../../services/ServerandUrls';

//
import logo from '../../images/logo.png'
import logotext from '../../images/logo-text.png'
import login from "../../images/bg-login2.png";
import loginbg from "../../images/bg-login.jpg";
import { Spinner } from 'react-bootstrap';



function Login (props) {
  
    const [email, setEmail] = useState("");
    let errorsObj = { email: '', password: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [loader, setloader] = useState(false);
    const [Error, setError] = useState(false);
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();

  
    function onLogin(e) 
    {
        setloader(true)
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (email === '') {
            errorObj.email = 'Email is Required';
            error = true;
        }
        if (password === '') {
            errorObj.password = 'Password is Required';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
			return ;
		      }
      var LoginData = {
          'Email': email,
          'Password': password,
        }
      const ReqHeader = {'Content-Type': 'application/x-www-form-urlencoded'}
        dispatch(loginAction(Urls.Login,LoginData,ReqHeader,props.history,setloader,setError));
        dispatch(loadingToggleAction(true)); 

    }
    
  return (
        <div className="login-main-page" style={{backgroundImage:"url("+ loginbg +")"}}>
            <div className="login-wrapper">
                <div className="login-aside-left" style={{backgroundImage:"url("+ login +")"}}>
                    <Link to="/dashboard" className="login-logo">
                      <img src={logo} alt="" className="mr-2"/>
                      <img src={logotext} alt="" className="ml-1"/>
                    </Link>
                    <div className="login-description">
                        <h2 className="text-black  mb-2">Moein Intelegante System</h2>
                        <p className="fs-12">Moein Intelegante System </p>
                        <ul className="social-icons mt-4">
                            <li><Link to={"#"}><i className="fa-brands fa-facebook"></i></Link></li>
                            <li><Link to={"#"}><i className="fa-brands fa-twitter"></i></Link></li>
                            <li><Link to={"#"}><i className="fa-brands fa-linkedin"></i></Link></li>
                        </ul>
                        <div className="mt-5">
                            <Link to={"#"} className="text-black mr-4">Privacy Policy</Link>
                            <Link to={"#"} className="text-black mr-4">Contact</Link>
                        </div>
                    </div>
                </div>
                <div className="login-aside-right">
                    <div className="row m-0 justify-content-center h-100 align-items-center">
                      <div className="col-xl-7 col-xxl-7">
                        <div className="authincation-content">
                          <div className="row no-gutters">
                            <div className="col-xl-12">
                              <div className="auth-form-1">
                                <div className="mb-4">
                                  <h3 className="text-white mb-1">Welcome to Moein</h3>
                                  <p className="text-white">Sign in by entering information below</p>
                                </div>
                                {props.errorMessage && (
                                    <div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
                                        {props.errorMessage}
                                    </div>
                                )}
                                {props.successMessage && (
                                    <div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
                                        {props.successMessage}
                                    </div>
                                )}
                                <form onSubmit={onLogin}>
                                    <div className="form-group">
                                        <label className="mb-2 ">
                                          <strong className="text-white">Email</strong>
                                        </label>
                                        <input type="email" className="form-control"
                                          value={email}
                                           onChange={(e) => setEmail(e.target.value)}
                                        />
                                      {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                                    </div>
                                    <div className="form-group">
                                        <label className="mb-2 "><strong className="text-white">Password</strong></label>
                                        <input
                                          type="password"
                                          className="form-control"
                                          value={password}
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                        />
                                        {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                                    </div>
                                  <div className="form-row d-flex justify-content-between mt-4 mb-2">
                                    <div className="form-group">
                                      <div className="custom-control custom-checkbox ml-1 ">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="basic_checkbox_1"
                                        />
                                        <label className="custom-control-label text-white" htmlFor="basic_checkbox_1 ">
                                          Remember my preference
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="text-center">
                                    <button type="submit" className="btn bg-white text-primary btn-block">Sign In</button>
                                  </div>
                                </form>
                                <div className="new-account mt-2">
                                  <p className="text-white">
                                    Don't have an account?{" "}
                                    <Link className="text-black" to="./page-register">
                                      Sign up
                                    </Link>
                                    {loader ? <Spinner animation="border" className='position-absolute' variant="info" /> : "" }
                                  </p>
                                  <p className={"text-red p-0 m-0 LoginErrorText " + (Error ? " LoginErrorTextShow":" ")}>Wrong Username or Password</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(Login);