import {format} from 'date-fns';
import { ReadTableState } from '../../Elements/Functions';
import { ColumnFilter } from '../../FilteringTable/ColumnFilter';

export const CoverCOLUMNS = [
	
	{
		Header : 'Target UserName',
		Footer : 'Target UserName',
		accessor: 'targetUserName',
		Filter: ColumnFilter,
	},
	{
		Header : 'Order State ',
		Footer : 'Order State',
		accessor: 'orderState',
		Cell: ({ value }) => {return ReadTableState(value)},
		Filter: ColumnFilter,
	},
	{
		Header : 'Created Date ',
		Footer : 'Created Date ',
		accessor: 'createdDate',
		Cell: ({ value }) => {return format(new Date(value), 'dd/MM/yyyy hh:mm a')},
		Filter: ColumnFilter,
	},
	{
		Header : 'Expire Date ',
		Footer : 'Expire Date ',
		accessor: 'expiryDate',
		Cell: ({ value }) => {return format(new Date(value), 'dd/MM/yyyy hh:mm a')},
		Filter: ColumnFilter,
	},
	{
		Header : 'Type ',
		Footer : 'Type ',
		accessor: 'supportTweetType',
		Filter: ColumnFilter,
	},
	{
		Header : 'Target Link ',
		Footer : 'Target Link',
		accessor: 'targetLink',
		Cell: ({ value }) => {return <a href={value} target="_blank" rel='noreferrer'> <i className="fa fa-link"></i> </a>},
		Filter: ColumnFilter,
	},
	{
		Header : 'Title ',
		Footer : 'Title',
		accessor: 'title',
		Filter: ColumnFilter,
	}
	,
	{
		Header : 'Username',
		Footer : 'Username',
		accessor: 'userName',
		Filter: ColumnFilter,
	},
	{
		Header : 'Operation Name',
		Footer : 'Operation Name',
		accessor: 'operationName',
		Filter: ColumnFilter,
	}
	,
	{
		Header : 'Adjustment',
		Footer : 'Adjustment',
		accessor: 'scheduledAdjustmentName',
		Filter: ColumnFilter,
	}
]

export const HashtagCoverCOLUMNS = [
	
	{
		Header : 'Title ',
		Footer : 'Title',
		accessor: 'title',
		Filter: ColumnFilter,
	}
	,
	{
		Header : 'Likes ',
		Footer : 'Likes',
		accessor: 'countLikes',
		Filter: ColumnFilter,
	},
	{
		Header : ' Retweets',
		Footer : 'Retweets',
		accessor: 'countRetweets',
		Filter: ColumnFilter,
	}
	,
	{
		Header : ' Username',
		Footer : 'Username',
		accessor: 'userName',
		Filter: ColumnFilter,
	}
	,
	{
		Header : 'Order State ',
		Footer : 'Order State',
		accessor: 'orderState',
		Cell: ({ value }) => {return ReadTableState(value)},
		Filter: ColumnFilter,
	},
	{
		Header : 'Created Date ',
		Footer : 'Created Date ',
		accessor: 'createdDate',
		Cell: ({ value }) => {return format(new Date(value), 'dd/MM/yyyy hh:mm a')},
		Filter: ColumnFilter,
	},
	{
		Header : 'Expire Date ',
		Footer : 'Expire Date ',
		accessor: 'expiryDate',
		Cell: ({ value }) => {return format(new Date(value), 'dd/MM/yyyy hh:mm a')},
		Filter: ColumnFilter,
	},
	{
		Header : 'Operation Name',
		Footer : 'Operation Name',
		accessor: 'operationName',
		Filter: ColumnFilter,
	}
]
