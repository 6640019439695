import React,{ useEffect, useMemo } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination, useRowSelect ,useBlockLayout } from 'react-table';
import { COLUMNS } from './Columns';
import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { useState } from 'react';
import { ChangeHandler, CheckSpace, DeleteRow, UpdateRow } from '../../Elements/Functions';
import { Urls } from '../../../../services/ServerandUrls';
import { AccountEditModal, AccountLogTable, CheckboxSelection1 } from '../../Elements/Elements';
import { ColumnFilter } from '../../FilteringTable/ColumnFilter';
import { GlobalFilter } from '../../FilteringTable/GlobalFilter';
import { AxiosGetRequest, AxiosPutRequest } from '../../../../services/Connections';
import { ToastError } from '../../../../services/Toasts';
import Select  from "react-select";



export const AcountsList = (props) => {
	const columns = useMemo( () => COLUMNS, [] )
	const data = useMemo( () => props.AccountList, [props.AccountList] )
	const [show, setShow] = useState(false);
	const [DataCount, setDataCount] = useState(1500);
	const [EditedRow, setEditedRow] = useState({});
	const [SubjectList, setSubjectList] = useState([]);
	const [LogsList, SetLogsList] = useState([]);
	const [ModalContent, setModalContent] = useState("Table");
	const [AccountSubList, setAccountSubList] = useState([]);
	const [SelectedSubList, setselectedSubList] = useState({socialAreeas:[]});

	const tableInstance = useTable({
		columns,
		data,	
		initialState : {pageIndex : 0,pageSize:25}
	}, useFilters, useGlobalFilter,useBlockLayout , usePagination , useRowSelect,
	(hooks)=>{
		hooks.visibleColumns.push((columns)=>{
			return [
				
				{
					id:"Edit",
					Filter: ColumnFilter,
					Header:({getToggleAllRowsSelectedProps}) => (
						<div style={{paddingTop:"2.5rem"}}>
							<i className='fa-solid fa-edit'> </i>
						</div>
					),
					Cell:({row}) => (
						<div>
							<i  onClick={()=>handleShow(row.original)} className='fa-solid fa-edit c-pointer' title='Edit Account'> </i>
						</div>
					),
					width:50
				},
				{
					id:"Logs",
					Filter: ColumnFilter,
					Header:({getToggleAllRowsSelectedProps}) => (
						<div style={{paddingTop:"2.5rem"}}>
							<i className='fa-solid fa-list'> </i>
						</div>
					),
					Cell:({row}) => (
						<div>
							<i  onClick={()=>ShowLogs(row.original)} className='fa-solid fa-list c-pointer' title='Show Account Logs'> </i>
						</div>
					),
					width:50
				},
				{
					id:"selection",
					Filter: ColumnFilter,
					Header:({getToggleAllRowsSelectedProps}) => (
						<div style={{paddingTop:"2.5rem"}}>
						<CheckboxSelection1 {...getToggleAllRowsSelectedProps()}/>
						</div>
					),
					Cell:({row}) => (
						<CheckboxSelection1 {...row.getToggleRowSelectedProps()}/>
					),
					width:50
				},
				...columns
			]
		})
	}
	);
	
	const { 
		getTableProps, 
		getTableBodyProps, 
		setPageSize,
		headerGroups, 
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
		selectedFlatRows
	} = tableInstance;

	const handleClose = () => setShow(false);

	const handleShow = (Data) => {
		setModalContent("Edit")
		AxiosGetRequest(Urls.SocialAreas.accountSubjects,[{"HeadKey":"recivedId","HeadValue":Data.id}]).then(response => {
			if(response[0])
			{
				var List = [];
				var selected = {socialAreeas:[]};
				for (let i = 0; i < response[1].data.length; i++) {
				List.push({"value":response[1].data[i].id , "label":response[1].data[i].title});
				selected.socialAreeas.push(response[1].data[i].id);
				}
				setAccountSubList(List)
				setselectedSubList(selected)
			}
		
		
		var tempOBJ = {
		id:Data.id,
		userName:Data.userName,
		email:Data.email,
		genderType:Data.genderType,
		password:Data.password,
		phoneNumber:Data.phoneNumber,
		imageUrl:Data.imageUrl,
		isActive:Data.isActive,
		}
		console.log(tempOBJ)
		setEditedRow(tempOBJ)
		setShow(true);
	})
	};
	
	const handleChangeedited = (Value,Type) => {
		setEditedRow(() => {
		  return {
			...EditedRow,
			[Type]: Value,
		  };
		});
	};

	const writeEdit = (Data,index) => {
	if(Data =="id" || Data == "imageUrl" || Data == "socialAreeas"){
		return
	}
	else if(Data == "isActive")
	{
		return(
			<Row key={index} className={"mb-1 d-flex mt-3"}>
				<Col className='pr-0 d-flex  align-items-center' lg={3}>
					<h6>{Data}:</h6>
				</Col>
				<Col>
				<div style={{zIndex:0}} className='custom-control custom-checkbox'>
					<input
					type='checkbox'                      
					className='custom-control-input'
					onChange={(e) => handleChangeedited(e.target.checked,Data)}
					id={Data+1}  
					defaultChecked={EditedRow[Data]}
					/>
					<label
					className='custom-control-label '
					htmlFor={Data+1}
					>
					
					</label>
				</div>
				</Col>
			</Row>
		)
	}
	else if(Data == "genderType")
		{
			return(
			<Row key={index} className={"mb-1"}>
					<Col className='pr-0 d-flex  align-items-center' xl={3}>
						<h6>{Data}:</h6>
					</Col>
					<Col className='pl-0  'xl={5}>
						<Select onChange={(e) => ChangeHandler('genderType',e !=null ? e.value:null,setEditedRow,EditedRow)}  defaultInputValue={EditedRow[Data]} options={[{"value":"Male" , "label":"Male"},{"value":"Female" , "label":"Female"}]}></Select>
					</Col>
			</Row>
			)
		}
	else {
		return(
				<Row key={index} className={"mb-1"}>
					<Col className='pr-0 d-flex  align-items-center' xl={3}>
						<h6>{Data}:</h6>
					</Col>
					<Col className='pl-0  'xl={5}>
						<input type='text' onBlur={e => Data == "userName" ? CheckSpace(e.target.value,Data,1):""} id={Data+1} value={EditedRow[Data]} onChange={(e) => handleChangeedited(e.target.value,Data)} className=' form-control  text-black  ' placeholder={Data}/>
					</Col>
				</Row>
		)
	}
	}

	const SendItem = () => {
	var socialListID = [];
	for (let i = 0; i < SelectedSubList.socialAreeas.length; i++) {
		socialListID.push
		(
			{
				robotAccountId : EditedRow.id,
				socialAreaId : SelectedSubList.socialAreeas[i]
			}
		)
	}
	if(CheckSpace(EditedRow.userName,"userName",1))
	{
	props.setLoader(true);
	UpdateRow(Urls.Acounts.update,[EditedRow],"Account",props.setLoader,props.setRefreshData,!props.RefreshData,setShow);
	AxiosPutRequest(Urls.SocialAreas.accountSubjectsUpdate,socialListID)
	}
	else
	ToastError(1,"Please check your inputs")
	return
	}

	const ChangeTableBySubject = (SubjectId) => {
		document.getElementById("TableBodyId").classList.add("fadeout")
		if(SubjectId !==null)
		{
		props.setLoader(true)
		let headerList = 
		[
			{HeadKey:"SubjectId",HeadValue:SubjectId}
		]

		AxiosGetRequest(Urls.Acounts.accsBySub,headerList).then(response => {
			document.getElementById("TableBodyId").classList.remove("fadeout")
			props.setLoader(false);
			if(response[0])
			{
					props.setAccountsList(response[1].data)	
			}
			else
			  ToastError("Acounts List didnt load")
		  })

		}
		else
			{
				props.setRefreshData(!props.RefreshData)
				setTimeout(() => {
					document.getElementById("TableBodyId").classList.remove("fadeout")	
				}, 500);
			}
			
	}

	const ReloadData = () => {
		props.setAccountsList([])
        props.setLoader(true)
		AxiosGetRequest(Urls.Acounts.view,[{"HeadKey":"pageSize","HeadValue":DataCount}]).then(response => {
			if(response[0])
			{
			  props.setAccountsList(response[1].data)
			  props.setLoader(false)
			}
			else
			  ToastError("Tweet List didnt loaded")
		  })
	}

	const ShowLogs = (Data) => {
		setModalContent("Table")
		AxiosGetRequest(Urls.Acounts.Logs,[{"HeadKey":"robotAccountId","HeadValue":Data.id}]).then(response => {
			SetLogsList(response[1].data)
		})
		setShow(true);
	}
	
	useEffect(() => {
		if(JSON.parse(localStorage.getItem("SocialList")) !== null )
		setSubjectList(JSON.parse(localStorage.getItem("SocialList")))
	 }, [])

	const {globalFilter, pageIndex,pageSize} = state
	
	 
	return(
		<>
				<div className="card-body">
					<div className="table-responsive">
						<div className='d-flex  w-100 align-items-end' style={{gap:"1.5rem"}}>
							<GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
							<Button variant='outline-danger' style={{height:"fit-content"}} onClick={()=>DeleteRow(selectedFlatRows,"acounts",Urls.Acounts.delete,props.setRefreshData,!props.RefreshData,props.setLoader)}>Delete </Button>
							<div className="m-2" style={{width:"30%",zIndex:"3"}}>
								<Select  
								isClearable 
								onChange={(e) => ChangeTableBySubject(e !=null ? e.value:null)} 
								options={props.SocialList}/>
							</div>
							{props.Loader ? <Spinner animation="border" className='mb-3' variant="primary" /> : "" }
						</div>
						<div className='TableContainer '>
							<div className="tableBlock" {...getTableProps()}>
								<table className="table filtering-table table-responsive-lg TableCustom">
									<thead className="sticky-header">
									{headerGroups.map(headerGroup => (
											<tr {...headerGroup.getHeaderGroupProps()} className="HeaderRow" >
												<th style={{paddingTop:"3.2rem",width:"25px"}}  >#</th>
												{headerGroup.headers.map(column => (
													<th {...column.getHeaderProps()}>
														{column.render('Header')}
														{column.canFilter ? column.render('Filter') : null}
													</th>
												))}
											</tr>
									))}
									</thead> 
									<tbody {...getTableBodyProps()} id="TableBodyId" >
										{
										page.length > 0 ? 
											page.map((row,index) => {
												prepareRow(row)
												return(
													<tr {...row.getRowProps()} className={row.original.isActive && row.original.isLogin ? '' : 'Inactive-row'}>
														<td >{pageIndex * pageSize + index + 1}</td>
														{row.cells.map((cell) => {
															return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>
														})}
													</tr>
												)
											}):<p className='d-flex w-100 justify-content-center'> No items to show</p>}
									</tbody>
								</table>
							</div>
						</div>
						<div className="d-flex justify-content-between">
							<span className='d-flex flex-column'>
								<span>
									Page{' '}
									<strong>
										{pageIndex + 1} of {pageOptions.length}
									</strong>{''}
								</span>
								<strong className='pl-3'>{tableInstance.filteredRows.length}/{tableInstance.data.length} </strong>
							</span>
							<span className="table-index">
								Rows Count : {' '}
								<select
								value={pageSize}
								onChange={e => {
									setPageSize(Number(e.target.value))
								}}
								>
								{[10, 25, 50, 100,200].map(pageSize => (
									<option key={pageSize} value={pageSize}>
									Show {pageSize}
									</option>
								))}
								</select>
							</span>
							<span className="table-index d-flex flex-row align-items-center">
								<Button variant="outline-primary" onClick={ReloadData} className="rounded" size="sm">Custom Data: {' '}</Button>
								<input type="number" 
									className="ml-2"
									defaultValue={DataCount} 
									onChange = {e => { 
										setDataCount (e.target.value ? Number(e.target.value): 0 )
									} } 
								/>
							</span>
						</div>
						<div className="text-center">	
							<div className="filter-pagination  mt-3">
								<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
								<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
									Previous
								</button>
								<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
									Next
								</button>
								<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
							</div>
						</div>
					</div>
				</div>
	<Modal 
	className='AccountListModal'
	size={
		ModalContent != "Edit"  ? "lg":""
		} 
	show={show} 
	onHide={handleClose}>
		{ModalContent != "Edit"  ? 
		<AccountLogTable
		handleClose={handleClose}
		DataList={LogsList}
		
		/>
		:
		<AccountEditModal
		EditedRow={EditedRow}
		writeEdit={writeEdit}
		setselectedSubList={setselectedSubList}
		SelectedSubList={SelectedSubList}
		SubjectList={SubjectList}
		AccountSubList={AccountSubList}
		ID={props.ID}
		Loader={props.Loader}
		handleClose={handleClose}
		SendItem={SendItem}
		/>}
	</Modal>
		</>
	)
	
}
export default AcountsList;