import {format} from 'date-fns';
import { Button } from 'react-bootstrap';
import { ColumnFilter } from '../../FilteringTable/ColumnFilter';

export const COLUMNS = [

	{
		Header : 'Hashtag',
		Footer : 'Hashtag',
		accessor: 'hashtag',
		Filter: ColumnFilter,
	},
	{
		Header : 'Social Subject',
		Footer : 'Social Subject',
		accessor: 'SocialSubject',
		Filter: ColumnFilter,
	},
]
