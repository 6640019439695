import { ColumnFilter } from '../../FilteringTable/ColumnFilter';

export const ContentCOLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header : 'Tweet Text ',
		Footer : 'Tweet Text',
		accessor: 'first_name',
		Filter: ColumnFilter,
	},
	{
		Header : ' Accounts Group',
		Footer : 'Selected Accounts Group',
		accessor: 'last_name',
		Filter: ColumnFilter,
	},
	{
		Header : 'Operation Name',
		Footer : 'Combined',
		accessor: 'email',
		Filter: ColumnFilter,
	}
	// {
	// 	Header : 'Country',
	// 	Footer : 'Country',
	// 	accessor: 'country',
	// 	Filter: ColumnFilter,
	// 	Cell: ({ value }) => {return <Button variant='outline-primary ' onClick={()=>console.log(value)}>Edit </Button>},
	// 	disableFilters: true,
]

export const GROUPED_COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id'
	},
	{
		Header : 'Name',
		Footer : 'Name',
		columns: [
			{
				Header : 'First Name',
				Footer : 'First Name',
				accessor: 'first_name'
			},
			{
				Header : 'Last Name',
				Footer : 'Last Name',
				accessor: 'last_name'
			},
		]
	},
	{
		Header: 'Info',
		Footer: 'Info',
		columns: [
			{
				Header : 'Date of  Birth',
				Footer : 'Date of  Birth',
				accessor: 'date_of_birth'
			},
			{
				Header : 'Country',
				Footer : 'Country',
				accessor: 'country',
			},
			{
				Header : 'Phone',
				Footer : 'Phone',
				accessor: 'phone'
			},
		]
	},
]