import { Spinner } from "react-bootstrap";
import ActionListItem from "./ActionListItem";
import {   useEffect, useState } from "react";



export default function ActionListContainer (props) {
  
	

  // const RollDown = (Act) => {

  //   var ItemsContainer = document.getElementById("ActionItemsContainer")
    
  //   const interval = setInterval(() => {
  //   if (props.count >= props.ActionList.Data.length) 
  //   {
  //     clearInterval(interval);
  //   } 
  //   else
  //   {
  //     setDataList(PervList => [props.ActionList.Data[props.count],...PervList])
  //     for (let index = 0; index < ItemsContainer.childNodes.length; index++) 
  //     {
  //       if(index==0)
  //       ItemsContainer.childNodes[index].classList.add("FadesDownFast")
  //       else
  //       ItemsContainer.childNodes[index].classList.add("SlideDownsFast")
  //       setTimeout(() => {
  //         ItemsContainer.childNodes[0].classList.remove("FadesDownFast")
  //         ItemsContainer.childNodes[index].classList.remove("SlideDownsFast")
  //         }, 1000);
  //     }
  //     props.setCount(props.count+1)
  //   }
  // }, 1250);
  
  // }




//  useEffect(() => {
//   setDataList(props.ActionList)
//   var ItemsContainer = document.getElementById("ActionItemsContainer")
//     let counter = props.count;
//     console.log(props.count)
//     console.log('object1 :>> ', props.ActionList);
//     console.log('object2 :>> ', props.ActionList.Data);

//     const interval = setInterval(() => {
//       if (counter >= props.ActionList.Data.length) 
//       {
//         clearInterval(interval);
//       } 
//       else 
//       {
//         setDataList(PervList => [props.ActionList.Data[counter],...PervList])
//         for (let index = 0; index < ItemsContainer.childNodes.length; index++) 
//         {
//           if(index==0)
//           ItemsContainer.childNodes[index].classList.add("FadesDownFast")
//           else
//           ItemsContainer.childNodes[index].classList.add("SlideDownsFast")
//           setTimeout(() => {
//             ItemsContainer.childNodes[0].classList.remove("FadesDownFast")
//             ItemsContainer.childNodes[index].classList.remove("SlideDownsFast")
//             }, 250);
//         }
//         counter++;
//         props.setCount(counter)
//       }
//     }, 312);
//   }, [props.ActionList]);

const [showItems, setShowItems] = useState(false);
const [DataList, setDataList] = useState(props.ActionList.Data);

useEffect(() => {
  setDataList(props.ActionList.Data);
}, [props.ActionList.Data]);
  
    return(
        <div ref={props.reportContainer} className="ActionListContainer" id="ActionListContainer">
        {props.ActionList.Init ? "" : <h5 className="font-w600"> Select a filter</h5>}
        {props.ActionList.Condition ? <h5 className="font-w600"> No action to show!</h5> : "" }
        {props.reportLoader ? <Spinner animation="border" variant="primary" /> : "" }
          {DataList.reverse().map(((Data,Index) => 
              <ActionListItem Data={Data} Index={Index}  Kind={props.ActionList.Kind} setShowItems={setShowItems} show={showItems} delay={Index * 10}/>
              ))}
      </div>
    )
}