import { ToastContainer, toast } from 'react-toastify'


export const ToastError = (Text,customId) => {
  return (
    toast.error(Text, {
      
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customId,
      theme: "colored",
      
    })
  )
}

export const ToasSucces = (Text,customId) => {
  return (
    toast.success(Text, {
      position: 'bottom-left',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: customId,
      theme: "colored",
    })
  )
}