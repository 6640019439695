import {format} from 'date-fns';
import { ColumnFilter } from '../../../FilteringTable/ColumnFilter';



export const COLUMNS = [
	{
		Header : 'Username',
		Footer : 'Username',
		accessor: 'userName',
		Filter: ColumnFilter,
	},
	{
		Header : 'Operation Name',
		Footer : 'Operation Name',
		accessor: 'operationName',
		Filter: ColumnFilter,
	},
	{
		Header : 'Order State ',
		Footer : 'Order State ',
		accessor: 'orderState',
		Filter: ColumnFilter,
	},
	{
		Header : 'Text ',
		Footer : 'Text ',
		accessor: 'text',
		Filter: ColumnFilter,
	},
	{
		Header : 'Link',
		Footer : 'Link',
		accessor: 'link',
		Filter: ColumnFilter,
		Cell: ({ value }) => {return <>
		{value.length > 0 ? <a href={value} target="_blank" rel='noreferrer'> <i className="fa fa-link"></i> </a>:<h6>No Link!</h6>}
		</>},
	},
	{
		Header : 'Created Date ',
		Footer : 'Created Date ',
		accessor: 'createdDate',
		Cell: ({ value }) => {return format(new Date(value), 'dd/MM/yyyy hh:mm a')},
		Filter: ColumnFilter,
	},
	{
		Header : 'Finish Date ',
		Footer : 'Finish Date ',
		accessor: 'finishedDate',
		Cell: ({ value }) => {return format(new Date(value), 'dd/MM/yyyy hh:mm a')},
		Filter: ColumnFilter,
	},
	{
		Header : 'Expiry Date',
		Footer : 'Expiry Date',
		accessor: 'expiryDate',
		Filter: ColumnFilter,
		Cell: ({ value }) => {return format(new Date(value), 'dd/MM/yyyy hh:mm a')},
	},
	{
		Header : 'isDeleted',
		Footer : 'isDeleted',
		accessor: 'isDeleted',
		Filter: ColumnFilter,
		Cell: ({ value }) => {return value ? "True":"False"},
	}
	,
	{
		Header : 'Action Type',
		Footer : 'Action Type',
		accessor: 'robotAccountTweetType',
		Filter: ColumnFilter,
	}
]


