import React,{ forwardRef, useEffect, useMemo } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination, useRowSelect,useFlexLayout } from 'react-table';
import { COLUMNS } from './Columns';
import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { useState } from 'react';
import { ChangeHandler, CheckEmpty, DeleteRow, UpdateRow,findOptionByValue } from '../../../Elements/Functions';
import { Urls } from '../../../../../services/ServerandUrls';
import { CheckboxSelection1 } from '../../../Elements/Elements';
import ReactDatePicker from 'react-datepicker';
import TimeRangePicker from '@wojtekmaj/react-timerange-picker/dist/TimeRangePicker';
import { GlobalFilter } from '../../../FilteringTable/GlobalFilter';
import { ColumnFilter } from '../../../FilteringTable/ColumnFilter';
import Select  from "react-select";




export const AcountsList = (props) => {
	const columns = useMemo( () => COLUMNS, [] )
	const [dateRange, setDateRange] = useState([new Date(), new Date()]);
	const [startDatee, endDatee] = dateRange;
	const data = useMemo( () => props.OperationList, [props.OperationList] )
	const [Loader, setLoader] = useState(false);
	const [show, setShow] = useState(false);
	const [Send, setSend] = useState(0);
	const [EditedRow, setEditedRow] = useState({});
	const [TimeValue, setTimeValue] = useState(["00:00", "00:00"]);
	const DeleteTypeOptions =
	[
		{ value: 'All', label: 'All' },
        { value: 'Tweets', label: 'Tweets' },
        { value: 'Comments', label: 'Comments' },
	]
	const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button className={"rounded-lg bg-white border p-3 DatePicker-Custom"} onClick={onClick} ref={ref}>
          {value}
        </button>
	));

	const ExampleCustomInput2 = forwardRef(({ value, onClick }, ref) => (
        <button className={"rounded-lg bg-white border p-3 DatePicker-Custom"} disabled={!EditedRow.isNeedDeleteAfter} onClick={onClick} ref={ref}>
          {value}
        </button>
	));
	const tableInstance = useTable({
		columns,
		data,	
		initialState : {pageIndex : 0,pageSize:25}
	}, useFilters, useGlobalFilter,useFlexLayout, usePagination , useRowSelect,
	(hooks)=>{
		hooks.visibleColumns.push((columns)=>{
			return [
				{
					id:"Counter",
					Filter: ColumnFilter,
					Header:({getToggleAllRowsSelectedProps}) => (
						<div style={{paddingTop:"2.5rem"}}>
						#
						</div>
					),
					Cell:({row}) => (
						<div>
							{row.index +1 }
						</div>
					),
					width:30
				},
				{
					id: "edit",
					Filter: ColumnFilter,
					Header: <div style={{paddingTop:"2.5rem"}}>
						
					<i className='fa-solid fa-edit'> </i>
				</div>,
					Cell: ({row}) => (
						<i  onClick={()=>handleShow(row.original)} className='fa-solid fa-edit c-pointer'> </i>
					),
					width: 30
				},
				{
					id:"selection",
					Filter: ColumnFilter,
					Header:({getToggleAllRowsSelectedProps}) => (
						<div style={{paddingTop:"2.5rem"}}>
						<CheckboxSelection1 {...getToggleAllRowsSelectedProps()}/>
						</div>
					),
					Cell:({row}) => (
						<CheckboxSelection1 {...row.getToggleRowSelectedProps()}/>
					),
					width:30
				},
				...columns
			];
		})
	}
	)
	
	const { 
		getTableProps, 
		getTableBodyProps, 
		headerGroups, 
		prepareRow,
		setPageSize,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
		selectedFlatRows
	} = tableInstance

	const handleClose = () => setShow(false);

	const detectDate = (cond,date) => {
		if(cond)
			return new Date(date)
		else
			return new Date()
	}

	const handleShow = (Data) => {
		console.log(Data)
		setShow(true);
		var tempOBJ = 
		{
		id:Data.id,
		name:Data.name,
		Datee:[Data.startDate,Data.endDate],
		isActive:Data.isActive,
		isNeedDeleteAfter:Data.isNeedDeleteAfter,
		deleteDate:[detectDate(Data.isNeedDeleteAfter,Data.startDeleteDate), detectDate(Data.isNeedDeleteAfter,Data.endDeleteDate)],
		deleteType:Data.deleteType
		}
		let start = new Date(Data.startDate);
		let end = new Date(Data.endDate)
		setDateRange([start,end])
		setTimeValue([start.getHours()+":"+start.getMinutes(),end.getHours()+":"+end.getMinutes()])
		setEditedRow(tempOBJ)
	};
	
	
	  const writeEdit = (Data,index) => {
		if(Data =="id" || Data == "imageUrl" || Data=="endDate"||Data=="startDate"){
			return
		}
		else if (Data == "Datee")
		{
			
			return (
				<>
				<Row key={index} className={"mb-1"}>
					<Col className='pr-0 d-flex  align-items-center' xl={3}>
						<h6>Date:</h6>
					</Col>
					<Col className='pl-0  'xl={7}>
					<ReactDatePicker 
						selectsRange={true}
						startDate={startDatee}
						endDate={endDatee}
						onChange={(update) => {setDateRange(update);}}
						customInput={<ExampleCustomInput />}
						withPortal/>
					</Col>
				</Row>
				<Row>
				<Col className='pr-0 d-flex  align-items-center' xl={3}>
						<h6>Time:</h6>
					</Col>
					<Col className='pl-0  'xl={7}>
					<TimeRangePicker clockIcon={null} disableClock={true} clockClassName={" position-relative "} format={"HH:mm"} onChange={setTimeValue} value={TimeValue} />
					</Col>
				</Row>
				</>
				)
		}
		else if(Data == "isActive")
		{
			return(
				<Row key={index} className={"mb-1 d-flex mt-3"}>
						<Col className='pr-0 d-flex  align-items-center' sm={3}>
							<h6>{"Active"}:</h6>
						</Col>
						<Col className='pl-0  '>

							<div style={{zIndex:0}} className='custom-control custom-checkbox'>
                          <input
                          type='checkbox'                      
                          className='custom-control-input'
						  onChange={(e) =>ChangeHandler(Data,e.target.checked,setEditedRow,EditedRow)}
						  id={Data+1}  
                          defaultChecked={EditedRow[Data]}
                          />
                          <label
                          className='custom-control-label '
                          htmlFor={Data+1}
                          >
                          
                          </label>
                      </div>
						</Col>
					</Row>
			)
		}
		else if (Data == "isNeedDeleteAfter")
		{
			return(
				<Row key={index} className={"mb-1 d-flex mt-3 " }>
						<Col className='pr-0 d-flex  align-items-center' sm={3}>
							<h6>{"Delete After"}:</h6>
						</Col>
						<Col className='pl-0  '>
							<div style={{zIndex:0}} className='custom-control custom-checkbox'>
                          <input
                          type='checkbox'                      
                          className='custom-control-input'
						  onChange={(e) =>ChangeHandler(Data,e.target.checked,setEditedRow,EditedRow)}
						  id={Data+1}  
                          defaultChecked={EditedRow[Data]}
                          />
                          <label
                          className='custom-control-label '
                          htmlFor={Data+1}
                          >
                          
                          </label>
                      </div>
						</Col>
					</Row>
			)
		}
		else if (Data == "deleteDate")
		{
			return (
				<>
				<Row key={index} className={"mb-1 " + (!EditedRow.isNeedDeleteAfter ? " bg-light":" ")}>
					<Col className='pr-0 d-flex  align-items-center' xl={3}>
						<h6>Delete Date:</h6>
					</Col>
					<Col className='pl-0  'xl={7}>
					<ReactDatePicker 
						selectsRange={true}
						startDate={EditedRow.deleteDate[0]}
						endDate={EditedRow.deleteDate[1]}
						disabled={!EditedRow.isNeedDeleteAfter}
						onChange={(update) => {ChangeHandler(Data,update,setEditedRow,EditedRow);}}
						customInput={<ExampleCustomInput2 />}
						withPortal/>
					</Col>
					<div></div>
				</Row>
				</>
				)
		}
		else if (Data == "deleteType")
		{
			return (
				<>
				<Row key={index} className={"mb-1" + (!EditedRow.isNeedDeleteAfter ? " bg-light":" ")}>
					<Col className='pr-0 d-flex  align-items-center' xl={3}>
						<h6>Delete Type:</h6>
					</Col>
					<Col className='pl-0  'xl={7}>
						<Select 
							defaultValue={findOptionByValue(EditedRow.deleteType,DeleteTypeOptions)}
							isDisabled={!EditedRow.isNeedDeleteAfter}  
							onChange={(e) => ChangeHandler(Data,e !=null ? e.value:null,setEditedRow,EditedRow)}
							options={DeleteTypeOptions}/> 
					</Col>
				</Row>
				</>
				)
		}
		else {
			return(
					<Row key={index} className={"mb-1"}>
						<Col className='pr-0 d-flex  align-items-center' xl={3}>
							<h6>{Data}:</h6>
						</Col>
						<Col className='pl-0  'xl={5}>
							<input type='text' onBlur={e =>CheckEmpty(e.target.value,Data,1)} id={Data+1} value={EditedRow[Data]} onChange={(e) => ChangeHandler(Data,e.target.value,setEditedRow,EditedRow) } className='form-control  text-black' placeholder={Data}/>
						</Col>
					</Row>
			)
		}
	  }

	  const SendItem = () => {
		setLoader(true);
		const Today = new Date();
        var month = Today.getMonth() + 1; 
        var day = Today.getDate();
        var year = Today.getFullYear();
        var monthEnd = Today.getMonth() + 1;
        var dayEnd = Today.getDate();
        var yearEnd = Today.getFullYear();
        var newdate = new Date(year + "-" + month + "-" + day);
        var EndDate = new Date(yearEnd + "-" + monthEnd + "-" + dayEnd);
        if(startDatee !== null)
        {
        month = startDatee.getMonth() + 1; 
        day = startDatee.getDate();
        year = startDatee.getFullYear();
        newdate = new Date(year + "-" + month + "-" + day);
		if(TimeValue[0].length == 3)
			{
			newdate.setHours(TimeValue[0].slice(0,1))
			newdate.setMinutes(TimeValue[0].slice(-1))
			}
		else if(TimeValue[0].length == 4)
			{
				if(TimeValue[0].slice(0,2).includes(":"))
				{
				newdate.setHours(TimeValue[0].slice(0,1))
				newdate.setMinutes(TimeValue[0].slice(-2))
				}
				else
				{
				newdate.setHours(TimeValue[0].slice(0,2))
				newdate.setMinutes(TimeValue[0].slice(-1))
				}
			}
		else
			{
				newdate.setHours(TimeValue[0].slice(0,2))
				newdate.setMinutes(TimeValue[0].slice(-2))
			}

        }
        if(endDatee !== null)
        {
          monthEnd = endDatee.getMonth() + 1;
          dayEnd = endDatee.getDate();
          yearEnd = endDatee.getFullYear();
          EndDate = new Date(yearEnd + "-" + monthEnd + "-" + dayEnd);
		  if(TimeValue[1].length == 3)
		  {
			EndDate.setHours(TimeValue[1].slice(0,1))
			EndDate.setMinutes(TimeValue[1].slice(-1))
		  }
	  else if(TimeValue[1].length == 4)
		  {
			  if(TimeValue[1].slice(0,2).includes(":"))
				{
					EndDate.setHours(TimeValue[1].slice(0,1))
					EndDate.setMinutes(TimeValue[1].slice(-2))
				}
			  else
				{
					EndDate.setHours(TimeValue[1].slice(0,2))
					EndDate.setMinutes(TimeValue[1].slice(-1))
				}
		  }
	  else
			{
				EndDate.setHours(TimeValue[1].slice(0,2))
				EndDate.setMinutes(TimeValue[1].slice(-2))
			}
        }
        setEditedRow({
          ...EditedRow,
          endDate: EndDate.toISOString(),
          startDate:newdate.toISOString()
        });
		setSend(1)

	  }
	  
	const {globalFilter, pageIndex,pageSize} = state

	useEffect(() => {
		if(Send===1)
		{
			console.log(EditedRow)
			UpdateRow(Urls.Operation.update,EditedRow,"Operation",setLoader,props.setRefreshData,!props.RefreshData,setShow);
			setSend(0)
		}
	
	}, [Send])



	return(
	<>
				<div className="card-body">
					<div className="table-responsive">
						<div className='d-flex  w-100 align-items-end' style={{gap:"1.5rem"}}>
							<GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
							<Button variant='outline-danger' style={{height:"fit-content"}} onClick={()=>DeleteRow(selectedFlatRows,"Operations",Urls.Operation.delete,props.setRefreshData,!props.RefreshData,setLoader)}>Delete </Button>
							{Loader ? <Spinner animation="border" className='mb-3' variant="primary" /> : "" }
						</div>
						<table {...getTableProps()} className="table filtering-table table-responsive-lg">
							<thead>
							   {headerGroups.map(headerGroup => (
									<tr {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map(column => (
											<th {...column.getHeaderProps()}>
												{column.render('Header')}
												{column.canFilter ? column.render('Filter') : null}
											</th>
										))}
									</tr>
							   ))}
							</thead> 
							<tbody {...getTableBodyProps()} className="" >
								{page.map((row) => {
									prepareRow(row)
									return(
										<tr {...row.getRowProps()} className={row.isSelected ? "TableRows SelectedRow":"TableRows"}>
											{row.cells.map((cell) => {
												return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>
											})}
										</tr>
									)
								})}
							</tbody>
						</table>
						<div className="d-flex justify-content-between">
							<span>
								Page{' '}
								<strong>
									{pageIndex + 1} of {pageOptions.length}
								</strong>{''}
							</span>
							<span className="table-index">
								Rows Count : {' '}
								<select
								value={pageSize}
								onChange={e => {
									setPageSize(Number(e.target.value))
								}}
								>
								{[10, 25, 50, 100,200].map(pageSize => (
									<option key={pageSize} value={pageSize}>
									Show {pageSize}
									</option>
								))}
								</select>
							</span>
							<span className="table-index">
								Go to page : {' '}
								<input type="number" 
									className="ml-2"
									defaultValue={pageIndex + 1} 
									onChange = {e => { 
										const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
										gotoPage(pageNumber)
									} } 
								/>
							</span>
						</div>
						<div className="text-center">	
							<div className="filter-pagination  mt-3">
								<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
								
								<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
									Previous
								</button>
								<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
									Next
								</button>
								<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
							</div>
						</div>
					</div>
				</div>
				<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Row</Modal.Title>
        </Modal.Header>
        <Modal.Body>
			{Object.keys(EditedRow).map((row,index) => 
				writeEdit(row,index)
			)}
			<div>

			</div>
		</Modal.Body>
		<Modal.Footer>
		{Loader ? <Spinner animation="border" className='mb-3' variant="primary" /> : "" }
          <Button variant="outline-primary" onClick={handleClose}>
            cancel
          </Button>
          <Button variant="primary" onClick={SendItem}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
	</>
	)
	
}
export default AcountsList;