import {format} from 'date-fns';
import { ColumnFilter,CheckboxFilter, GenderFilter } from '../../FilteringTable/ColumnFilter';

export const COLUMNS = [

	{
		Header : 'Username ',
		Footer : 'Username',
		accessor: 'userName',
		Filter: ColumnFilter,
		sticky: 'right' 
	},
	{
		Header : ' Created date',
		Footer : 'Created date',
		accessor: 'createdDate',
		Cell: ({ value }) => {return format(new Date(value), 'dd/MM/yyyy')},
		Filter: ColumnFilter,
	},
	{
		Header : 'Email',
		Footer : 'Email',
		accessor: 'email',
		Filter: ColumnFilter,
	},
	{
		Header : 'Password',
		Footer : 'Password',
		accessor: 'password',
		Filter: ColumnFilter,
	},
	{
		Header : 'Phone Number',
		Footer : 'Phone Number',
		accessor: 'phoneNumber',
		Filter: ColumnFilter,
	},
	{
		Header : 'Gender',
		Footer : 'Gender',
		accessor: 'genderType',
		Filter: GenderFilter,
	},
	{
		Header : 'Followings',
		Footer : 'Followings',
		accessor: 'countFollowings',
		Filter: ColumnFilter,
	},
	{
		Header : 'Followers',
		Footer : 'Followers',
		accessor: 'countFollowers',
		Filter: ColumnFilter,
	},
	{
		Header : 'Active',
		Footer : 'Active',
		accessor: 'isActive',
		Filter: CheckboxFilter,
		Cell: ({ value }) => {return value ? "True":"False"},
	},
	{
		Header : 'LoggedIn',
		Footer : 'LoggedIn',
		accessor: 'isLogin',
		Filter: CheckboxFilter,
		Cell: ({ value }) => {return value ? "True":"False"},
	},
	{
		Header : 'Suspended',
		Footer : 'Suspended',
		accessor: 'IsSuspended',
		Filter: CheckboxFilter,
		Cell: ({ value }) => {return value ? "True":"False"},
	},
	{
		Header : 'Ip Changed',
		Footer : 'Ip Changed',
		accessor: 'ipChanged',
		Filter: CheckboxFilter,
		Cell: ({ value }) => {return value ? "True":"False"},
	},
	{
		Header : 'Limited',
		Footer : 'Limited',
		accessor: 'isLimited',
		Filter: CheckboxFilter,
		Cell: ({ value }) => {return value ? "True":"False"},
	},
	{
		Header : 'Retweet Limited',
		Footer : 'Retweet Limited',
		accessor: 'isRetweetLimited',
		Filter: CheckboxFilter,
		Cell: ({ value }) => {return value ? "True":"False"},
	},
	{
		Header : 'Like Limited',
		Footer : 'Like Limited',
		accessor: 'isLikeLimited',
		Filter: CheckboxFilter,
		Cell: ({ value }) => {return value ? "True":"False"},
	},
	{
		Header : 'Follow Limited',
		Footer : 'Follow Limited',
		accessor: 'isFollowLimited',
		Filter: CheckboxFilter,
		Cell: ({ value }) => {return value ? "True":"False"},
	},
	


]
