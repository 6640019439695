import {format} from 'date-fns';
import { ReadTableState, WriteToolTipTable } from '../../Elements/Functions';
import { ColumnFilter } from '../../FilteringTable/ColumnFilter';

export const DirectCOLUMNS = [
	{
		Header : 'Direct text ',
		Footer : 'Direct text',
		accessor: 'text',
		Cell: ({ value }) => {return WriteToolTipTable(value)},
		Filter: ColumnFilter,
	},
	{
		Header : ' UserName',
		Footer : 'UserName',
		accessor: 'userName',
		Filter: ColumnFilter,
	},
	{
		Header : 'Target User',
		Footer : 'Target User ',
		accessor: 'targetUserName',
		Filter: ColumnFilter,
	},
	{
		Header : 'Created Date ',
		Footer : 'Created Date ',
		accessor: 'createdDate',
		Cell: ({ value }) => {return format(new Date(value), 'dd/MM/yyyy hh:mm a')},
		Filter: ColumnFilter,
	},
	{
		Header : 'Order State ',
		Footer : 'Order State',
		accessor: 'orderState',
		Cell: ({ value }) => {return ReadTableState(value)},
		Filter: ColumnFilter,
	},
	{
		Header : 'Expire Date ',
		Footer : 'Expire Date ',
		accessor: 'expiryDate',
		Cell: ({ value }) => {return format(new Date(value), 'dd/MM/yyyy hh:mm a')},
		Filter: ColumnFilter,
	},
	{
		Header : 'Operation Name',
		Footer : 'Operation Name',
		accessor: 'operationName',
		Filter: ColumnFilter,
	},
	{
		Header : 'List Name',
		Footer : 'List Name ',
		accessor: 'scheduledAdjustmentName',
		Filter: ColumnFilter,
	}
	
]

