/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Link
import { Link } from "react-router-dom";
import { AxiosGetRequest } from "../../../services/Connections";
import { Urls } from "../../../services/ServerandUrls";
import { ToastError } from "../../../services/Toasts";
import {logout} from "../../components/Elements/Functions"
class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
  }
    render() {
        return (
            <div className="mm-wrapper">
                <ul className="metismenu" ref={(el) => (this.el = el)}>
                    {this.props.children}
                </ul>
            </div>
        );
    }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");

    
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    
    btn.addEventListener("click", toggleFunc);

        AxiosGetRequest(Urls.SocialAreas.view).then(response => {
          if(response[0])
          {
            var List = []
            for (let i = 0; i < response[1].data.length; i++) {
               List.push({"value":response[1].data[i].id , "label":response[1].data[i].title})
            }
            localStorage.setItem("SocialList" , JSON.stringify(List))
          }
          else
            ToastError("Social List didnt loaded")
        })

        AxiosGetRequest(Urls.Operation.Active).then(response => {
          if(response[0])
          {
            var List = []
            for (let i = 0; i < response[1].data.length; i++) {
               List.push({"value":response[1].data[i].id , "label":response[1].data[i].name})
            }
            localStorage.setItem("OperationList" , JSON.stringify(List))
          }
          else
            ToastError("Operation List didnt loaded")
        })

        AxiosGetRequest(Urls.Acounts.view).then(response => 
        {
          if(response[0])
          {
            var List = []
            for (let i = 0; i < response[1].data.length; i++) {
               List.push({"value":response[1].data[i].id , "label":response[1].data[i].userName})
            }
            localStorage.setItem("AccountsList" , JSON.stringify(List))
          }
          else
            ToastError("Acounts List didnt loaded")
        })

  }
  state = {
    loveEmoji: false,
  };
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];
    
    /// Active menu
    let dashBoard = [
        "",
        "my-wallet",
        "invoices",
        "cards-center",
        "transactions",
        "transactions-details",
		"task",
      ],
      forms = [
        "Operations",
        "Tweets",
        "PollsAndVotes",
        "Replies",
        "Covers",
        "Trends",
        "Contents",
        "Directs",
        "Retweets"
      ],
      Accounts = [
        "Accounts"
      ]

    return (
      <div className="deznav" id={"MoeinMenu"}>
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
				      <Link  to="/" onClick={() => this.props.onClick3()} ><i className="fa-solid fa-desktop"></i><span className="nav-text">Dashboard</span></Link>
            </li>
            <li className={`${Accounts.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="fa-regular fa-user"></i>
                <span className="nav-text">Accounts</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "Accounts" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to="/Accounts">
                    Add Accounts
                  </Link>
                </li>
              </ul>
            </li>
            <li className={`${forms.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="flaticon-381-notepad"></i>
                <span className="nav-text forms">Forms</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${ path === "Operations" ? "mm-active" : "" }`} onClick={() => this.props.onClick()} to="/Operations">Operations</Link>
                </li>
                <li>
                  <Link className={`${ path === "Tweets" ? "mm-active" : "" }`} onClick={() => this.props.onClick()} to="/Tweets">Tweets</Link>
                </li>
                <li>
                  <Link className={`${ path === "PollsAndVotes" ? "mm-active" : "" }`} onClick={() => this.props.onClick()} to="/PollsAndVotes">Polls And Votes</Link>
                </li>
                <li>
                  <Link className={`${ path === "Replies" ? "mm-active" : "" }`} onClick={() => this.props.onClick()} to="/Replies">Replies</Link>
                </li>
                <li>
                  <Link className={`${ path === "Retweets" ? "mm-active" : "" }`} onClick={() => this.props.onClick()} to="/Retweets">Retweets With Comment</Link>
                </li>
                <li>
                  <Link className={`${ path === "Covers" ? "mm-active" : "" }`} onClick={() => this.props.onClick()} to="/Covers">Covers</Link>
                </li>
                <li>
                  {/* <Link className={`${ path === "Trends" ? "mm-active" : "" }`} onClick={() => this.props.onClick()} to="/Trends">Trends</Link> */}
                </li>
                <li>
                  {/* <Link className={`${ path === "Contents" ? "mm-active" : "" }`} onClick={() => this.props.onClick()} to="/Contents">Contents</Link> */}
                </li>
                <li>
                  <Link className={`${ path === "Directs" ? "mm-active" : "" }`} onClick={() => this.props.onClick()} to="/Directs">Directs</Link>
                </li>
                <li>
                  {/* <Link className={`${ path === "Wizard" ? "mm-active" : "" }`} onClick={() => this.props.onClick()} to="/Wizard">Wizard</Link> */}
                </li>
                <li>
                  <Link className={`${ path === "TagLists" ? "mm-active" : "" }`} onClick={() => this.props.onClick()} to="/TagLists">Tag Lists</Link>
                </li>
                <li>
                  <Link className={`${ path === "Reports" ? "mm-active" : "" }`} onClick={() => this.props.onClick()} to="/Reports">Reports</Link>
                </li>
              </ul>
            </li>
            <li >
              <Link  to="/setting" ><i className="fa-solid fa-cogs"></i><span className="nav-text">Setting</span></Link>
            </li>
            <li >
              <Link  to="/login" onClick={() => logout()} ><i className="fa-solid fa-power-off"></i><span className="nav-text">Log Out</span></Link>
            </li>
          </MM>
			<div className="copyright">
				<p>
				  <strong>Moein intelligent System</strong> © {(new Date().getFullYear())} All
				  Rights Reserved
				</p>
			  </div>
			  </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
