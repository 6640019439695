import {format} from 'date-fns';
import { validateYupSchema } from 'formik';
import { OverlayTrigger } from 'react-bootstrap';
import { RenderTooltip } from '../../Elements/Elements';
import { ColumnFilter } from '../../FilteringTable/ColumnFilter';
import { WriteToolTipTable } from '../../Elements/Functions';

export const RTCOLUMNS = [
	{
		Header : 'Username ',
		Footer : 'Username',
		accessor: 'userName',
		Filter: ColumnFilter,
	},
	{
		Header : 'Target Link ',
		Footer : 'Target Link',
		accessor: 'targetLink',
		Cell: ({ value }) => {return <OverlayTrigger placement="top" delay={{ show: 500, hide: 100 }}overlay={RenderTooltip(1,"Username")}><a href={value}>Target Link</a></OverlayTrigger>},
		Filter: ColumnFilter,
	},
	{
		Header : 'Link ',
		Footer : 'Link',
		accessor: 'link',
		Cell: ({ value }) => {return <a href={value}><i className='fa-regular fa-link'></i></a>},
		Filter: ColumnFilter,
	},
	{
		Header : 'Text ',
		Footer : 'Text',
		accessor: 'text',
		Cell: ({ value }) => {return WriteToolTipTable(value)},
		Filter: ColumnFilter,
	},
	{
		Header : 'Order State ',
		Footer : 'Order State',
		accessor: 'orderState',
		Filter: ColumnFilter,
	},
	{
		Header : 'Created Date ',
		Footer : 'Created Date ',
		accessor: 'createdDate',
		Cell: ({ value }) => {return format(new Date(value), 'dd/MM/yyyy hh:mm a')},
		Filter: ColumnFilter,
	},
	{
		Header : 'Expire Date ',
		Footer : 'Expire Date ',
		accessor: 'expiryDate',
		Cell: ({ value }) => {return format(new Date(value), 'dd/MM/yyyy hh:mm a')},
		Filter: ColumnFilter,
	},
	{
		Header : "Finished Date",
		Footer : 'Finished Date ',
		accessor: 'finishedDate',
		Cell: ({ value }) => {
			console.log(value)
			if(value != undefined || value != null	)
				return format(new Date(value), 'dd/MM/yyyy hh:mm a')
			else
				return "Not Set"
			
		},
		Filter: ColumnFilter,
	},
	{
		Header : 'Operation Name',
		Footer : 'Operation Name',
		accessor: 'operationName',
		Filter: ColumnFilter,
	}
]

