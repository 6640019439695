import React,{ useEffect, useMemo } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination, useRowSelect,} from 'react-table';
import { TagListColumns } from './Columns';
import { Button, Col,  Modal,  Row, Spinner,  } from 'react-bootstrap';
import { useState } from 'react';
import { ToasSucces, ToastError } from '../../../../services/Toasts';
import { AxiosGetRequest, AxiosPostRequest, AxiosPutRequest } from '../../../../services/Connections';
import { Urls } from '../../../../services/ServerandUrls';
import {CheckboxSelection1 as CheckboxSelection} from "../../Elements/Elements"
import Select  from "react-select";
import { ChangeHandler, DeleteRow } from '../../Elements/Functions';




export const TagListsList = (props) => {
	const [show, setShow] = useState(false);
	const [Loader, setLoader] = useState(false);
	const [type, settype] = useState(0);
	const [Types, setTypeLists] = useState([]);
	const [RowData, setRowData] = useState({name:"",collectionType:""});


	const columns = useMemo( () => TagListColumns, [] )
	const data = useMemo( () => props.TagLists, [props.TagLists] )


	const tableInstance = useTable({
		columns,
		data,	
		initialState : {pageIndex : 0,pageSize:25},  
	}, useFilters, useGlobalFilter, usePagination,useRowSelect,
	(hooks)=>{
		hooks.visibleColumns.push((columns)=>{
			return [
				{
					id:"Counter",
					Header:({getToggleAllRowsSelectedProps}) => (
						<div style={{paddingTop:"2.5rem"}}>
						#
						</div>
					),
					Cell:({row}) => (
						<div>
							{row.index +1 }
						</div>
					),
					width:50
				},
				{
					id:"selection",
					Header:({getToggleAllRowsSelectedProps}) => (
						<CheckboxSelection {...getToggleAllRowsSelectedProps()}/>
					),
					Cell:({row}) => (
						<CheckboxSelection {...row.getToggleRowSelectedProps()}/>
					),
					width:50
				},
				...columns
			]
		})
	});
	
	const { 
		getTableProps, 
		getTableBodyProps, 
		headerGroups, 
		prepareRow,
		state,
		setPageSize,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		selectedFlatRows
	} = tableInstance
	

	const handleShow = (type,Data) => {
		console.log(Data)
		setShow(true);
		if(type == 1 )
		{
		setRowData({name:"",collectionType:""});
		settype(0)
		}
		else if(type == 2)
		{
		setRowData(Data);
		settype(1)
		}

	};
	
	const handleClose = () => setShow(false);


	const AddNew = () =>
	{
		if(RowData.name != undefined && RowData.name.replace(/^\s+|\s+$/g, "").length>0 && RowData.collectionType != "")
		{
			setLoader(true)
			let newobj = 
			{
				title:RowData.name
			}
			if(type === 0)
			{
			AxiosPostRequest(Urls.TagLists.add,RowData).then(response => {
				setLoader(false)
				if(response[0])
				{
					ToasSucces("Tag List has been added successfully ");
					props.setRefreshData(!props.RefreshData)
					setShow(false);
				}
				else
						ToastError("Tag List  didn't added")
				})
			}
			else if(type === 1)
			{
				AxiosPutRequest(Urls.TagLists.update,RowData).then(response => {
					setLoader(false)
					if(response[0])
					{
					ToasSucces("Tag List Updated successfully ");
					props.setRefreshData(!props.RefreshData)
					setShow(false);
					}
					else
					ToastError("Tag List  didn't Updated")
				})
			}
		}
		else
			ToastError("Please check your form",5)
	};

	
	const enter = (e)=>
	{
		if(e.key == "Enter")
			AddNew()
	};


	useEffect(() => 
    {
	  if(show)
	  document.querySelector("#insert_input").focus()
    }, [show])

    useEffect(() => 
    {
        AxiosGetRequest(Urls.TagLists.types).then(response => 
            {
            if(response[0])
            {
                var List = []
                for (let i = 0; i < response[1].data.length; i++) 
                {
                  List.push({"value":response[1].data[i].name , "label":response[1].data[i].name})
                }
                setTypeLists(List)
            }
            else
              ToastError("Tag Types didnt loaded")
            })
    }, [])


	const {globalFilter, pageIndex,pageSize} = state

	return(
		<>
				<div className="card-body">
					<div className="table-responsive">
						<div className='d-flex justify-content-end' style={{width:"100%",gap:"1rem"}}>
						{props.Loader ? <Spinner animation="border" className='mt-3' variant="primary" /> : "" }
							<Button variant='outline-danger' onClick={()=>DeleteRow(selectedFlatRows,"Tag List",Urls.TagLists.delete,props.setRefreshData,!props.RefreshData,setLoader)}>Delete </Button>
							<Button variant='outline-primary ' onClick={()=>handleShow(1)}>Add New </Button>
						</div>
						<table {...getTableProps()} className="table filtering-table table-responsive-lg">
							<thead>
							   {headerGroups.map(headerGroup => (
									<tr {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map(column => (
											<th {...column.getHeaderProps()}>
												{column.render('Header')}
												{column.canFilter ? column.render('Filter') : null}
											</th>
										))}
										<th className='pb-4' style={{textAlign:"center"}}  role='columnheader'> Edit</th>
									</tr>
							   ))}
							</thead> 
							<tbody {...getTableBodyProps()} className="" >
								{page.map((row,index) => {
									prepareRow(row)
									return(
										<tr {...row.getRowProps()} className={row.isSelected ? "TableRows SelectedRow":"TableRows"}>

											{row.cells.map((cell) => {
												return <td {...cell.getCellProps()} className={"c-pointer"}>
													 {cell.column.id =="value" ?
												cell.render('Cell')
												:cell.render('Cell')} 
												 </td>
											})}
											<td style={{textAlign:"center",width:"35%"}} ><Button variant='outline-primary ' onClick={()=>handleShow(2,row.original)}>Edit </Button></td>
										</tr>
									)
								})}
							</tbody>
						</table>
						<div className="d-flex justify-content-between">
							<span>
								Page{' '}
								<strong>
									{pageIndex + 1} of {pageOptions.length}
								</strong>{''}
							</span>
							<span className="table-index">
								Rows Count : {' '}
								<select
								value={pageSize}
								onChange={e => {
									setPageSize(Number(e.target.value))
								}}
								>
								{[10, 25, 50, 100,200].map(pageSize => (
									<option key={pageSize} value={pageSize}>
									Show {pageSize}
									</option>
								))}
								</select>
							</span>
							<span className="table-index">
								Go to page : {' '}
								<input type="number" 
									className="ml-2"
									defaultValue={pageIndex + 1} 
									onChange = {e => { 
										const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
										gotoPage(pageNumber)
									} } 
								/>
							</span>
						</div>
						<div className="text-center">	
							<div className="filter-pagination  mt-3">
								<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
								
								<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
									Previous
								</button>
								<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
									Next
								</button>
								<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
							</div>
						</div>
					</div>
				</div>
				<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{type === 0 ? "Add" : "Edit"} Tag List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
		<Row>
			<Col className='pr-0 d-flex  align-items-center' xl={3}>
				<h6> Name:</h6>
			</Col>
			<Col className='pl-0  'xl={7}>
				<input type='text' value={RowData.name} onKeyPress={(e) => enter(e)} onChange={(e) => ChangeHandler("name",e.target.value,setRowData,RowData)} className=' form-control  text-black  ' style={{height:"45px"}} id={"insert_input"} placeholder='Tag List Name' autoFocus/>
			</Col>
		</Row>
        <Row className='mt-4'>
        <Col className='pr-0 d-flex  align-items-center' xl={3}>
				<h6> Type:</h6>
			</Col>
            <Col className='pl-0   mt-2'xl={7}>
            <div className='form-group '>
               <Select isClearable defaultValue={Types.filter(option => option.label === RowData.collectionType)}  onChange={(e) => ChangeHandler('collectionType',e !=null ? e.value:null,setRowData,RowData)} options={Types}/>
            </div>
         </Col>
        </Row>
		</Modal.Body>
		<Modal.Footer>
		{Loader ? <Spinner animation="border" variant="primary" /> : "" }
          <Button variant="outline-primary" onClick={handleClose}>
            cancel
          </Button>
          <Button variant="primary" onClick={AddNew}>
           {type ? "Update":"Add"}
          </Button>
        </Modal.Footer>
      </Modal>
		</>
	)
	
}