
import { useEffect, useState } from "react";
import { Card, Col, Nav, Tab } from "react-bootstrap";
import { AxiosGetRequest } from "../../../../services/Connections";
import { Urls } from "../../../../services/ServerandUrls";
import { ToastError } from "../../../../services/Toasts";
import TableElement from "../../FilteringTable/TableElment";
import { RepliesCOLUMNS } from "./Columns";
import ReplyForm from "./ReplyForm";


export default function RepliesForm(props) {
	const [Loader, setLoader] = useState(false);
  const [RepliesList, setRepliesList] = useState([]);
	const [RefreshData, setRefreshData] = useState(false);

    const tabData = [
        {
          name: 'Reply Form',
          icon: 'ballot',
          content:<ReplyForm RefreshData={RefreshData} setRefreshData={setRefreshData} />
        },
        {
          name: 'Reply List',
          icon: 'list',
          content:
          <TableElement 
          ExcludedFields={["userName","createdDate","expiryDate","operationName","scheduledAdjustmentName","orderState","finishedDate"]} 
          Headers={RepliesCOLUMNS} 
          Type={"Replies"}   
          RefreshData={RefreshData} 
          setRefreshData={setRefreshData} 
          hasEdit={RepliesList.length> 0} 
          Loader={Loader} 
          setLoader={setLoader} 
          hasDelete={true} 
          hasDownload={true}
          downloadList={[["userName","link"],"Replies Links"]}
          DataList={RepliesList}
          setDataList={setRepliesList}
          />
        },
        
      ]



      useEffect(() => {
        setLoader(true);
        AxiosGetRequest(Urls.Replies.view,[{"HeadKey":"pageSize","HeadValue":1500}]).then(response => {
          setLoader(false);
          if(response[0])
          {
            setRepliesList(response[1].data)
          }
          else
            ToastError("Tweet List didn't load")
        })
      
      }, [RefreshData])
      




    return(
        <Col xl={12}>
          <Card>
            <Card.Body>
              <div className='default-tab'>
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                  <Nav as='ul' className='nav-tabs'>
                    {tabData.map((data, i) => (
                      <Nav.Item as='li' key={i}>
                        <Nav.Link eventKey={data.name.toLowerCase()}>
                          <i className={`fa-light fa-${data.icon} mr-2`} />
                          {data.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content className='pt-4'>
                    {tabData.map((data, i) => (
                      <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                        {data.content}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Card.Body>
          </Card>
        </Col>
    )
}