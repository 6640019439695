import { Card, Col, Nav, Tab } from "react-bootstrap";
import TableElement from "../../FilteringTable/TableElment";
import { COLUMNS } from "./Columns";
import TrendsForm from "./TrendsForm";








export default function Trends(props) {

    const tabData = [
        {
          name: 'Trends Form',
          icon: 'ballot',
          content:<TrendsForm/>
        },
        {
          name: 'Trends List',
          icon: 'list',
          content:
          <TableElement Headers={COLUMNS} Type={"Tweet"} hasDelete={true} DataList={[]}/>
        }
        
      ]


    return(
        <Col xl={12}>
          <Card>
            <Card.Body>
              <div className='default-tab'>
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                  <Nav as='ul' className='nav-tabs'>
                    {tabData.map((data, i) => (
                      <Nav.Item as='li' key={i}>
                        <Nav.Link eventKey={data.name.toLowerCase()}>
                          <i className={`fa-light fa-${data.icon} mr-2`} />
                          {data.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content className='pt-4'>
                    {tabData.map((data, i) => (
                      <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                        {data.content}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Card.Body>
          </Card>
        </Col>
    )
}