import { useEffect, useState } from "react";
import { Col, OverlayTrigger } from "react-bootstrap";
import { RenderTooltip } from "../Elements/Elements";
import { formatDistance } from "date-fns";





export default function ActionListItem (props) {
  const [showItem, setShowItem] = useState(props.show);


  useEffect(() => {
    setTimeout(() => {
      setShowItem(true);
    }, props.delay);
  }, []);

    return(
        <div key={props.Index} className={'ListItem ' + props.Data.orderType +" "+(!props.Data.webReported ? " reportCardNew ":"") +" " + `action-list-item${showItem ? " show" : ""}`}      
        style={{ animationDelay: `${props.delay}ms` }} >        
        {props.Data.userName ? 
        <Col className='d-flex align-items-center justify-content-start' sm={3} >
          <OverlayTrigger placemen="top" delay={{ show: 100, hide: 100 }}overlay={RenderTooltip(1,("User"))}>
          <i className='fa-solid fa-user'></i>
          </OverlayTrigger>
          &nbsp;:<a href={"https://twitter.com/"+props.Data.userName} rel='noreferrer' target='_blank' ><h5 className='m-0 SmallText  font-w700 ActionitemText pt-1'>&nbsp;{props.Data.userName}</h5></a>
        </Col>: "" 
        }
        {props.Data.targetUserName ?
        <Col className='d-flex align-items-center justify-content-start p-0' sm={3}>
           <OverlayTrigger placemen="top" delay={{ show: 100, hide: 100 }}overlay={RenderTooltip(1,("Target User"))}>
            <i className='fa-solid fa-user pointer' style={{zIndex:"3"}}></i>
          </OverlayTrigger>
          <i className='fa-light fa-crosshairs position-absolute' style={{marginLeft:"-1px",marginBottom:"9px",zIndex:"1"}}></i>
          &nbsp;:
          <a href={"https://twitter.com/"+props.Data.targetUserName} rel='noreferrer' target='_blank' ><h5 className='m-0 SmallText  font-w700 ActionitemText'>{props.Data.targetUserName}</h5></a>
        </Col>:""}
        {props.Data.massege  ? 
        <Col className='d-flex align-items-center justify-content-start p-0 pl-1' >
          <OverlayTrigger placemen="top" delay={{ show: 100, hide: 100 }}overlay={RenderTooltip(1,("Message"))}>
            <i className='fa-solid fa-comment c-pointer  ActionsIcons'></i>
          </OverlayTrigger>
          :&nbsp;
          <OverlayTrigger placemen="top" delay={{ show: 100, hide: 100 }}overlay={RenderTooltip(1,(props.Data.massege))}>
          <p className='m-0 MediumText font-w500 ActionitemText' style={{flex:1}}>{props.Data.massege} </p>
          </OverlayTrigger>
        </Col>
        :""}
        {props.Data.timedone ? 
        <Col className='d-flex align-items-center justify-content-center mr-1'  sm={2}>
          {/* <OverlayTrigger placement="top" delay={{ show: 100, hide: 100 }}overlay={RenderTooltip(1,(formatDistance(new Date(props.Data.timedone), new Date()))+" ago ")}>
            <i className='fa-solid fa-clock c-pointer  ActionsIcons'></i>
          </OverlayTrigger> */}
          <p className='m-0 SmallText NoWrap font-w500 ' style={{flex:1}}>          
          {formatDistance(new Date(props.Data.timedone), new Date())} ago
          </p>
          </Col> :
           ""}
        {props.Data.thisLink ? <Col className='d-flex align-items-center justify-content-center' sm={1} >
          <OverlayTrigger placement="top" delay={{ show: 100, hide: 100 }}overlay={RenderTooltip(1,"Link")}>
            <a href={props.Data.thisLink}  rel={'noreferrer'} target={'_blank'} >
              <i className='fa-solid fa-link  ActionsIcons'></i>
            </a>
          </OverlayTrigger>
        </Col>:""}
        {props.Data.targetLink ? <Col className='d-flex align-items-center justify-content-center' sm={1} >
        <OverlayTrigger placement="top" delay={{ show: 100, hide: 100 }}overlay={RenderTooltip(1,"Target Link")}>
          <a href={props.Data.targetLink}  rel={'noreferrer'} target={'_blank'} >
            <i className='fa-solid fa-external-link  ActionsIcons'></i>
          </a>
        </OverlayTrigger>
        </Col>:""}
        
      </div>
    )
}