import { useState  } from "react";
import { Button, Card, Col, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import { ToasSucces, ToastError } from "../../../services/Toasts";
import { MultiSelectELM, } from "../Elements/Elements";
import { hasWhiteSpace, read_clipboard} from "../Elements/Functions";
import { AxiosPostRequest } from "../../../services/Connections";
import { Urls } from "../../../services/ServerandUrls";


export default function AddAccounts (props) {
    const [Loader, setLoader] = useState(false);
    const [BulkList, setBulkList] = useState([]);
    const [BulkAction, setBulkAction] = useState(false);

    const [Account, setAccount] = useState({
    "userName": "",
    "password": "",
    "email": "",
    "phoneNumber": "",
    "genderType": "",
    "userType": "Admin",
    "isActive": true,
    "socialAreeas": null
    }); 

    const AccountSet = (type,value) => 
    {
        setAccount({
            ...Account,
            [type]: value 
          });
    }

    const Getmultiselect = (e) => {
        var list = []
        for (let i = 0; i < e.length; i++) 
        {
            list[i] = e[i].value
        }
        return list
    }

    const SendAccount = () => {
        console.log(BulkList)
        if(!BulkAction)
        for (const key in Account) 
        {
            if(Account[key].length == 0)
                {
                for (const keys in Account) 
                {
                    if( document.querySelector("#"+keys) !== null)
                    {
                        if(Account[keys].length == 0)
                        document.querySelector("#"+keys).classList.add("shadow-warning")
                        else{
                        document.querySelector("#"+keys).classList.remove("shadow-warning")
                        }
                    }
                }
                ToastError("Check your entries");
                setLoader(false)
                return
                }
                else if( document.querySelector("#"+key) !== null)
                document.querySelector("#"+key).classList.remove("shadow-warning")
            setLoader(true)
        }
        AxiosPostRequest(Urls.Acounts.add,(BulkAction ? BulkList:[Account])).then(response => {
            setLoader(false)
				if(response[0])
				{
					ToasSucces("Account has been added successfully ");
                    props.setRefreshData(!props.RefreshData)
				}
				else
                    ToastError("Account  didn't added")
				})
    }

    const CheckSpace = (e,type) => {
        if(hasWhiteSpace(e))
        {
            ToastError(type + " can't contain spaces",1)
            document.querySelector("#"+type).classList.add(...["shadow-warning"])
        }
        else
        (
            document.querySelector("#"+type).classList.remove(...["shadow-warning"])
        )
    }

    const BulkFunction = (e) =>{
        ClearData()
        setBulkAction(e.target.checked)
    }

    const ClearData = () => 
    {
        setAccount({
            "userName": "",
            "password": "",
            "email": "",
            "phoneNumber": "",
            "genderType": "",
            "userType": "Admin",
            "isActive": true,
            "socialAreeas": null
            });
    }

    const CustomMulti = (e) =>{
        if(BulkAction)
            {
                BulkList.map(Item =>
                {
                    Item["socialAreeas"] = Getmultiselect(e)
                })
            }
        else
        setAccount
        ({
            ...Account,
            socialAreeas: Getmultiselect(e)
        });
        
    }

    const ReadFile = (event,keysArr) => {
        if(event.target.files[0].type=="text/plain")
        {
            var input = event.target;
            var reader = new FileReader();
            var List = [];
            document.getElementById("TextFileName").innerText = input.files[0].name
            reader.onload = function() {      
                var Users = reader.result.split('\r\n')
                Users.map((User,Index) => 
                    {
                    var PersonOBJ = {}
                    var Info = User.split(",")
                    Info.map((InfoData,index) =>
                        {   
                            if(InfoData.length>0)
                            {
                            PersonOBJ[keysArr[index]]=InfoData
                            PersonOBJ["userType"] = "Admin"
                            PersonOBJ["isActive"] = true
                            }
                        })
                        if(Object.keys(PersonOBJ).length>0)
                        List.push(PersonOBJ)
                    })
                    setBulkList(List);
            };
            reader.readAsText(input.files[0]);   
        }
        else
        ToastError("Wrong File Type")
    }

      
    
    return(
        <>
        <Card>
                <Row className={"w-100 ml-0 pt-3 pb-4" + (BulkAction ? " bg-light ":"")}  style={{borderTop:"1px solid #dbdbdb"}} >
                    <Col className="d-flex align-items-center"  xl={6} sm={9} >
                        <div className=' d-flex ' style={{width:"30%"}} >
                            <label htmlFor="userName" className='mr-2 mt-1'>
                                <i className="fa-regular fa-user mr-1"></i>
                                Username
                            </label> 
                        </div>
                        <div className="d-flex">
                            <input  disabled={BulkAction} onBlur={(e) => CheckSpace(e.target.value,"userName")} onChange={(e) => AccountSet("userName",e.target.value)} value={Account.userName} type='text' id={"userName"} />
                            <span onClick={()=>BulkAction ? "":read_clipboard("userName",setAccount,Account)}className=' input-group-append c-pointer border border-dark input-group-text ' style={{minWidth:"30px"}}>
                                <i  className="fa-regular fa-paste"></i>
                            </span>
                        </div>
                    </Col>
                    <Col className="d-flex align-items-center mt-2"  xl={6} sm={9}>
                        <div className=' d-flex ' style={{width:"30%"}}>
                            <label htmlFor="password" className='mr-2 mt-1'>
                                <i className="fa-regular fa-key mr-1"></i>
                                Password
                            </label> 
                        </div>
                        <div className="d-flex">
                            <input  disabled={BulkAction} onChange={(e) => AccountSet("password",e.target.value)}   value={Account.password}  type='text' id={"password"} />
                            <span onClick={()=>read_clipboard("password",setAccount,Account)} className=' input-group-append c-pointer border border-dark input-group-text ' style={{minWidth:"30px"}}>
                                <i  className="fa-regular fa-paste"></i>
                            </span>
                        </div>		
                    </Col>
                </Row>
                <Row className={"w-100 ml-0 pt-3 pb-4" + (BulkAction ? " bg-light ":"")}  style={{borderTop:"1px solid #dbdbdb"}} >
                    <Col className="d-flex align-items-center"  xl={6} sm={9}>
                        <div className=' d-flex ' style={{width:"30%"}}>
                            <label htmlFor="email" className='mr-2 mt-1 '>
                                <i className="fa-regular fa-envelope mr-1"></i>
                                Email
                            </label> 
                        </div>
                        <div className="d-flex">
                            <input  disabled={BulkAction} onChange={(e) => AccountSet("email",e.target.value)}  value={Account.email} type='Email' id={"email"} />
                            <span onClick={()=>read_clipboard("email",setAccount,Account)} className=' input-group-append c-pointer border border-dark input-group-text ' style={{minWidth:"30px"}}>
                                <i  className="fa-regular fa-paste"></i>
                            </span>
                        </div>		
                    </Col>
                    <Col className="d-flex align-items-center mt-2"  xl={6} sm={9}>
                        <div className=' d-flex ' style={{width:"30%"}} >
                            <label htmlFor="phoneNumber" className='mr-2 mt-1'>
                                <i className="fa-regular fa-phone mr-1"></i>
                                Phone Number
                            </label> 
                        </div>
                        <div className="d-flex">
                            <input  disabled={BulkAction} onChange={(e) => AccountSet("phoneNumber",e.target.value)}  value={Account.phoneNumber}  type='Number' id={"phoneNumber"} />
                            <span onClick={()=>read_clipboard("phoneNumber",setAccount,Account)} className=' input-group-append c-pointer border border-dark input-group-text ' style={{minWidth:"30px"}}>
                                <i  className="fa-regular fa-paste"></i>
                            </span>
                        </div>	
                    </Col>
                </Row>
                <Row className="w-100 ml-0 pt-3 "  style={{borderTop:"1px solid #dbdbdb"}} >
                    <Col className="d-flex align-items-center"  xl={6} sm={9}>
                        <div className=' d-flex align-items-center' style={{width:"40%"}}>
                            <div style={{zIndex:0}} className='custom-control custom-checkbox mr-3 ml-1'>
                            <input
                            type='checkbox'                      
                            className='custom-control-input'
                            onChange={(e) => BulkFunction(e)}
                            id='MaleCheckBox1'
                            />
                            <label htmlFor="MaleCheckBox1" className=' custom-control-label mr-2 mt-1 '>
                                <i className="fa fa-list mr-1"></i>
                                Bulk List
                            </label> 
                            <OverlayTrigger  placement="bottom" delay={{ show: 500, hide: 100 }}overlay={        
                                <Tooltip id="button-tooltip">
                                You can add accounts by using a list text file. Click on <i className="fa-thin fa-file"></i> icon to download template. Please mind spaces and punctuation marks
                                </Tooltip>}>
                                <i className="fa-thin fa-exclamation-circle c-pointer mt-2"></i>
                            </OverlayTrigger>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                        <div className={'file '+ (BulkAction ? "file--upload":"file--uploadDIS")}>
                            <label htmlFor='input-file'>
                                <i className="fa fa-upload"></i>Upload
                            </label>
                            <input disabled={!BulkAction} onChange={(e)=>ReadFile(e,["userName","password","email","phoneNumber","genderType"])} id='input-file' type='file' />
                        </div>
                        <a href="./AccountTemplate.txt"  download><i className="fa-regular fa-file ml-3 Temp-Icon" style={{fontSize:"1.5rem"}} ></i></a>
                        <h6 id="TextFileName" className="ml-3 mb-0 ">No Files!</h6>
                        </div>		
                    </Col>

                </Row>
                <Row className="w-100 ml-0 pt-4 pb-4 mt-4"  style={{borderTop:"1px solid #dbdbdb"}}>
                <Col className="d-flex align-items-center"   xl={2}>
                    <div className=' form-check'>
                        <label htmlFor="Subject" className='form-check-label'>
                            Subject
                        </label>
                        <OverlayTrigger  placement="bottom" delay={{ show: 500, hide: 100 }}overlay={        
                                <Tooltip id="button-tooltip">
                                Selected Subject(s) will be added to all "Bulk List" accounts
                                </Tooltip>}>
                                <i className="fa-thin fa-exclamation-circle c-pointer mt-2 ml-1"></i>
                            </OverlayTrigger>
                    </div>
                    </Col>
                    <Col xl={4}>
                    <div className='form-group'>
                    <label className="d-sm-none d-md-block pt-3"> </label>
                    <MultiSelectELM id={"socialAreeas"} ChangeFunc={(e)=>CustomMulti(e)} Optionss={Account.socialAreeas}  Data={props.SocialList} />
                  </div>
                    </Col>
                  <Col  xl={6} className="d-flex align-items-center">
                    <div  
                          id={"genderType"}
                          className="d-flex flex-row mt-2">
                      <h5  className=" mb-0  text-nowrap">Gender:</h5>
                      <div style={{zIndex:0}} className='custom-control  mr-3 ml-3'>
                        <input className='form-check-input border border-warning' 
                            type='radio' 
                            name='gridRadios'
                            value={Account.genderType} 
                            checked = {Account.genderType == "Male" ? true:false}
                            id={"Male"}
                            disabled={BulkAction}
                            onChange={()=>AccountSet("genderType","Male")}
                            />
                            <label
                            className='form-check-label'
                            htmlFor='Male'
                            >
                            Male
                          </label>
                      </div>
                      <div style={{zIndex:0}}  className='custom-control  mr-3 ml-3'>
                          <input
                                disabled={BulkAction}
                                className='form-check-input '
                                type='radio'
                                name='gridRadios'
                                id={"Female"}
                                checked = {Account.genderType == "Female" ? true:false}
                                onChange={()=>AccountSet("genderType","Female")}
                                />
                          <label
                          className='form-check-label'
                          htmlFor='Female'
                          >
                          Female
                          </label>
                      </div>
                    </div>
                    </Col>
                </Row>
                

        </Card>
       <Row className="flex-row-reverse" style={{gap:"0.6rem"}}>
          <Button onClick={() => SendAccount()} >Send </Button>
          <Button variant='outline-primary ' onClick={()=>ClearData()}>Clear </Button>
            {Loader ? <Spinner animation="border" variant="primary" /> : "" }
       </Row>
       </>
    )
}
