import { Card, Col, Nav, Tab } from "react-bootstrap";
import {  ContentCOLUMNS } from "./ContentColumns";
import ContentForm from "./ContentForm";

import StylesForm from "./StyleForms";
import TableElement from "../../FilteringTable/TableElment";
import { useState } from "react";


export default function Contents(props) {
  const [Loader, setLoader] = useState(false);

    const tabData = [
        {
          name: 'Contents Form',
          icon: 'plus',
          content:<ContentForm />
        },
        {
          name: 'Contents List',
          icon: 'list',
          content:
            <TableElement Loader={Loader} setLoader={setLoader} Headers={ContentCOLUMNS} Type={"Tweet"} hasDelete={true} DataList={[]}/>
        },
        {
            name: 'Style Form',
            icon: 'plus',
            content:
              <StylesForm/>
          },
          
        {
            name: 'Style List',
            icon: 'list',
            content:
            <TableElement Loader={Loader} setLoader={setLoader} Headers={ContentCOLUMNS} Type={"Tweet"} hasDelete={true} DataList={[]}/>
          },
        
      ]


    return(
        <Col xl={12}>
          <Card>
            <Card.Body>
              <div className='default-tab'>
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                  <Nav as='ul' className='nav-tabs'>
                    {tabData.map((data, i) => (
                      <Nav.Item as='li' key={i}>
                        <Nav.Link eventKey={data.name.toLowerCase()}>
                          <i className={`la la-${data.icon} mr-2`} />
                          {data.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content className='pt-4'>
                    {tabData.map((data, i) => (
                      <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                        {data.content}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Card.Body>
          </Card>
        </Col>
    )
}