import { useState ,useRef, useEffect} from "react";
import { Button, Card, Col, ListGroup, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
// import { ToastError } from "../../../../services/Toasts";
import { PreventNegative } from "../../../../services/MathMatics";
import { ChangeHandler, CheckEmpty, ClearData, read_clipboard } from "../../Elements/Functions";
import { AccountColumn, GenderColumn, OperationColumn, PostFunction, RenderTooltip, SubjectColumn } from "../../Elements/Elements";
import { AxiosGetRequest } from "../../../../services/Connections";
import { Urls } from "../../../../services/ServerandUrls";
import { ToastError } from "../../../../services/Toasts";
 

export default function CoverForm (props) {
  const [ViewOnlyVal, setViewOnlyVal]= useState(false);
  const [AvailAccounts, setAvailAccounts] = useState(0)
  const [AvailAccountsLikes, setAvailAccountsLikes] = useState(0)
  const [OperationList, setOperationList] = useState([])
  const [AccountList, setAccountList] = useState([])
  const MaleCheckRef = useRef(null)
  const FemaleCheckRef = useRef(null)
	const [Loader, setLoader] = useState(false);

  const [Options, setOptions] = useState(
    {
      targetLink:"",
      countLikes:0,
      countRetweets:0,
      operationId:null,
      socialAreas:[],
      privateRobotAccountId:null,
      genderType:"FemaleMale",
      isJustSee:false
    }
  );

 
  const ViewOnlyFunction = (Value) => {
    setViewOnlyVal(Value)
    setOptions({
      ...Options,
      countLikes: 0 ,
      countRetweets:0,
      isJustSee:Value
    });
  }


      const CleanData = {
        targetLink:"",
        countLikes:0,
        countRetweets:0,
        operationId:null,
        socialAreas:[],
        privateRobotAccountId:null,
        genderType:null
      }


  
      const SendCover = () => 
      {
        if(Options.countLikes <= AvailAccountsLikes && Options.countRetweets <= AvailAccounts)
        {
          if(ViewOnlyVal)
            {
              if(CheckEmpty(Options.targetLink,"Link","1"))
                if(CheckEmpty(Options.socialAreas.length,"Subject","1"))
                  if(CheckEmpty(Options.genderType,"Gender","1"))
                    PostFunction("Cover","Cover.add",Options,props.setRefreshData,props.RefreshData,setLoader)
            }
          else
            {
              if(CheckEmpty(Options.targetLink,"Link","1"))
                if(CheckEmpty(Options.socialAreas.length,"Subject","1"))
                  if(CheckEmpty(Options.genderType,"Gender","1"))
                    if(CheckEmpty(Options.countLikes,"Likes","1") || CheckEmpty(Options.countRetweets,"Retweets","1"))
                      PostFunction("Cover","Cover.add",Options,props.setRefreshData,props.RefreshData,setLoader)
            }
        }
        else
        {
          ToastError("Not enough account to run command",1)
        }
      }
        
                 
      
        useEffect(() => {
          setLoader(true)
          AxiosGetRequest(Urls.Acounts.AvailAccounts,[{"HeadKey":"orderType","HeadValue":"retweet"}]).then(response => {
          setLoader(false)
            if(response[0])
            {
              setAvailAccounts(response[1].data)
            }
          })
          AxiosGetRequest(Urls.Acounts.AvailAccounts,[{"HeadKey":"orderType","HeadValue":"like"}]).then(response => {
            setLoader(false)
              if(response[0])
              {
                setAvailAccountsLikes(response[1].data)
              }
            })

      }, [])

      
    return(
        <>
        <div className=" mobile-send-button">
        <Button onClick={()=>SendCover()}>Send </Button>
        </div>
        <Card>
            <ListGroup >
                <div className="d-flex flex-row justify-content-between">
                    <h4>Cover form</h4>
                </div>
                <div className='input-group  mb-3 input-primary ' >
                      <div className='input-group-prepend'>
                        <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 100 }}overlay={RenderTooltip(1,"Paste Clipboard")}>
                          <span onClick={() =>read_clipboard("targetLink",setOptions,Options)} className='input-group-text c-pointer'><i className="fa-thin fa-paste"></i></span>
                        </OverlayTrigger>
                      </div>
                      <input
                        type='text'
                        className='form-control input-default text-black'
                        placeholder="Link"
                        id={"Link1"}
                        value={Options.targetLink} 
                        onChange={(e) => ChangeHandler('targetLink',e.target.value,setOptions,Options)}/>
                </div>
            </ListGroup>
                  <Row className={"w-100 ml-0 pt-3 pb-4 mt-4 " }  style={{borderTop:"1px solid #dbdbdb"}} >
                  <Col xl={3}>
                    <div className='form-group'>
                      <label><i className="fa fa-heart mr-1"></i>Likes:</label>
                      <input
                        type='number'
                        className='form-control input-default p-2  text-black'
                        placeholder="Likes"
                        value={Options.countLikes}
                        id={"Likes1"}
                        disabled={ViewOnlyVal}
                        style={{height:"2.4rem"}}
                        onChange={(e)=>ChangeHandler('countLikes',e.target.value,setOptions,Options)}
                        onKeyPress={(e)=>PreventNegative(e)}
                      />
                    </div>
                    </Col>
                    <Col xl={3}>
                    <div className='form-group'>
                    <label><i className="fa fa-retweet   mr-1"></i>Retweet:</label>
                      <input
                        type='number'
                        className='form-control input-default p-2  text-black'
                        value={Options.countRetweets}
                        id={"Retweets1"}
                        placeholder="Retweet"
                        disabled={ViewOnlyVal}
                        style={{height:"2.4rem"}}
                        onChange={(e)=>ChangeHandler('countRetweets',e.target.value,setOptions,Options)}
                        onKeyPress={(e)=>PreventNegative(e)}
                      />
                    </div>
                    </Col>
                    <Col sm={4} xl={3} md={3} className={"d-flex mt-3 ml-0 pl-0 align-items-center"} >
                    <div style={{zIndex:0}} className='custom-control custom-checkbox mr-3 ml-3 pb-2'>
                        <input
                        type='checkbox'                      
                        className='custom-control-input'
                        id='View_Only12'
                        onChange={(e)=>ViewOnlyFunction(e.target.checked)}
                        />
                        <label
                        className='custom-control-label text-nowrap'
                        htmlFor='View_Only12'
                        >
                        View Only
                        <OverlayTrigger  placement="bottom" delay={{ show: 500, hide: 100 }}overlay={
                          <Tooltip id="button-tooltip">
                            Just viewing tweets and adding to their impression.
                            this will disable Likes and Retweet actions.
                          </Tooltip>
                        }>
                        <i class="fa-thin fa-exclamation-circle c-pointer ml-1"></i>
                        </OverlayTrigger>
                        </label>
                    </div>
                    <input
                        className='form-control  input-default p-2  text-black'
                        style={{height:"2.4rem",width:"8rem",marginTop:"-6px"}}
                        placeholder={"Acounts count"}
                        disabled={!ViewOnlyVal}
                      />
                    </Col>
                  <GenderColumn CustomClass={"mt-1"} customID={1}  xl={"3"} Options={Options} setOptions={setOptions} MaleRef={MaleCheckRef} FemaleRef={FemaleCheckRef} />
                </Row>
                <Row className="w-100 ml-0 pt-3 pb-2"  style={{borderTop:"1px solid #dbdbdb"}} >
                  <OperationColumn OperationList={OperationList} Options={Options} setOptions={setOptions}/>
                  <SubjectColumn ID={"Subject1"}
                    Options={Options}
                    setOptions={setOptions} 
                    setAvailAccounts1={setAvailAccounts} 
                    setAvailAccounts2={setAvailAccountsLikes} 
                    setLoader={setLoader} 
                    type1={"like"} 
                    type2={"retweet"} 
                    double={true}/>

                  <AccountColumn AccountList={AccountList} Options={Options} setOptions={setOptions} />
                </Row>

        </Card>
       <Row className="flex-row-reverse" style={{gap:"0.6rem"}}>
          <Button onClick={()=> SendCover()}>Send </Button>
          <Button variant='outline-primary ' onClick={()=> ClearData(setOptions,CleanData)}>Clear </Button>
          <h5 className="mt-3 mr-2"> Available Accounts for retweet : {AvailAccounts}</h5>
          <h5 className="mt-3 mr-2"> Available Accounts for like {AvailAccountsLikes}</h5>
          {Loader ? <Spinner style={{marginTop:"1rem"}} animation="border" className='mb-3' variant="primary" /> : "" }
       </Row>
       </>
    )
}