import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);


function GradientArea(props) {

  const data = {
    defaultFontFamily: "Poppins",
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug","Sep","Oct","Nov","Dec"],
    datasets: [
      {
        label: props.ChartOptions.Label,
        data: props.ChartOptions.Data,
        backgroundColor: "rgba(30,170,231, 0.2)",
        borderColor: "rgba(30,170,231,1)",
        borderWidth: "1",
        fill:true
      },
    ],
  };
  
  const options = {
    plugins:{
      legend: false,
    },
    scales: {
      y: 
        {
      max: props.ChartOptions.Max + (Math.floor(props.ChartOptions.Max/4)),
          min: 0,
          ticks: {
            beginAtZero: true,
            padding: 5,
            stepSize: props.ChartOptions.stepSize
          },
          
        },
      
      x: 
        {
          ticks: {
            padding: 5,
            autoSkip: false
          },
        },
      
    },
      responsive: true,
    maintainAspectRatio: false
  };

  return (
    <>
      <Line data={data} options={options}  />
    </>
    )
}
export default GradientArea;
