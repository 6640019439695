import { forwardRef, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import TimeRangePicker from '@wojtekmaj/react-timerange-picker'
import { ChangeHandler } from "../../Elements/Functions";
import "react-datepicker/dist/react-datepicker.css";
import { isValid } from "date-fns";
import { VncScreen } from 'react-vnc';



export default function OperationWizard(props)
{
  let CurrentTime = new Date().getHours()+":"+new Date().getMinutes()
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDatee, endDatee] = dateRange;
  const [TimeValue, setTimeValue] = useState([CurrentTime, CurrentTime]);
      const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button className="rounded-lg bg-white border p-3 DatePicker-Custom" onClick={onClick} ref={ref}>
          {value}
        </button>
      ));

      useEffect(() => {
        const Today = new Date();
        var month = Today.getMonth() + 1; 
        var day = Today.getDate();
        var year = Today.getFullYear();
        var monthEnd = Today.getMonth() + 1;
        var dayEnd = Today.getDate();
        var yearEnd = Today.getFullYear();
        var newdate = new Date(year + "-" + month + "-" + day);
        var EndDate = new Date(yearEnd + "-" + monthEnd + "-" + dayEnd);

        if(startDatee !== null)
        {
        month = startDatee.getMonth() + 1; 
        day = startDatee.getDate();
        year = startDatee.getFullYear();
        newdate = new Date(year + "-" + month + "-" + day);

        if(!isNaN(TimeValue[0].slice(0,2))) 
          {newdate.setHours(TimeValue[0].slice(0,2))}
        else 
          {newdate.setHours(TimeValue[0].slice(0,1))}
        if(!isNaN(TimeValue[0].slice(-2)))
          newdate.setMinutes(TimeValue[0].slice(-2))
        else 
          newdate.setMinutes(TimeValue[0].slice(-1))
        }
        if(endDatee !== null)
        {
          monthEnd = endDatee.getMonth() + 1;
          dayEnd = endDatee.getDate();
          yearEnd = endDatee.getFullYear();
          EndDate = new Date(yearEnd + "-" + monthEnd + "-" + dayEnd);
          if(!isNaN(TimeValue[1].slice(0,2))) 
            {EndDate.setHours(TimeValue[1].slice(0,2))}
          else 
            {EndDate.setHours(TimeValue[1].slice(0,1))}
          if(!isNaN(TimeValue[1].slice(-2)))
            EndDate.setMinutes(TimeValue[1].slice(-2))
          else 
            EndDate.setMinutes(TimeValue[1].slice(-1))
        }

        
        if(isValid(EndDate)&&isValid(newdate))
        {

        
        props.setOptions({
          ...props.Options,
          endDate: EndDate.toISOString(),
          startDate:newdate.toISOString()
        });}
      }, [dateRange,TimeValue])
      const ref = useRef();
      
    return(
      <>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className='form-group'>
            <Row>
                <Col>
                <h4>Operation Title</h4>
                <input
                type='text'
                className='form-control input-default rounded-lg text-black'
                placeholder="Operation's Title"
                value={props.Options.name}
                onChange={e => ChangeHandler("name",e.target.value,props.setOptions,props.Options)}
                />
                </Col>
                <Col>
                <h4>Hashtag</h4>
                <input
                type='text'
                className='form-control input-default rounded-lg text-black'
                placeholder="Hashtag"
                value={props.Options.Hashtagh}
                onChange={e => ChangeHandler("Hashtagh",e.target.value,props.setOptions,props.Options)}
                />
                </Col>
            </Row>
            <Row className="mt-2 align-items-end">
                <Col lg={2}>
                  <div className="d-flex flex-row co-lg-6">
                    <div >
                    <h4>Combined?</h4>
                        <div className='form-group mb-0'>
                            <label className='radio-inline mr-3'>
                              <input type='radio' name='combineRadio' onChange={() => ChangeHandler("combineBotOrder",true,props.setOptions,props.Options)} /> yes
                            </label>
                            <label className='radio-inline mr-3'>
                              <input defaultChecked type='radio' name='combineRadio' onChange={() => ChangeHandler("combineBotOrder",false,props.setOptions,props.Options)} /> no
                            </label>
                        </div>
                    </div>
                  </div>
                </Col> 
                <Col className="mt-2" xl={3}  >
                  <h4>Calendar:</h4>
                  <ReactDatePicker 
                  selectsRange={true}
                  startDate={startDatee}
                  endDate={endDatee}
                  onChange={(update) => {setDateRange(update);}}
                  customInput={<ExampleCustomInput />}
                  withPortal/>
                </Col>
                <Col className="mt-2" xl={4} >
                  <div>
                    <h4 >Time range:</h4>
                    <TimeRangePicker className="pb-1" clockIcon={null} disableClock={true} clockClassName={" position-relative "} format={"HH:mm"} onChange={setTimeValue} value={TimeValue} />
                  </div>
                </Col>
            </Row>

          </div>
        </form>
      </>

    )
}