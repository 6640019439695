import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

function LineChart(props) {
  // Calculate max value from all datasets
  const allDataPoints = props.ChartData.flatMap(dataSet => dataSet[1]);
  const maxNumber = Math.max(...allDataPoints);
  const colors = [
    "rgba(30,170,231,1)",
    "rgb(196, 193, 7)",
    "rgb(168, 0, 56)",
    "rgb(1, 148, 1)",
    "rgb(166, 0, 166)",
    "rgb(0, 0, 0)",
    "rgb(255, 81, 0)"
    // Add more colors as needed
  ];

 
  
  const data = {
    defaultFontFamily: "Poppins",
    labels: [
      "1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th",
      "11th", "12th", "13th", "14th", "15th", "16th", "17th", "18th", "19th", "20th",
      "21st", "22nd", "23rd", "24th", "25th", "26th", "27th", "28th", "29th", "30th"
    ],
    datasets: props.ChartData.map((dataSet,index) => ({
      label: dataSet[0],
      data: dataSet[1],
      backgroundColor: colors[index % colors.length], // Use unique color for each line
      borderColor: colors[index % colors.length],
      borderWidth: 1,
      fill: false
    })),
  };

  const options = {
    plugins: {
      legend: true,
    },
    scales: {
      y: {
        max: maxNumber ? maxNumber + Math.floor(maxNumber / 4) : 1000 ,  // Add 50% buffer to maxNumber
        min: 0,
        ticks: {
          beginAtZero: true,
          padding: 5,
          stepSize: Math.floor(Math.round(maxNumber / 4)),
        },
      },
      x: {
        ticks: {
          padding: 5,
          autoSkip: false,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <>
      <Line data={data} options={options} />
    </>
  );
}

export default LineChart;
