import Wave from "react-wavify";





export default function PerformanceCard(props) {


    return(
        <div className="col-xl-4 col-lg-6 col-md-5 col-sm-4">
            <div className="card  border card-body overflow-hidden p-0 d-flex rounded Performance_Card">
              <div className="p-0 text-center mt-3">
                <h3 className="text-white fs-15 font-w700">{props.CardName}</h3>
                <h3 className="text-white fs-12 mb-0 font-w600"id={props.TextID}>
                  0
                </h3>
              </div>
              <Wave fill="#ff844b" paused={false} options= {{height: 5,amplitude: 15,speed: 0.13, points: 3}} /> 
            </div>
          </div>
    )
}