import React from "react";
import { Radar } from "react-chartjs-2";

import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
  Legend
);


function RadarChart(props) {
  console.log(props.RadarData)
  const data = {
    defaultFontFamily: "Poppins",
    labels: [
      "Impressions",
      "Engagements",
      ["New","Followers"],["Video","Views"],
      "Replays",["Profile","Visits"],
    ],
    datasets: [
      {
        label: props.RadarData.userName,
        data: [props.RadarData.impressions,
          props.RadarData.engagements,
          props.RadarData.newFollowers,
          props.RadarData.videoViews,
          props.RadarData.replays,
          props.RadarData.profileVisits,],
        borderColor: "navy",
        borderWidth: "1",
        backgroundColor: "rgba(161, 160, 247, .6)",
      }
    ],
  };
  
  const options = {
    plugins:{
      legend: false,
    },
    maintainAspectRatio: false,
    scales: {
      r: {
        max: Math.max(...data.datasets[0].data) + (Math.floor(Math.max(...data.datasets[0].data)/4)),
        ticks: {
          beginAtZero: true,
          display: false // Hides the labels in the middel (numbers)
        }
      }
    },
    scale:2
  };
    return (
      <>
        <Radar data={data} options={options} height={150} />
      </>
    );
  }

export default RadarChart;
