import { forwardRef, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import TimeRangePicker from '@wojtekmaj/react-timerange-picker'
import { AddObject, ChangeHandler, ClearData } from "../../Elements/Functions";
import { Urls } from "../../../../services/ServerandUrls";
import "react-datepicker/dist/react-datepicker.css";
import { isValid } from "date-fns";
import { ToastError } from "../../../../services/Toasts";
import Select  from "react-select";



export default function OPAddForm(props)
{
  let CurrentTime = new Date().getHours()+":"+new Date().getMinutes()
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDatee, endDatee] = dateRange;
  const [TimeValue, setTimeValue] = useState([CurrentTime, CurrentTime]);
	const [Loader, setLoader] = useState(false);
  const [OperationData, setOperationData] = useState(
    {
      "name": "",
      "combineBotOrder": false
      ,"isActive": true,
      "startDate":new Date(),
      "endDate":new Date(),
      "deleteAfterHour": 1,
      "isNeedDeleteAfter": false,
      "deleteType": "All"
    });
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
  <button className="rounded-lg bg-white border p-3 DatePicker-Custom" onClick={onClick} ref={ref}>
    {value}
  </button>
      ));
  const SelectRef = useRef(null)

      const CleanData = 
      {
        "name": "",
        "startDate":new Date(),
        "endDate":new Date(),
        "combineBotOrder": false,
        "isActive": false,
        "deleteAfterHour": 1,
        "isNeedDeleteAfter": false,
        "deleteType": "All"
      }
      

  const SendData = () => 
      {
        console.log(OperationData)
        if (OperationData.name.length <= 0) 
        {
          ToastError("Please write a title for the operation",1)
        }
        else
        {
          setLoader(true);
          AddObject(Urls.Operation.add,OperationData,"Operation",setLoader,props.setRefreshData,!props.RefreshData);
        }
      }

      const DataClear = () => {
        setTimeValue(["00:00", "00:00"])
        setDateRange([new Date(), new Date()])
        ClearData(setOperationData,CleanData)
      }

      useEffect(() => {
        const Today = new Date();
        var month = Today.getMonth() + 1; 
        var day = Today.getDate();
        var year = Today.getFullYear();
        var monthEnd = Today.getMonth() + 1;
        var dayEnd = Today.getDate();
        var yearEnd = Today.getFullYear();
        var newdate = new Date(year + "-" + month + "-" + day);
        var EndDate = new Date(yearEnd + "-" + monthEnd + "-" + dayEnd);

        if(startDatee !== null)
        {
        month = startDatee.getMonth() + 1; 
        day = startDatee.getDate();
        year = startDatee.getFullYear();
        newdate = new Date(year + "-" + month + "-" + day);

        if(!isNaN(TimeValue[0].slice(0,2))) 
          {newdate.setHours(TimeValue[0].slice(0,2))}
        else 
          {newdate.setHours(TimeValue[0].slice(0,1))}
        if(!isNaN(TimeValue[0].slice(-2)))
          newdate.setMinutes(TimeValue[0].slice(-2))
        else 
          newdate.setMinutes(TimeValue[0].slice(-1))
        }
        if(endDatee !== null)
        {
          monthEnd = endDatee.getMonth() + 1;
          dayEnd = endDatee.getDate();
          yearEnd = endDatee.getFullYear();
          EndDate = new Date(yearEnd + "-" + monthEnd + "-" + dayEnd);
          if(!isNaN(TimeValue[1].slice(0,2))) 
            {EndDate.setHours(TimeValue[1].slice(0,2))}
          else 
            {EndDate.setHours(TimeValue[1].slice(0,1))}
          if(!isNaN(TimeValue[1].slice(-2)))
            EndDate.setMinutes(TimeValue[1].slice(-2))
          else 
            EndDate.setMinutes(TimeValue[1].slice(-1))
        }

        
        if(isValid(EndDate)&&isValid(newdate))
        {

        
        setOperationData({
          ...OperationData,
          endDate: EndDate.toISOString(),
          startDate:newdate.toISOString()
        });}
      }, [dateRange,TimeValue])
      
      const options = [
        { value: 'All', label: 'All' },
        { value: 'Tweets', label: 'Tweets' },
        { value: 'Comments', label: 'Comments' },
      ]
      useEffect(() => {
        if(!OperationData.isNeedDeleteAfter && SelectRef.current!==null)
           {
              SelectRef.current.clearValue();
              ChangeHandler('deleteAfterHour',"0",setOperationData,OperationData)
           }
     }, [OperationData.isNeedDeleteAfter])

    return(
      <>
        <div className=" mobile-send-button">
          <Button onClick={()=>SendData()}>Send </Button>
        </div>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className='form-group'>
            <Row>
                <h4>Title</h4>
                <input
                type='text'
                className='form-control input-default rounded-lg text-black'
                placeholder="Operation's Title"
                value={OperationData.name}
                onChange={e => ChangeHandler("name",e.target.value,setOperationData,OperationData)}
                />
            </Row>
            <Row className="mt-2 align-items-end pt-2 pb-2"  style={{borderTop:"1px solid #dbdbdb"}}>
                <Col lg={2} className="pb-3">
                  <div className="d-flex flex-row co-lg-6">
                    <div >
                    <h4>Combined?</h4>
                        <div className='form-group mb-0'>
                            <label className='radio-inline mr-3'>
                              <input type='radio' name='combineRadio' onChange={() => ChangeHandler("combineBotOrder",true,setOperationData,OperationData)} /> yes
                            </label>
                            <label className='radio-inline mr-3'>
                              <input defaultChecked type='radio' name='combineRadio' onChange={() => ChangeHandler("combineBotOrder",false,setOperationData,OperationData)} /> no
                            </label>
                        </div>
                    </div>
                  </div>
                </Col> 
                <Col lg={2} className="pb-3">
                  <div className="d-flex flex-row co-lg-6">
                      <div >
                      <h4>Active?</h4>
                          <div className='form-group mb-0'>
                              <label className='radio-inline mr-3'>
                                <input defaultChecked type='radio' name='activeRadio' onChange={() => ChangeHandler("isActive",true,setOperationData,OperationData)} /> yes
                              </label>
                              <label className='radio-inline mr-3'>
                                <input  type='radio' name='activeRadio' onChange={() => ChangeHandler("isActive",false,setOperationData,OperationData)} /> no
                              </label>
                          </div>
                      </div>
                  </div>
                </Col> 
                <Col className="mt-2" xl={3}  >
                  <h4>Calendar:</h4>
                  <ReactDatePicker 
                  selectsRange={true}
                  startDate={startDatee}
                  endDate={endDatee}
                  onChange={(update) => {setDateRange(update);}}
                  customInput={<ExampleCustomInput />}
                  withPortal/>
                </Col>
                <Col className="mt-2" xl={4} >
                  <div>
                    <h4 >Time range:</h4>
                    <TimeRangePicker className="pb-1" clockIcon={null} disableClock={true} clockClassName={" position-relative "} format={"HH:mm"} onChange={setTimeValue} value={TimeValue} />
                  </div>
                </Col>
            </Row>
            <Row className="mt-2 align-items-end pt-3"  style={{borderTop:"1px solid #dbdbdb"}}>
                <Col lg={2}>
                  <div className="d-flex flex-row co-lg-6">
                    <div >
                    <h4>Delete After Operation Done?</h4>
                        <div className='form-group mb-0'>
                            <label className='radio-inline mr-3'>
                              <input type='radio' name='DeleteRadio' onChange={() => ChangeHandler("isNeedDeleteAfter",true,setOperationData,OperationData)} /> yes
                            </label>
                            <label className='radio-inline mr-3'>
                              <input defaultChecked type='radio' name='DeleteRadio' onChange={() => ChangeHandler("isNeedDeleteAfter",false,setOperationData,OperationData)} /> no
                            </label>
                        </div>
                    </div>
                  </div>
                </Col>
                <Col lg={3}>
                <div className="d-flex flex-row co-lg-6 align-items-center">
                      <label className="mr-2 ">Delete After How Many Hours?</label>
                      <div className={"form-control input-default d-flex justify-content-start align-items-center p-0  " + (!OperationData.isNeedDeleteAfter ? "disabled-item":"") }style={{width:"5rem",height:"2.5rem"}}>
                        <input disabled={!OperationData.isNeedDeleteAfter} className=' form-control input-default h-100 border-0 w-75' onChange={(e) => ChangeHandler('deleteAfterHour',e !=null ? e.target.value:"",setOperationData,OperationData)} type={"number"} value={OperationData ? OperationData.deleteAfterHour:""} placeholder="0"></input>
                        <h5 className={" border-0 m-0 text-dark pr-1 pl-1 "}>hrs</h5>
                      </div>
                  </div>
                </Col> 
                <Col lg={3}>
                <div className="d-flex flex-row co-lg-6 align-items-center">
                      <label className="mr-2 ">Action Delete Type?</label>
                      <Select   ref={SelectRef} placeholder={"Select Source"} isDisabled={!OperationData.isNeedDeleteAfter} onChange={(e) => ChangeHandler('deleteType',e !=null ? e.value:null,setOperationData,OperationData)} options={options}> </Select>
                  </div>
                </Col> 
            </Row>
          </div>
          <Row className="flex-row-reverse" style={{gap:"0.6rem"}}>
            <Button onClick={() => SendData()} >Send </Button>
            <Button variant='outline-primary ' onClick={() =>  DataClear()} >Clear </Button>
            {Loader ? <Spinner animation="border" variant="primary" className="mt-2" /> : "" }
          </Row>
        </form>
      </>

    )
}