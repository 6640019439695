import { useEffect, useState } from "react";
import { Card, Col, Nav, Tab } from "react-bootstrap";
import { AxiosGetRequest } from "../../../../services/Connections";
import { Urls } from "../../../../services/ServerandUrls";
import { ToastError } from "../../../../services/Toasts";
import TableElement from "../../FilteringTable/TableElment";
import { DirectCOLUMNS } from "./Columns";
import DirectForm from "./DirectForm";



export default function Directs(props) {
	const [Loader, setLoader] = useState(false);
  const [ List, setList] = useState([]);
	const [RefreshData, setRefreshData] = useState(false);

    const tabData = [
        {
          name: 'Direct massages Form',
          icon: 'plus',
          content:<DirectForm setRefreshData={setRefreshData} />
        },
        {
          name: 'Direct massages List',
          icon: 'list',
          content:
            <TableElement 
            Headers={DirectCOLUMNS} 
            Type={"Directs"}
            hasEdit={List.length> 0}
            Loader={Loader} 
            setLoader={setLoader} 
            hasDelete={true} 
            DataList={List}
            setDataList={setList}
            />
        },

        
      ]

      useEffect(() => {

        setLoader(true);
        AxiosGetRequest(Urls.Directs.view,[{"HeadKey":"pageSize","HeadValue":1500}]).then(response => 
          {
          setLoader(false);
          if(response[0])
          {
            setList(response[1].data)
          }
          else
            ToastError("Directs List List didnt loaded")
          })
      }, [RefreshData])

    return(
        <Col xl={12}>
          <Card>
            <Card.Body>
              <div className='default-tab'>
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                  <Nav as='ul' className='nav-tabs'>
                    {tabData.map((data, i) => (
                      <Nav.Item as='li' key={i}>
                        <Nav.Link eventKey={data.name.toLowerCase()}>
                          <i className={`la la-${data.icon} mr-2`} />
                          {data.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content className='pt-4'>
                    {tabData.map((data, i) => (
                      <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                        {data.content}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Card.Body>
          </Card>
        </Col>
    )
}