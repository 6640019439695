import { useState , useEffect} from "react";
import {  Button, Card, Col, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Select from "react-select";
import {  Loader, RenderTooltip,  } from "../../Elements/Elements";
import { Urls } from "../../../../services/ServerandUrls";
import { AxiosGetRequest, } from "../../../../services/Connections";
import { Errors,Pending , OperationDetailsItems, Success, OperationDetailsHeaders } from "./FilteringTable/Columns";
import SingleTable from "../../FilteringTable/OperationDetailTable/SingleTable";
import Table from 'react-bootstrap/Table';

export default function OperationDetails (props) {
  const [Loading, setLoading] = useState(false)
  const [OperationList, setOperationList] = useState([]);
	const [show, setShow] = useState(false);
	const [ModalData, setModalData] = useState([
    {
      "robotAccountTweetType": "Tweet",
      "impressions": 0,
      "engagements": 0,
      "detailExpands": 0,
      "newFollowers": 0,
      "videoViews": 0,
      "profileVisits": 0,
      "likes": 0,
      "retweets": 0,
      "replays": 0,
      "countTweets": 0
  }
  ]);
  const [tableControler, settableControler] = useState({
    hasData:false,
    initiate:false,
    selectedItem:"",
    tableData:[],
    tableCols:[]
  })
  const [Data, setData] = useState({})

  const [NumbersObject, setNumbersObject] = useState({
    tweets:[0,0,0,0],
    votes:[0,0,0,0],
    comments:[0,0,0,0],
    retweetWithComments:[0,0,0,0],
    supportHashtags:[0,0,0,0],
    supportTweets:[0,0,0,0],
    directMasseges:[0,0,0,0],
    Retweet:[0,0,0,0],
    Like:[0,0,0,0],
    
  })


	const handleClose = () =>{
    setShow(false)
  }
  const handleShow = () =>{
    setShow(true)
  }


   const updateNumbers = (Array,targetKey,customKey) => {
    var tempNumber = [0,0,0,0];
    // console.log(targetKey, Array);
    if(Array.length>0)
    {
      if(targetKey=="supportTweets")
      {
        console.log('customKey :>> ', Array);
        const typeAArray = Array.filter(entry => entry.supportTweetType === customKey);
        tempNumber[0] = typeAArray.length
        for (let i = 0; i < typeAArray.length; i++) 
        {
          if(Success.includes(typeAArray[i].orderState))
            tempNumber[1] = tempNumber[1]+1;
          else if (Errors.includes(typeAArray[i].orderState))
            tempNumber[2] = tempNumber[2]+1;
          else if (Pending.includes(typeAArray[i].orderState))
            tempNumber[3] = tempNumber[3]+1;
        }
        setNumbersObject(prevState => ({
          ...prevState,
          [customKey]: tempNumber,
        }));
      }
      else
      {
        tempNumber[0] = Array.length
        for (let i = 0; i < Array.length; i++) 
        {
          if(Success.includes(Array[i].orderState))
            tempNumber[1] = tempNumber[1]+1;
          else if (Errors.includes(Array[i].orderState))
            tempNumber[2] = tempNumber[2]+1;
          else if (Pending.includes(Array[i].orderState))
            tempNumber[3] = tempNumber[3]+1;
        }
        setNumbersObject(prevState => ({
          ...prevState,
          [targetKey]: tempNumber,
        }));
      }

      }

  };

  const getOpInformation = (opID) => 
   {
    AxiosGetRequest(Urls.OperationDetails.Interactions,[{HeadKey:"OperationId",HeadValue:opID}]).then( response=>{
        setModalData(response[1].data)
    })
    setNumbersObject(
      {
        tweets:[0,0,0,0],
        votes:[0,0,0,0],
        comments:[0,0,0,0],
        retweetWithComments:[0,0,0,0],
        supportHashtags:[0,0,0,0],
        supportTweets:[0,0,0,0],
        directMasseges:[0,0,0,0],
        Retweet:[0,0,0,0],
        Like:[0,0,0,0],
      }
    )
    setLoading(true)
    for (let i = 0; i < OperationDetailsItems.length; i++) {
      AxiosGetRequest(Urls.OperationDetails[OperationDetailsItems[i].accessor],[{HeadKey:"OperationId",HeadValue:opID}]).then(response => 
        {
          updateNumbers(response[1].data,OperationDetailsItems[i].accessor,OperationDetailsItems[i].CustomAccessor);
          setLoading(false)
          setData(prevState => ({
            ...prevState,
            [OperationDetailsItems[i].accessor]: response[1].data,
          }));
          settableControler(prevState => ({
            ...prevState,
            hasData:true,
          }));
        })
    }


  }


  const tooltip = () =>{

      return(
        <>
        <div className="d-flex flex-column pl-3 pr-3 mt-2" >
          <div className="d-flex flex-row justify-evenly rtl" >
            <div className="mt-1 mr-2 " style={{width:"10px",height:"10px",background:"black",borderRadius:"3px"}}></div>
            <p>Total</p>
          </div>
          <div className="d-flex flex-row justify-evenly rtl" >
            <div className="mt-1 mr-2 " style={{width:"10px",height:"10px",background:"#00c343",borderRadius:"3px"}}></div>
            <p>Successful</p>
          </div>
          <div className="d-flex flex-row justify-evenly rtl" >
            <div className="mt-1 mr-2 " style={{width:"10px",height:"10px",background:"red",borderRadius:"3px"}}></div>
            <p>Error</p>
          </div>
          <div className="d-flex flex-row justify-evenly rtl" >
            <div className="mt-1 mr-2 " style={{width:"10px",height:"10px",background:"#e9d106",borderRadius:"3px"}}></div>
            <p>Pending</p>
          </div>
        </div>
        </>
      )
  }

  const getInfo = (accessor) => {
      if(tableControler.hasData)
        settableControler(prevState => ({
          ...prevState,
          selectedItem:accessor,
          initiate: true,
          tableCols:OperationDetailsHeaders[accessor],
          tableData: Data[accessor]
        }));
  }


    
    useEffect(() => {
      var List = []
        for (let i = 0; i < props.OperationList.length; i++) 
        {
          List.push({"value":props.OperationList[i].id , "label":props.OperationList[i].name})
        }
        setOperationList(List);

   }, [props.OperationList])



  return (
          <>
          <Card>
            <Row className="w-100 ml-0 pt-3 mt-4 mb-4"  style={{borderTop:"1px solid #dbdbdb"}} >
            <Col className="mt-2 mb-2 col-lg" > 
              <Select isClearable onChange={(e) => getOpInformation(e.value)} options={OperationList} />
            </Col> 
            <Col sm={1} className="d-flex align-items-center" style={{gap:"1rem"}}>
              <OverlayTrigger placement="top" delay={{ show: 500, hide: 100 }}overlay={RenderTooltip(props,tooltip())}>
              <i className="fa fa-info-square fs-22" style={{ cursor: 'help' }}></i>
              </OverlayTrigger>
                {Loading ? <Loader/> : ""}
            </Col>
            </Row>
            <div className="d-flex flex-column">
              <Row className="d-flex  flex-row  justify-content-around px-4">
                {OperationDetailsItems.map(item => {
                  return(
                    <Card className={tableControler.selectedItem == item.accessor ? " Selected-operation border cursor-pointer":"border cursor-pointer" } 
                    onClick={() => (
                      item.Title === "Interactions"
                        ? handleShow()
                        : getInfo(item.accessor)
                    )}
                     style={{ width: '11rem',height:"10rem",overflow:"hidden" }}>
                      <Card.Body className="d-flex flex-column  align-items-center p-3 pr-1 pl-1">
                        <div className="d-flex flex-column justify-content-center align-items-center w-100">
                          <i className={item.Icon + " mb-2 "} style={{fontSize:"2rem"}}></i>
                          <h4 className="font-weight-bold text-nowrap">
                            {item.Title}
                          </h4> 
                          {item.Title === "Interactions" ? "":
                          <div className="d-flex flex-row mt-2 w-100 " style={{gap:"5px",justifyContent:"space-evenly"}}>
                            <h5 className="font-weight-bold" >{item.CustomAccessor !== undefined ?  NumbersObject[item.CustomAccessor][0]:NumbersObject[item.accessor][0]}</h5>
                            <h5 className="font-weight-bold"style={{color:"#00c343"}}>{item.CustomAccessor !== undefined ?  NumbersObject[item.CustomAccessor][1]:NumbersObject[item.accessor][1]}</h5>
                            <h5 className="font-weight-bold"style={{color:"red"}}>{item.CustomAccessor !== undefined?  NumbersObject[item.CustomAccessor][2]:NumbersObject[item.accessor][2]}</h5>
                            <h5 className="font-weight-bold"style={{color:"#e9d106"}}>{ item.CustomAccessor!== undefined  ?  NumbersObject[item.CustomAccessor][3]:NumbersObject[item.accessor][3]}</h5>
                          </div> 
                          }
                         
                        </div>
                        {typeof(item.Value) == "boolean" ?
                        <>
                        {item.Value ? <i className="fa-solid fa-circle-check " style={{color:"#09ac0d",fontSize:"1.8rem"}}></i>:<i className="fa-solid fa-circle-xmark" style={{color:"rgb(200,0,50)",fontSize:"1.8rem"}}></i>}
                        </> : 
                        <OverlayTrigger  placement="bottom" delay={{ show: 500, hide: 100 }}overlay={<Tooltip id="button-tooltip">{item.Value}</Tooltip>}>
                          <h5 className="text-center w-100" style={{overflow:"hidden"}}>
                            {item.Value}
                          </h5>
                        </OverlayTrigger>}
                      </Card.Body>
                    </Card>
                  )
                })}
              </Row>
            </div>
          </Card>
            {tableControler.initiate ? <SingleTable DataList={tableControler.tableData} Headers={tableControler.tableCols}></SingleTable> : ""}
          
        <Modal 
        dialogClassName="customModal" 
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}>
          <Modal.Header closeButton>
          <Modal.Title><h3>Interactions</h3></Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Table responsive striped bordered hover>
      <thead>
        <tr>
          <th className="text-center" >Type</th>
          <th>Impressions</th>
          <th>Engagements</th>
          <th>Detail Expands</th>
          <th>New Followers</th>
          <th>Video Views</th>
          <th>Profile Visits</th>
          <th>Likes</th>
          <th>Retweets</th>
          <th>Replays</th>
          <th>CountTweets</th>
        </tr>
      </thead>
      <tbody>
        {ModalData.map(item=> {
          return(
            <tr >
            <td className="text-center">{item.robotAccountTweetType}</td>
            <td className="text-center">{item.impressions}</td>
            <td className="text-center">{item.engagements}</td>
            <td className="text-center">{item.detailExpands}</td>
            <td className="text-center">{item.newFollowers}</td>
            <td className="text-center">{item.videoViews}</td>
            <td className="text-center">{item.profileVisits}</td>
            <td className="text-center">{item.likes}</td>
            <td className="text-center">{item.retweets}</td>
            <td className="text-center">{item.replays}</td>
            <td className="text-center">{item.countTweets}</td>
          </tr>
          )
        })}
      </tbody>
    </Table>
          </Modal.Body>
          <Modal.Footer>
          <Button onClick={() => handleClose()} variant="outline-primary">
            cancel
          </Button>
          </Modal.Footer>
	      </Modal>

          </>
    )
}