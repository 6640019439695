
import { ColumnFilter } from '../../FilteringTable/ColumnFilter';

export const COLUMNS = [

	{
		Header : 'Social Subject ',
		Footer : 'Social Subject',
		accessor: 'label',
		Filter: ColumnFilter,
		// minWidth:350
	}
]
