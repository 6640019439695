import React, { useEffect, useState } from 'react'
/// React router dom
import {Switch, Route } from 'react-router-dom'
/// Css
import './index.css'
import './chart.css'
import './step.css'

/// Layout
import Nav from './layouts/nav'
import Footer from './layouts/Footer'
/// Dashboard
import Home from './components/Dashboard/Home'

/// App
import AppProfile from './components/AppsMenu/AppProfile/AppProfile'
import PostDetails from './components/AppsMenu/AppProfile/PostDetails'
import Compose from './components/AppsMenu/Email/Compose/Compose'
import Inbox from './components/AppsMenu/Email/Inbox/Inbox'
import Read from './components/AppsMenu/Email/Read/Read'
import Calendar from './components/AppsMenu/Calendar/Calendar'

/// Product List


/// Plugins



/// Widget
import Widget from './pages/Widget'


/// Form
//import ReduxForm from "./components/Forms/ReduxForm/ReduxForm";
import Operations from './components/Forms/Operations/Operations'
import Tweets from './components/Forms/Tweets/Tweets'
import PollsVotes from './components/Forms/PollsAndVotes/PVContainer'
import RepliesForm from './components/Forms/Replies/Replies'
import CoverContainer from './components/Forms/Covers/CoverContainer'
import Trends from './components/Forms/Trends/TrendsContainer'
import Contents from './components/Forms/Content/ContentContainer'
import Directs from './components/Forms/DirectionMassage/DirectContainer'
import RetweetsWithComment from './components/Forms/RetweetsWithComment/RetweetsWithComment'
import TagListContainer from './components/Forms/TagLists/TagListContainer'



/// Pages
import Registration from './pages/Registration'
import Login from './pages/Login'
import LockScreen from './pages/LockScreen'
import Error400 from './pages/Error400'
import Error403 from './pages/Error403'
import Error404 from './pages/Error404'
import Error500 from './pages/Error500'
import Error503 from './pages/Error503'

//Scroll To Top
import ScrollToTop from './layouts/ScrollToTop';
import AccountsContainer from './components/Acounts/AcountsContainer'
import Wizard from './components/Forms/Wizardd/Wizard'
import ReportContainer from './components/Forms/Reports/ReportContainer'
import Setting from './components/Forms/Setting/SettingContainer'



const Markup = () => {
  let path = window.location.pathname
  path = path.split('/')
  path = path[path.length - 1]
  let pagePath = path.split('-').includes('page')
  const [activeEvent, setActiveEvent] = useState(!path)

  const routes = [
    /// Dashboard
    { url: '', component: Home },
    { url: 'dashboard', component: Home },

    /// Apps
    { url: 'app-profile', component: AppProfile },
    { url: 'post-details', component: PostDetails },
    { url: 'email-compose', component: Compose },
    { url: 'email-inbox', component: Inbox },
    { url: 'email-read', component: Read },
    { url: 'app-calender', component: Calendar },
    { url: 'Wizard', component: Wizard },
   


    /// Widget
    { url: 'widget-basic', component: Widget },



    /// Form
    
   // { url: 'form-redux', component: ReduxForm }, 
    { url: 'Operations', component: Operations },
    { url: 'Tweets', component: Tweets },
    { url: 'PollsAndVotes', component: PollsVotes },
    { url: 'Replies', component: RepliesForm },
    { url: 'Covers', component: CoverContainer },
    { url: 'Trends', component: Trends },
    { url: 'Contents', component: Contents },
    { url: 'Directs', component: Directs },
    { url: 'Retweets', component: RetweetsWithComment },
    { url: 'TagLists', component: TagListContainer },
    { url: 'Reports', component: ReportContainer },
    { url: 'Setting', component: Setting },
    
    /// pages
    { url: 'page-register', component: Registration },
    { url: 'page-lock-screen', component: LockScreen },
    { url: 'page-login', component: Login },
    { url: 'page-error-400', component: Error400 },
    { url: 'page-error-403', component: Error403 },
    { url: 'page-error-404', component: Error404 },
    { url: 'page-error-500', component: Error500 },
    { url: 'page-error-503', component: Error503 },

    //Acounts
    { url: "Accounts" , component:AccountsContainer}
  ]

  useEffect(() => {
    document.title = ("Moein - " + (path !== '' ? path:"Dashboard"));
  }, [path]);


  return (
       <> 
          <div
            id={`${!pagePath ? 'main-wrapper' : ''}`}
            className={`${!pagePath ? 'show menu-toggle' : 'mh100vh'}`}
          >
            {!pagePath && (
              <Nav
                onClick={() => setActiveEvent(!activeEvent)}
                activeEvent={activeEvent}
                onClick2={() => setActiveEvent(false)}
                onClick3={() => setActiveEvent(false)}
              />
            )}
            <div
              className={` ${!path && activeEvent ? 'rightside-event' : ''} ${!pagePath ? 'content-body' : ''}`}
            >
              <div
                className={`${!pagePath ? 'container-fluid' : ''}`}
                
              >
                <Switch>
                  {routes.map((data, i) => (
                    <Route
                      key={i}
                      exact
                      path={`/${data.url}`}
                      component={data.component}
                    />
                  ))}
                </Switch>
              </div>
            </div>
            {!pagePath && <Footer />}
          </div>
         <ScrollToTop />
       </>
  )
}

export default Markup
