import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import noIMG from "../../../../images/avatar/NotFound.png";
import { RenderTooltip } from "../../../components/Elements/Elements";


export default function TopAccounts (props) {

const openAcc= (acc) => {
    window.open(`https://twitter.com/${acc}`, "_blank");
}

const popover = (profile) => {
    return(
    <Popover id={`popover-${profile.id}`} style={{ borderRadius: '10px',borderColor:"lightblue" }}>
      <Popover.Content>
        <div className="d-flex flex-column">
          <div className="mb-2">
            <strong>Impressions:</strong> {profile.impressions}
          </div>
          <div className="mb-2">
            <strong>Engagements:</strong> {profile.engagements}
          </div>
          <div className="mb-2">
            <strong>Profile Visits:</strong> {profile.profileVisits}
          </div>
          <div className="mb-2">
            <strong>Followings:</strong> {profile.followings}
          </div>
          <div className="mb-2">
            <strong>Followers:</strong> {profile.followers}
          </div>
          <div className="mb-2">
            <strong>Created Date:</strong> {new Date(profile.createdDate).toLocaleDateString()}
          </div>
          <div style={{paddingTop:"5px",textAlign:"center",borderTop:"2px solid lightblue",width:"100%"}}>
          <strong><a className="ProfileName" href={`https://twitter.com/${props.profile.userName}`} target="_blank" rel="noreferrer" >View Profile</a></strong>
          </div>
        </div>
      </Popover.Content>
    </Popover>
    )
};


    return(		
            <li key={props.profile.id ? props.profile.id:props.index } className="Profile-item" >
                <OverlayTrigger  
                placement="bottom" 
                delay={{ show: 500, hide: 100 }} 
                show={props.isOpen}
                overlay={popover(props.profile)}
                >
                <img alt="ImgProfiles" 
                onClick={props.onClick} className="rounded-circle profile-circle" src={props.profile.imageUrl ?props.profile.imageUrl:noIMG} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src=noIMG;}}/>
                </OverlayTrigger>
                <OverlayTrigger  placement="bottom" delay={{ show: 500, hide: 100 }} overlay={popover(props.profile)}>
                <div className="ProfileNameContainer">
                    <a className="ProfileName" href={`https://twitter.com/${props.profile.userName}`} target="_blank" rel="noreferrer" >{props.profile.userName}</a>
                </div>
                </OverlayTrigger>

            </li>

)
}
