import { useState , useRef} from "react";
import { Button, Card, Col, ListGroup, Row } from "react-bootstrap";
import { TransitionGroup } from "react-transition-group";
import { ContentColumn, GenderColumn, OperationColumn, SubjectColumn } from "../../Elements/Elements";





export default function TrendsForm () {
  const MaleCheckRef = useRef(null)
  const FemaleCheckRef = useRef(null)
  const [Options, setOptions] = useState(
    {
      operationId:null,
      tagCollectionId:null,
      privateRobotAccountId:null,
      countRepetition:"",
      socialAreas: [],
      isWithTrendMethod:false,
      isNeedtextRepetition:false,
      trendMethodAddType: "Top",
      minCountTrendTweets:"",
      maxCountTrendTweets:"",
      genderType:"FemaleMale",
      TrendSuorce:null
    }
  );




    return(
        <>
        <Card>
            <ListGroup >
                <div className="d-flex flex-row justify-content-between">
                    <h4>Trends Form</h4>
                </div>
                <TransitionGroup>
                    <ListGroup.Item  className="rounded mb-2 mt-2 border " >
                    <div className='input-group mb-3 input-primary '>
                      <div className='input-group-prepend'>
                        <span className='input-group-text'>#</span>
                      </div>
                      <input
                        type='text'
                        className='form-control'
                        placeholder={"Trend Text"}
                      />
                    </div>
                    </ListGroup.Item>
                </TransitionGroup>
            </ListGroup>
                <Row className="w-100 ml-0 pt-4 mt-4 pb-4"  style={{borderTop:"1px solid #dbdbdb"}} >
                  <OperationColumn Options={Options} setOptions={setOptions}/>
                  <SubjectColumn Options={Options} setOptions={setOptions}/>
                  <Col xl={4} className={"d-flex h-100 align-items-center pl-1"}  style={{gap:"0.3rem"}}>


                            <label className="mb-0 d-flex"><i className="fa fa-repeat mr-1 "></i>Repetition:</label>
                            <input className='form-control input-default w-50 ml-2' style={{height:"45px",borderRadius:"5px"}}  placeholder='Amount' type="number"/>
                            <label className="mb-0 d-flex"><i className="fa fa-dial-max mr-1 "></i>Max:</label>
                            <input className='form-control input-default w-50 ml-2' style={{height:"45px",borderRadius:"5px"}}  placeholder='Amount' type="number"/>

                  </Col>
                </Row>
                <Row className="w-100 ml-0 pt-4 pb-4"  style={{borderTop:"1px solid #dbdbdb"}}>
                  <ContentColumn/>
                  <GenderColumn customID={1}    xl={"3"} Options={Options} setOptions={setOptions} MaleRef={MaleCheckRef} FemaleRef={FemaleCheckRef} />
                </Row>
        </Card>
       <Row className="flex-row-reverse" style={{gap:"0.6rem"}}>
          <Button> Send </Button>
          {/* <Button><i className="fa-solid fa-calculator mr-1"></i>Calculate </Button> */}
          <Button variant='outline-primary '>Clear </Button>
       </Row>
       </>
    )
}