import React from 'react';

export const ColumnFilter = ( { column } ) => {
	
	const {filterValue, setFilter} = column
	return(
		<div>
		
			<input className="form-control input-search"
				value={filterValue || ''}  onChange={e => setFilter(e.target.value)} />
		</div>
	)
} 
export const CheckboxFilter = ({ column }) => {
	const { setFilter } = column;
  
	const handleCheckboxChange = (value,kind) => {
		
			if(document.getElementById(column.id+"false").checked && document.getElementById(column.id+"true").checked)
			{
				setFilter()
			}
			else if(!document.getElementById(column.id+"false").checked && document.getElementById(column.id+"true").checked)
			{
				setFilter(true)
			}
			else if(!document.getElementById(column.id+"false").checked && !document.getElementById(column.id+"true").checked)
			{
				setFilter("true/false")
			}
			else if(document.getElementById(column.id+"false").checked && !document.getElementById(column.id+"true").checked)
			{
				setFilter("False")
			}

	};
	
  
	return (
	  <div className='d-flex mt-2' style={{gap:"0.5rem"}}>
		<label>
		  <input
			type="checkbox"
			defaultChecked={true}
			id={column.id+"true"}
			onChange={(e) => handleCheckboxChange()}
			className='mr-1'
		  />
		  True
		</label>
		<label>
		  <input
			type="checkbox"
			id={column.id+"false"}
			className='mr-1'

			defaultChecked={true}
			onChange={(e) => handleCheckboxChange()}
		  />
		  False
		</label>
	  </div>
	);
  };
  
  export const GenderFilter = ({ column }) => {
	const { setFilter } = column;
  
	const handleCheckboxChange = () => {
	  const maleCheckbox = document.getElementById(column.id + "Male");
	  const femaleCheckbox = document.getElementById(column.id + "Female");
		console.log('column :>> ', column);
	  if (maleCheckbox.checked && femaleCheckbox.checked) {
		setFilter(undefined); // No filter
	  } else if (maleCheckbox.checked) {
		setFilter('Male');
	  } else if (femaleCheckbox.checked) {
		setFilter('Female');
	  } else {
		setFilter('true/false'); // No filter
	  }
	};
  
	return (
	  <div className='d-flex mt-2' style={{ gap: '0.5rem' }}>
		<label>
		  <input
			type='checkbox'
			defaultChecked={true}
			id={column.id + 'Male'}
			onChange={handleCheckboxChange}
			className='mr-1'
		  />
		  Male
		</label>
		<label>
		  <input
			type='checkbox'
			id={column.id + 'Female'}
			className='mr-1'
			defaultChecked={true}
			onChange={handleCheckboxChange}
		  />
		  Female
		</label>
	  </div>
	);
  };
  
  