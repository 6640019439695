import { createRef, useState } from 'react';
import swal from 'sweetalert';
import { AxiosDeleteRequest, AxiosPostRequest, AxiosPutRequest } from "../../../services/Connections";
import { ToasSucces, ToastError } from "../../../services/Toasts";



export const DeleteRow = (selectedFlatRows,type,reqURL,refreshfunction,refreshchangepara,loader) => {
	console.log(selectedFlatRows)
	if(selectedFlatRows.length > 0){
		var IDList = []
		for (let i = 0; i < selectedFlatRows.length; i++) {
			IDList.push({id:selectedFlatRows[i].original.id})
		}
		swal((selectedFlatRows.length > 1 ? "Are you sure you want to delete selected rows ?":" Are you sure you want to delete selected row?"), {
			buttons: {
				cancel: "Cancel",
				delete: true,
				},
			},
			).then((value)=>{
			switch (value) 
			{
				case "delete":
                    loader(true)
					console.log(IDList)
					AxiosDeleteRequest(reqURL,IDList).then(response => {
						loader(false)
						if(response[0])
						{
							ToasSucces( type + " has been deleted ");
							refreshfunction(refreshchangepara)

						}
						else
								ToastError(type + " didn't delete")
						})
				break;
				default:
					break;
			}
			
			})
			
	}
	else
		ToastError("Please select at least one row",6)
};

export const UpdateRow = (URL,Data,type,loader,refreshfunction,refreshchangepara,ShowModal) => {
	console.log('SentUpdateData :>> ', Data);
	AxiosPutRequest(URL,Data).then(response => {
		loader(false)
		if(response[0])
		{
		console.log('UpdateResponse :>> ', response);
		ToasSucces(type + " Updated successfully ");
		refreshfunction(refreshchangepara)
		ShowModal(false)
		}
		else
		ToastError(type + " didn't Update")
	})
}

export const AddObject =(URL,Data,type,loader,refreshfunction,refreshchangepara) =>{
	AxiosPostRequest(URL,Data).then(response => {
		loader(false)
			if(response[0])
			{
				console.log('AddResponse :>> ', response);
				ToasSucces(type + " has been added successfully ");
				refreshfunction(refreshchangepara)
			}
			else
				ToastError(type + " didn't add")
			})
}
    
export function hasWhiteSpace(s) {
	return /\s/g.test(s);
}

export const CheckSpace = (text,type,kind) => {
	
	if(hasWhiteSpace(text))
	{
		ToastError(type + " can't contain spaces",1)
		document.querySelector("#"+type+kind).classList.add(...["shadow-warning"])
		return(false)
	}else
	{
		document.querySelector("#"+type+kind).classList.remove(...["shadow-warning"])
		return(true)
	}
	
}

export const CheckEmpty = (text,type,kind) => {
	// console.log(document.querySelector("#"+type+kind))
	if(text == null || text == "")
	{
		ToastError((type + " Can't be empty"),2)
		document.querySelector("#"+type+kind).classList.add(...["shadow-warning"])
		return(false)
	}
	else
	{
		document.querySelector("#"+type+kind).classList.remove(...["shadow-warning"])
		return(true)
	}
}

export const read_clipboard = (type,SetData,Data) => {
	// navigator.clipboard.readText().then(text => {
    //     SetData
    //     ({
    //         ...Data,
    //         [type]: text
    //     });
    //   })
	SetData
        ({
            ...Data,
            [type]: ""
        });
}

export const ChangeHandler = (type,value,SetData,Data) => {
	SetData({
        ...Data,
        [type]: value 
      });
}

export const ClearData = (SetData,Data) => {
	SetData(Data);
}

export const Getmultiselect = (e,setter,Data,key) => {
    var list = []
    for (let i = 0; i < e.length; i++) {
        list[i] = e[i].value
    }
    setter
    ({
        ...Data,
        [key]: list
    });
}

export const ChangeInList = (e,Data,Setter,index,Limit) =>  {
	if(e.target.value.length < Limit){
	const List = [...Data];
	List[index].Text = e.target.value;
	Setter(List);
  }
  else
  return
}

export const GenderCheckbox = (Key,FemaleBox,MaleBox,DataSetter,Data) => {

		ChangeHandler(Key,null,DataSetter,Data)
		var Female = FemaleBox.current.checked;
		var Male =  MaleBox.current.checked;
		if(Female)
		ChangeHandler(Key,"Female",DataSetter,Data)
		if(Male)
		ChangeHandler(Key,"Male",DataSetter,Data)
		if (Female && Male)
		ChangeHandler(Key,"FemaleMale",DataSetter,Data)
	  
}

export const AddTweet = (Tweets,SetList) =>{
	const newTweet = 
	{ 
	Text:"",
	Files:[],
	TempMedia:[],
	id:Tweets.length,
	nodeRef: createRef(null),
	}
	SetList(Tweets => [...Tweets, newTweet]);
}

export const AddTweetKey = (e,List,SetList) => {
	if (e.keyCode == 81 && e.ctrlKey) 
		AddTweet(List,SetList);

}

export const handleKeyDown = (event,KeyType,Callback) => {
	if (event.key === KeyType) 
	{
	Callback()
	}
};

export const ReadTableState = (State) => {

	const Gold = ["Created","Exported","Login"]
	const Red = ["InternalError","AccuntLoginProblem","AccuntGetLimit","AccuntGetProblem","UnSuccessed", "ProblemInOtherOrder","OrderFristElementError","TwitterPageLoadError",]
	const Green = ["Success"]

	if(Gold.includes(State))
	{
		return (
			<h6 style={{color:"#e9d106"}}>
			{State}
			</h6>
		)
	}
	else if (Red.includes(State))
	{
		return (
			<h6 style={{color:"red"}}>
			{State}
			</h6>
		)
	}
	else if (Green.includes(State))
	{
		return (
			<h6 style={{color:"#00c343"}}>
			{State}
			</h6>
		)
	}
	else
	{
		return (
			<h6>
			{State}
			</h6>
		)
	}


	

}

export const WriteToolTipTable = (text) => {
	const [isWrapped, setIsWrapped] = useState(true);
  
	const handleClick = () => {
	  setIsWrapped(!isWrapped);
	};
  
	return (
		<h6 className='tableCellClass' style={{ whiteSpace: isWrapped ? "nowrap" : "normal" }} onClick={()=>handleClick()}>{text}</h6>
	);
};

export function logout(history) {
    localStorage.removeItem('userDetails');
	window.location.reload()

}

export const saveToLocalStorage = (key, value) => {
	try {
	  const serializedValue = JSON.stringify(value); 
	  localStorage.setItem(key, serializedValue);    
	} catch (error) {
	  console.error("Error saving to localStorage", error);
	}
  };
export const getFromLocalStorage = (key) => {
	try {
	  const serializedValue = localStorage.getItem(key);
	  return serializedValue ? JSON.parse(serializedValue) : null; 
	} catch (error) {
	  console.error("Error getting from localStorage", error);
	  return null;
	}
  };
  
export function HeaderIretate (HeaderList,HeaderOBJ)
{
	for (let i = 0; i < HeaderList.length; i++) 
	{
		HeaderOBJ[HeaderList[i].HeadKey] = HeaderList[i].HeadValue
	}
	return HeaderOBJ;

}

export const createAndDownloadTXTFile = (dataList,requierdList,filename) => {
	const textContent = dataList.flatMap(cell => cell.values.link.length > 0 ? (requierdList.map((item,index) =>cell.values[item] + (index === requierdList.length-1 ?  '\n': " - "))):"").join('');
	const file = new Blob([textContent], { type: 'text/plain' });

	const element = document.createElement('a');
	element.href = URL.createObjectURL(file);
	element.download = filename;
	document.body.appendChild(element);
	element.click();
	document.body.removeChild(element);
};

export const ImageConvertion = (imgArray) => {

	if (imgArray && imgArray.length > 0) {
		// Convert Base64 to Blob
		const byteCharacters = atob(imgArray);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
		  byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		const blob = new Blob([byteArray], { type: 'image/png' }); // Adjust the type if needed
  
		// Create Blob URL
		const url = URL.createObjectURL(blob);
		console.log('url :>> ', url);
		return (url)
	  }

}

export function handleKeyPress(event, key, callback) {
	if (event.key === key) {
	  callback(event);
	}
  }
  
export const findOptionByValue = (value,array) => {
	return array.find(option => option.value === value) || null;
};

export function isValidUrl(string) {
	try {
	  new URL(string);
	  return true;
	} catch (_) {
	  return false;
	}
  }
  
  export const TrendVals = (setTrendVal,setOptions,Options,SelectRef,e) => {
	setTrendVal(e)
	ChangeHandler('isWithTrendMethod',e,setOptions,Options)
	if(e == false)
	{
	  SelectRef.current.clearValue()
	  document.getElementById("hashtaqg").checked = false;
	  setOptions({
		...Options,
		trendMethodAddType: "Top" ,
		CountSkipeHashtag:0,
		CountTrandHastagAdd:1,
		IsNeedUseJustFristHashtag:false,
		TrendSuorce:null
	  });
	}
}