import { useEffect, useState } from "react";
import { Card, Col, Nav, Tab } from "react-bootstrap";
import { AxiosGetRequest } from "../../../../services/Connections";
import { Urls } from "../../../../services/ServerandUrls";
import { ToastError } from "../../../../services/Toasts";
import { InsideLists } from "./InsideTagLists";
import { TagListsList } from "./TagsListsList";




export default function TagListContainer(props) {
  const [TagLists, setTagLists] = useState([]);
	const [RefreshData, setRefreshData] = useState(false);
	const [Loader, setLoader] = useState(false);
    const tabData = [
        {
          name: 'Tag Lists',
          icon: 'list',
          content:<TagListsList TagLists={TagLists} Loader={Loader} RefreshData={RefreshData} setRefreshData={setRefreshData} />
        }, {
          
          name: 'Tags',
          icon: 'list',
          content:<InsideLists TagLists={TagLists} Loader={Loader} RefreshData={RefreshData} setRefreshData={setRefreshData} />
        },

        
      ]

      useEffect(() => {
        setLoader(true)
        AxiosGetRequest(Urls.TagLists.view,[{"HeadKey":"pageSize","HeadValue":500}]).then(response => 
          {
            setLoader(false)
          if(response[0])
          {
            setTagLists(response[1].data)
          }
          else
            ToastError("Tag Lists didnt loaded")
          })
      }, [RefreshData])

      
    return(
        <Col xl={12}>
          <Card>
            <Card.Body>
              {/* <!-- Nav tabs --> */}
              <div className='default-tab'>
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                  <Nav as='ul' className='nav-tabs'>
                    {tabData.map((data, i) => (
                      <Nav.Item as='li' key={i}>
                        <Nav.Link eventKey={data.name.toLowerCase()}>
                          <i className={`fa-light fa-${data.icon} mr-2`} />
                          {data.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content className='pt-0'>
                    {tabData.map((data, i) => (
                      <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                        {data.content}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Card.Body>
          </Card>
        </Col>
    )
}