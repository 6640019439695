import React, { createRef,  } from "react";
import { ListGroup, OverlayTrigger } from "react-bootstrap";
import { ToastError } from "../../../../services/Toasts";
import { RenderTooltip, TweetTextbox,  } from "../../Elements/Elements";
import { AddTweet } from "../../Elements/Functions";

const WizardTweetContainer = (props) => {

  const RemoveTweet = (index,item) =>
  {       
      if(props.TweetList.length>1){
      const ActionItemsList = [...props.TweetList];
      ActionItemsList.splice(index, 1);
      props.setTweetList(ActionItemsList);
      }
      else
      ToastError("At least One Tweet is needed",10)
  }

  const ChangeTweet = (e,index) =>
  {
        if(e.target.value.length < 281)
        {
          const List = [...props.TweetList];
          List[index].Text = e.target.value;
          props.setTweetList(List);
        }
      else
      return
  }

  const ReadFile = (event) => {
    if(event.target.files[0].type=="text/plain")
    {
        var input = event.target;
        var reader = new FileReader();
        var List = [];
        reader.onload = function() {      
            var Users = reader.result.split('$')
            
            Users.map((User,Index) => 
                {
                  if(User.slice(0,2)=="\r\n")
                  {
                    User = User.replace(/\r?\n|\r/, '');
                  }
                  var Tweet = 
                  {
                    Text:User,
                    Files:[],
                    TempMedia:[],
                    id:Index,
                    nodeRef: createRef(null),
                  }
                    List.push(Tweet)
                })
                props.setTweetList(List)
        };
        reader.readAsText(input.files[0]);   
    }
    else
    ToastError("Wrong File Type")
}



  return (
    <>
      <div className="d-flex justify-content-end" style={{gap:"0.5rem"}}>
        <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 100 }}overlay={RenderTooltip(props,"Template Bulk List")}>
            <div onChange={(e)=>ReadFile(e)} className="p-1 rounded-circle d-flex justify-content-center align-items-center c-pointer" style={{width:"25px",height:"25px",background:"rgb(78 78 78)"}}>
                <a href="./TextTemplate.txt"  download>
                <i className="fa-regular fa-file text-white  Temp-Icon" ></i>
                </a>
            </div>
          </OverlayTrigger>
          <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 100 }}overlay={RenderTooltip(props,<p className="p-0 m-0">Add by List <br/> (You can use template list beside this button)</p>)}>
            <div  className="p-1 rounded-circle d-flex justify-content-center align-items-center c-pointer" style={{width:"25px",height:"25px",background:"#026e31"}}>
                <i className="fa-solid fa-file-plus text-white TweetFileCont" >
                <input onChange={(e)=>ReadFile(e)} id='input-file' className="TweetFile" type='file' />
                </i>
            </div>
          </OverlayTrigger>
          <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 100 }}overlay={RenderTooltip(props,<p className="p-0 m-0">Add new Tweet <br/> (after selecting a textbox, you can add Tweets by pressing CTRL + Q)</p>)}>
            <div onClick={()=>AddTweet(props.TweetList,props.setTweetList)} className="p-1 rounded-circle d-flex justify-content-center align-items-center c-pointer" style={{width:"25px",height:"25px",background:"#026e31"}}>
                <i className="fa-solid fa-plus text-white" ></i>
            </div>
          </OverlayTrigger>
      </div>
      <ListGroup className="WizardTweetList" style={{paddingBottom:"3.5rem"}}>
        {props.TweetList.map((Tweet,index) =>(
          <ListGroup.Item style={{background:"#b5e0f117"}} className="rounded mb-2 mt-2 border border-primary position-relative"  ref={Tweet.nodeRef}>
            <span className="position-absolute" style={{bottom:"5px",right:"25px"}}>{index +1}</span>
            <TweetTextbox   TweetList={props.TweetList} setTweetList={props.setTweetList} Tweet={Tweet} Index={index} ChangeTweet={ChangeTweet} RemoveTweet={RemoveTweet}  />
          </ListGroup.Item>
        ))}
      </ListGroup>
    </>
  );
};

export default WizardTweetContainer;
