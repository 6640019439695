let serverIP = "http://213.232.203.227:8081/api/"; // Default value

// export const Server = {
    
//   get ApiServer() {
//     return serverIP;
//   },
//   set ApiServer(value) {
//     serverIP = value;
//   }
// };
// export const Server = () => {
//     try {
//       const response =  fetch(`${process.env.PUBLIC_URL}/ServerApi.json`);
//       const data =  response.json();
//       let ApiServer = "";
//       if (data) {
//         ApiServer = data.Serverapi;
//       }
//       else
//       {
//         alert("error")
//       }
//     } catch (error) {
//       console.error('Error fetching the server IP:', error);
//     }
//   };

export const initializeServer = async () => {
    try {
        console.log("read")
      const response = await fetch(`${process.env.PUBLIC_URL}/ServerApi.json`);
      const data = await response.json();
      if (data) {
        Server.ApiServer = data.Serverapi;
      }
      else
      {
        alert("error")
      }
    } catch (error) {
      console.error('Error fetching the server IP:', error);
    }
  };

  export const Server = {
    
    get ApiServer() {
      return serverIP;
    },
    set ApiServer(value) {
      serverIP = value;
    }
  };


export const Urls = 
{
    Values:"values",
    Login:"Tokens/GetToken",
    TopAccounts:"Accounts/GetTopAccounts",
    TopTweets:"Tweets/GetTopTweets",
    SocialAreas:
    {
        view :"SocialAreas/ViewSocialAreas",
        add :"SocialAreas/AddSocialArea",
        delete:"SocialAreas/DeleteSocialAreas",
        update:"SocialAreas/UpdateSocialArea",
        accountSubjects:"SocialAreas/ViewRobotAccountSocialAreas",
        accountSubjectsUpdate:"SocialAreas/UpdateRobotAccountSocialArea"
    },
    Inlfuencers:
    {
        view :"Influencers/ViewInfluencers",
        add :"Influencers/AddInfluencer",
        delete:"Influencers/DeleteInfluencers",
        update:"Inlfuencers/UpdateSocialArea"
    },
    Hashtags:
    {
        view :"Hashtags/ViewHashtags",
        add :"Hashtags/AddHashtag",
        delete:"Hashtags/DeleteHashtags",
        update:"Hashtags/UpdateSocialArea"
    },
    Reports:
    {
        Tweets:"Reports/TweetOrders",
        Likes:"Reports/LikeOrders",
        Follows:"Reports/FollowOrders",
        Unfollows:"Reports/UnFollowOrders",
        Retweets:"Reports/RetweetOrders",
        AutoFollow:"Reports/AutoFollowOrders",
        AutoTweets:"Reports/AutoFollowOrders",
        Comment:"Reports/CommentOrders",
        DirectMassege:"Reports/DirectMassegeOrders",
        Polls:"Reports/PullOrders",
        ImageTweet:"Reports/ImageTweetOrders",
        RetweetsWithComment:"Reports/RetweetWithCommentOrders",
        SupportHashtag:"Reports/SupportHashtagOrders",
        Timeline:"Reports/TimeLineOrders",
        VideoTweets:"Reports/VideoTweetOrders",
        Votes:"Reports/VoiteOrders",
        Influencer:"Reports/FollowFromInfluencers",
        All:"Reports/GetAllOrders",
        AccountActivitys:"Reports/GetAllRobotAccountActivitys",
        RobotAccountActivitys:{
            month:"Reports/RobotAccountActivitys?Days=30",
            week:"Reports/RobotAccountActivitys?Days=7"
        }
        
    },
    ReportOrder:
    {
     hashtags:"ReportHashtags/AddReportHashtags",
     tweets:"ReportTweets/AddReportTweets",
     acounts:"ReportTwitterAccounts/AddReportTwitterAccounts",
     views:{
        hashtags:"ReportHashtags/ViewReportHashtagOrders",
        tweets:"ReportTweets/ViewReportTweets",
        acounts:"ReportTwitterAccounts/ViewReportTwitterAccounts",
     }

    },
    Acounts:
    {
        view:"Accounts/ViewAllAccounts",
        add:"Accounts/AddAccounts",
        update:"Accounts/UpdateAccounts",
        delete:"Accounts/DeleteAccounts",
        accsBySub:"Accounts/ViewAllAccountsBySubject",
        AvailAccounts:"Accounts/GetCountAvailableAccounts",
        AvailAccountsBySubject:"Accounts/GetCountAvailableAccountsByOrderSettings",
        Logs:"Accounts/ViewRobotAccountLogs"
    },
    Operation:
    {
        view:"Operations/ViewOperations",
        add:"Operations/AddOperation",
        update:"Operations/UpdateOperation",
        delete:"Operations/DeleteOperations",
        Active:"Operations/ViewActiveOperations",
        report:"Operations/ViewRobotAccountTweetsByOperationId"
    },
    Tweet:
    {
        TopTweets:"Tweets/GetTopTweets",
        view:"Tweets/ViewTweets",
        add:"Tweets/AddTweets",
        delete:"Tweets/DeleteTweets",
        update:"Tweets/UpdateTweets",   
        trendMethods:"Tweets/GetTrendMethodAddTypeType"
    },
    Upload:"Uploads/Upload",
    Polls:
    {
        view:"Pulls/ViewPulls",
        add:"Pulls/AddPulls",
        update:"Pulls/UpdatePulls",
        delete:"Pulls/DeletePulls"
    },
    Votes:
    {
        view:"Voites/ViewVoites",
        add:"Voites/AddVoites",
        update:"Voites/UpdatePulls",
        delete:"Voites/DeleteVoites"
    },
    Replies:
    {
        view:"Comments/ViewComments",
        add:"Comments/AddComments",
        update:"Comments/UpdateComments",
        delete:"Comments/DeleteComments"
    },
    Retweets:
    {
        view:"RetweetWithComments/ViewRetweetWithComments",
        add:"RetweetWithComments/AddRetweetWithComments",
        update:"RetweetWithComments/UpdateRetweetWithComments",
        delete:"RetweetWithComments/DeleteRetweetWithComments"
    },
    HashtagCovers:
    {
        view:"SupportHashtags/ViewSupportHashtagOrders",
        add:"SupportHashtags/AddSupportHashtags",
        delete:"SupportHashtags/DeleteSupportHashtagOrders",
    },
    Cover:{
        view:"SupportTweets/ViewSupportTweets",
        add:"SupportTweets/AddSupportTweets",
        delete:"SupportTweets/DeleteSupportTweets",
    },
    Performance:
    {
    AllInOne:"Performances/GetAllInOnePerformance",
    AllData:"Performances/GetAllYearsPerformance",
    Month:"Performances/GetThisMonthPerformance",
    ThreeMonth:"Performances/Get90DayPerformance",
    SixMonth:"Performances/Get180DayPerformance",
    LastYear:"Performances/GetLastYearPerformance",
    PastMonths:"Performances/GetMonthsOfThisYearPerformance"
    },
    Directs : {
        view:"DirectMasseges/ViewDirectMasseges",
        update:"DirectMasseges/UpdateDirectMasseges",
        add:"DirectMasseges/AddDirectMasseges",
        delete:"DirectMasseges/DeleteDirectMasseges", 
    },
    Contents:{
        view:"",
        update:"",
        add:"",
        delete:"", 
    },
    TagLists:
    {
        view:"CollectionAdjustments/ViewCollectionAdjustments",
        update:"CollectionAdjustments/UpdateCollectionAdjustment",
        add:"CollectionAdjustments/AddCollectionAdjustment",
        delete:"CollectionAdjustments/DeleteCollectionAdjustments", 
        types:"CollectionAdjustments/GetCollectionType",
        getByType:"CollectionAdjustments/byTypeViewCollectionAdjustments"
    },
    UernameTags:
    {
        view:"Collections/ViewCollections",
        update:"Collections/UpdateCollection",
        add:"Collections/AddCollections",
        delete:"Collections/DeleteCollections", 
    },
    OperationDetails:
    {
        tweets:"Tweets/ViewTweetsByOperation",
        comments:"Comments/ViewCommentsByOperationId",
        directMasseges:"DirectMasseges/ViewDirectMassegesByOperationId",
        retweetWithComments:"RetweetWithComments/ViewRetweetWithCommentsByOperationId",
        supportHashtags:"SupportHashtags/ViewSupportHashtagByOperationIdOrders",
        supportTweets:"SupportTweets/ViewSupportTweetsByOperationId",
        votes:"Voites/ViewVoitesByOperationId",
        Interactions:"Interactions/GetOperationInteractions"
    },
    Settings:
    {
        getAppLanguge:"Settings/GetAppLanguge",
        getTrend24Link:"Settings/GetTrand24Link",
        getGetDayTrendsLink:"Settings/GetGetDayTrendsLink",
        setLang:"Settings/SetAppLanguge",
        setTrendLink:"Settings/SetTrand24Link",
        setGetDayTrendsLink:"Settings/setGetDayTrendsLink"
    }
}