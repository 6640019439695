import { useState ,createRef, useEffect, useRef} from "react";
import { Button, Card, Col, ListGroup, OverlayTrigger, Row,  } from "react-bootstrap";
import { ToasSucces, ToastError } from "../../../../services/Toasts";
import { TransitionGroup,CSSTransition } from "react-transition-group";
import Select from "react-select";
import { AccountColumn, GenderColumn, OperationColumn, RenderTooltip, SubjectColumn } from "../../Elements/Elements";
import { AddTweet, ChangeHandler } from "../../Elements/Functions";
import { Urls } from "../../../../services/ServerandUrls";
import { AxiosGetRequest, AxiosPostRequest } from "../../../../services/Connections";
 

export default function ContentForm (props) {
  const MaleCheckRef = useRef(null)
  const FemaleCheckRef = useRef(null)
  const [Loader, setLoader] = useState(false)
  const SelectRef = useRef(null)
  const [TagList, setTagList] = useState([])
  const [Options, setOptions] = useState(
    {
      operationId:null,
      collectionAdjustmentId:null,
      targetUserName:"",
      privateRobotAccountId:"",
      receivedDirectMassegeType:"ListAccounts",
      socialAreas: [],
      CountRepetitions:0,
      genderType:"FemaleMale"
    }
    );

    const [DirectList, setDirectList] = useState([
        {
            Text:"",
            id:0,
            nodeRef: createRef(null),
        },

    ]);
    
  

    const RemoveTweet = (index) =>
    {       

        if(DirectList.length>1){
        const ActionItemsList = [...DirectList];
        ActionItemsList.splice(index, 1);
        setDirectList(ActionItemsList);
        }
        else
        ToastError("At least One Massage is needed",10)
    }

    const ChangeTweet = (e,index) =>
    {
         if(e.target.value.length < 281){
          const List = [...DirectList];
          List[index].Text = e.target.value;
          setDirectList(List);
        }
        else
        return

        
    }

    const ChangeDirectType = (Value) => {
      if (Value === "ListAccounts") 
      {

      } else if(Value === "OneAccount") {

        SelectRef.current.clearValue();
      }
      setOptions(prevState => ({
        ...prevState,
        receivedDirectMassegeType: Value,
        targetUserName:"",
        collectionAdjustmentId:null
      }));
    }


    const SendDirect = () => 
    {
  
      var DirectsList = []
      for (let i = 0; i < DirectList.length; i++) 
        {
          var Direct = {}
          Direct.text = DirectList[i].Text;
  
          const TempOptions = { ...Options };

          Object.assign(Direct, TempOptions);
  
          DirectsList.push(Direct)
        }
        setLoader(true);
        AxiosPostRequest(Urls.Directs.add,DirectsList).then(response=>{
          setLoader(false);
            if(response[0])
            {
              ToasSucces("Direct(s) has been added successfully")
              props.setRefreshData(!props.RefreshData)
  
            }
            else
              ToastError("Direct(s) didn't add")
        })
    }

      useEffect(() => {
          AxiosGetRequest(Urls.TagLists.getByType,[{"HeadKey":"collectionType","HeadValue":"DirectMasseges"}]).then(response => 
            {
            if(response[0])
            {
            
              var TempList = []
                for (let i = 0; i < response[1].data.length; i++) 
                {
                  TempList.push({"value":response[1].data[i].id , "label":response[1].data[i].name})
                }
              
                setTagList(TempList)
            }
            })
              
      }, [])
    return (
        <>
        <div className=" mobile-send-button">
          <Button onClick={()=>SendDirect()}>Send </Button>
        </div>
        <Card>
          <ListGroup >
              <div className="d-flex flex-row justify-content-between">
                  <h4>Direct Massage</h4>
                  <div className="d-flex" style={{gap:"0.5rem"}}>
                      <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 100 }}overlay={RenderTooltip(props,"Add new Tweet")}>
                          <div onClick={()=>AddTweet(DirectList,setDirectList)} className="p-1 rounded-circle d-flex justify-content-center align-items-center c-pointer" style={{width:"25px",height:"25px",background:"#026e31"}}>
                              <i className="fa-solid fa-plus text-white" ></i>
                          </div>
                      </OverlayTrigger>
                  </div>
              </div>
              <TransitionGroup>
              {DirectList.map((Tweet,index) =>(
                  <CSSTransition key={index} nodeRef={Tweet.nodeRef} timeout={500} classNames="item">
                  <ListGroup.Item style={{background:"#b5e0f117"}} className="rounded mb-2 mt-2 border border-primary"  ref={Tweet.nodeRef}>
                  <div className="  d-flex flex-column align-items-center">
                      <div className=" w-100 d-flex flex-row align-items-center">
                          <textarea  dir="auto" value={Tweet.Text} onChange={(e) => ChangeTweet(e,index)} className="form-control input-default rounded-lg text-black " placeholder="Direct Text"  rows="2"></textarea>
                          <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 100 }}overlay={RenderTooltip(props,"Remove this Tweet")}>
                              <div onClick={()=>RemoveTweet(index)} className="p-1 ml-3 rounded-circle d-flex justify-content-center align-items-center c-pointer" style={{width:"20px",height:"20px",background:"#9c0606"}}>
                                  <i className="fa-light fa-xmark text-white"></i>
                              </div>
                          </OverlayTrigger>
                      </div>
                      <div className="w-100 d-flex flex-row">
                      </div>
                  </div>
                  </ListGroup.Item>
                  </CSSTransition> 
                  ))}
              </TransitionGroup>
          </ListGroup>
          <Row className="w-100 ml-0 pt-3 mt-4"  style={{borderTop:"1px solid #dbdbdb"}} >
            <OperationColumn  Options={Options} setOptions={setOptions}/>
            <SubjectColumn  Options={Options} setOptions={setOptions}/>
          </Row>
          <Row className="w-100 ml-0 pt-3 mt-4"  style={{borderTop:"1px solid #dbdbdb"}}>
              <Col className="d-flex align-items-center"  xl={1.5}>
                <div className=' form-check'>
                  <input defaultChecked class="form-check-input"  onChange={()=>ChangeDirectType("ListAccounts")}  type="radio" style={{marginTop:"0.5rem",marginLeft:"-0.75rem"}} name="flexRadioDefault" id="radioType1"/>
                  <label class="form-check-label" for="radioType1" >List Target:</label>
                </div>
              </Col>
              <Col xl={4}>
                <div className='form-group'>
                  <label  className="d-none d-lg-none d-xl-block  d-md-none d-sm-none   pt-3"> </label>
                  <Select isDisabled={Options.receivedDirectMassegeType==="OneAccount"} ref={SelectRef}  onChange={(e) => ChangeHandler('collectionAdjustmentId',e !=null ? e.value:null,setOptions,Options)} options={TagList}/>
                </div>
              </Col>
            <Col className="d-flex align-items-center"  xl={1.2}>
              <div className=' form-check'>
                <input class="form-check-input" onChange={()=>ChangeDirectType("OneAccount")} style={{marginTop:"0.5rem",marginLeft:"-0.75rem"}} type="radio" name="flexRadioDefault" id="radopType2"/>
                <label class="form-check-label" for="radopType2">Target Account:</label>
              </div>
            </Col>
            <Col xl={4}>
              <div className='form-group'>
                <label className="d-none d-lg-none d-xl-block  d-md-none d-sm-none   pt-3"> </label>
                <input disabled={Options.receivedDirectMassegeType==="ListAccounts"} type='text'  className='form-control input-default   p-2 h-75' value={Options.targetUserName} onChange={(e) => ChangeHandler('targetUserName',e!=null ? e.target.value:null,setOptions,Options)}   placeholder='Single Account Target' ></input>
              </div>
            </Col>
          </Row>
          <Row className="w-100 ml-0 pt-3 pb-4 mt-4"  style={{borderTop:"1px solid #dbdbdb"}}>
            <AccountColumn Options={Options} setOptions={setOptions}/>
            <GenderColumn customID={1} xl={"none"}  Options={Options} setOptions={setOptions} MaleRef={MaleCheckRef} FemaleRef={FemaleCheckRef}/>
          </Row>
        </Card>
       <Row className="flex-row-reverse" style={{gap:"0.6rem"}}>
          <Button onClick={()=>SendDirect()}>Send </Button>
          <Button onClick={()=>console.log(Options)} variant='outline-primary '>Clear </Button>
       </Row>
        </>
    )
}