import React,{ useEffect, useMemo,useRef } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination, useRowSelect, useFlexLayout, useBlockLayout,} from 'react-table';
import { UsernamesCOLUMNS } from './Columns';
import { Button, Col,  Modal,  Row, Spinner,  } from 'react-bootstrap';
import { useState } from 'react';
import { ToasSucces, ToastError } from '../../../../services/Toasts';
import { AxiosDeleteRequest, AxiosGetRequest, AxiosPostRequest, AxiosPutRequest } from '../../../../services/Connections';
import { Urls } from '../../../../services/ServerandUrls';
import {CheckboxSelection1 as CheckboxSelection} from "../../Elements/Elements"
import swal from 'sweetalert';
import Select  from "react-select";
import { ChangeHandler } from '../../Elements/Functions';




export const InsideLists = (props) => {
	const [show, setShow] = useState(false);
	const [Loader, setLoader] = useState(false);
	const [type, settype] = useState(0);
	const [selectedIndex, setselectedindex] = useState(null);
	const [selectedList, setselectedList] = useState(null);
	const [Types, setTypeLists] = useState([]);
	const [UserNamesList, setUserNamesList] = useState([{userName:"",collectionAdjustmentId:""}]);
	const [RowData, setRowData] = useState({userName:"",collectionAdjustmentId:""});
	const [TagListinside, setTagListinside] = useState([]);
	const columns = useMemo( () => UsernamesCOLUMNS, [] )
	const data = useMemo( () => TagListinside, [TagListinside] )


	const tableInstance = useTable({
		columns,
		data,	
		initialState : {pageIndex : 0,pageSize:25},  
	}, useFilters, useGlobalFilter, usePagination,useRowSelect,
	(hooks)=>{
		hooks.visibleColumns.push((columns)=>{
			return [
				{
					id:"selection",
					
					Header:({getToggleAllRowsSelectedProps}) => (
						<CheckboxSelection {...getToggleAllRowsSelectedProps()}/>
					),
					Cell:({row}) => (
						<CheckboxSelection {...row.getToggleRowSelectedProps()}/>
					),
					width:50
				},
				...columns
			]
		})
	});
	
	const { 
		getTableProps, 
		getTableBodyProps, 
		headerGroups, 
		prepareRow,
		state,
		setPageSize,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		selectedFlatRows
	} = tableInstance
	

	const handleShow = (type,Data,index) => {
		if(selectedList != null)
		{
			setShow(true);
			if(type == 1 )
			{
	
				setUserNamesList([{userName:"",collectionAdjustmentId:selectedList}]);
				settype(0);
			}
			else if(type == 2)
			{
			setRowData(Data);
			setselectedindex(index)
			settype(1)
			}
	
	
		}
		else
		ToastError("Please select a Tag List first")
	};
	
	const handleClose = () => {
		setUserNamesList([{userName:"",collectionAdjustmentId:""}])
		setShow(false)};


	const DeleteRow = (Value) => {
	if(selectedFlatRows.length > 0){
		var IDList = []
		let List = [];

		for (let i = 0; i < selectedFlatRows.length; i++) {
			IDList.push({id:selectedFlatRows[i].original.id})
		}
		swal((selectedFlatRows.length > 1 ? "Are you sure you want to delete selected rows ?":" Are you sure you want to delete selected row?"), {
			buttons: {
				cancel: "Cancel",
				delete: true,
				},
			},
			).then((value)=>{
			switch (value) 
			{
				case "delete":
					AxiosDeleteRequest(Urls.UernameTags.delete,IDList).then(response => {
						if(response[0])
						{
							ToasSucces("Tag List has been deleted ");
							for (let i = 0; i < selectedFlatRows.length; i++) 
							{
								List.push(selectedFlatRows[i].original)
								// setTagListinside(TagListinside.filter((item,index) => index !== selectedFlatRows[i].index))
								setTagListinside(TagListinside.filter(e => !List.includes(e))
								)
							}
						}
						else
								ToastError("Tag List didn't deleted")
						})
				break;
				default:
					break;
			}
			
			})
			
	}
	else
		ToastError("Please select at least one row",6)
	};


	const AddNew = () =>
	{
			setLoader(true)
			if(type === 0)
			{
				console.log(UserNamesList)
			AxiosPostRequest(Urls.UernameTags.add,UserNamesList).then(response => {
				setLoader(false)
				if(response[0])
				{
					ToasSucces("Tag List has been added successfully ");
					changeTableData(selectedList)
					setShow(false);
				}
				else
						ToastError("Tag List  didn't added")
				})
			}
			else if(type === 1)
			{
				AxiosPutRequest(Urls.UernameTags.update,RowData).then(response => {
					setLoader(false)
					if(response[0])
					{
					ToasSucces("Tag List Updated successfully ");
					const UpdatedArray = TagListinside.map((Item, index) => 
					{
						if (index === selectedIndex) 
						{
							Item.userName= RowData.userName
						}
							return Item;
					});
					setTagListinside(UpdatedArray);
					setShow(false);
					}
					else
					ToastError("Tag List  didn't Updated")
				})
			}
	};

	
	const enter = (e)=>{
		if(e.key == "Enter")
			AddNew()
	};


	useEffect(() => 
    {
	  if(show)
	  document.querySelector("#insert_input").focus()
    }, [show])



	const {globalFilter, pageIndex,pageSize} = state

	const SelectOptions = () => {
		
		var List = []
		for (let i = 0; i < props.TagLists.length; i++) 
		{
		  List.push({"value":props.TagLists[i].id , "label":props.TagLists[i].name})
		}
		return List
	}

	const changeTableData = (value) => {
		setLoader(true)
		AxiosGetRequest(Urls.UernameTags.view,[{"HeadKey":"recivedId","HeadValue":value}]).then(response => 
			{
				setLoader(false)
			if(response[0])
			{
				setTagListinside(response[1].data)
				setselectedList(value)
			}
			else
			  ToastError("Tag Lists didnt loaded")
			})
	}

	const changehandler = (e,Index) => {
		const UpdatedArray = UserNamesList.map((Item, index) => 
		{
			if (index === Index) 
			{
				Item.userName= e.target.value
			}
				return Item;
		});
		setUserNamesList(UpdatedArray);
	}
	return(
		<>
				<div className="card-body">
					<div className="table-responsive">
						<div className='d-flex justify-content-end' style={{width:"100%",gap:"1rem"}}>
						{Loader ? <Spinner animation="border" className='mt-2' variant="primary" /> : "" }
							<Col className='pt-2 pr-0' xl={4}> 
								<Select isClearable defaultValue={Types.filter(option => option.label === RowData.collectionType)}  onChange={(e) => changeTableData(e.value)} options={SelectOptions()}/>
							</Col>
							<Button variant='outline-danger' onClick={()=>DeleteRow()}>Delete </Button>
							<Button variant='outline-primary ' onClick={()=>handleShow(1)}>Add New </Button>
						</div>
						<table {...getTableProps()} className="table filtering-table table-responsive-lg">
							<thead>
							   {headerGroups.map(headerGroup => (
									<tr {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map(column => (
											<th {...column.getHeaderProps()}>
												{column.render('Header')}
												{column.canFilter ? column.render('Filter') : null}
											</th>
										))}
										<th className='pb-4' style={{textAlign:"center"}}  role='columnheader'> Edit</th>
									</tr>
							   ))}
							</thead> 
							<tbody {...getTableBodyProps()} className={TagListinside && TagListinside.length <= 0 ?"EmptyTableGap":""} >
								{page.map((row,index) => {
									prepareRow(row)
									return(
										
										<tr {...row.getRowProps()} className={row.isSelected ? "TableRows SelectedRow":"TableRows"}>

											{row.cells.map((cell) => {
												return <td {...cell.getCellProps()} className={"c-pointer"}>
													 {cell.column.id =="value" ?
												cell.render('Cell')
												:cell.render('Cell')} 
												 </td>
											})}
											<td style={{textAlign:"center",width:"35%"}} ><Button variant='outline-primary ' onClick={()=>handleShow(2,row.original,row.index)}>Edit </Button></td>
										</tr>
									)
								})}
								{TagListinside && TagListinside.length <= 0 ? <h5 className='EmptyTableText'>No Usernames to show!</h5>:""}
							</tbody>
						</table>
						<div className="d-flex justify-content-between">
							<span>
								Page{' '}
								<strong>
									{pageIndex + 1} of {pageOptions.length}
								</strong>{''}
							</span>
							<span className="table-index">
								Rows Count : {' '}
								<select
								value={pageSize}
								onChange={e => {
									setPageSize(Number(e.target.value))
								}}
								>
								{[10, 25, 50, 100,200].map(pageSize => (
									<option key={pageSize} value={pageSize}>
									Show {pageSize}
									</option>
								))}
								</select>
							</span>
							<span className="table-index">
								Go to page : {' '}
								<input type="number" 
									className="ml-2"
									defaultValue={pageIndex + 1} 
									onChange = {e => { 
										const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
										gotoPage(pageNumber)
									} } 
								/>
							</span>
						</div>
						<div className="text-center">	
							<div className="filter-pagination  mt-3">
								<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
								
								<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
									Previous
								</button>
								<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
									Next
								</button>
								<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
							</div>
						</div>
					</div>
				</div>
				<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{type === 0 ? "Add" : "Edit"} Tag List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
{type == 0 ? 
			<div className='d-flex rtl' style={{gap:"0.5rem"}}>
			<i className='fa fa-plus-circle c-pointer fs-20 rtl  text-green' onClick={()=>setUserNamesList([...UserNamesList,{userName:"",collectionAdjustmentId:selectedList}]) }></i>
			<i className='fa fa-minus-circle c-pointer fs-20 rtl  text-red' onClick={()=>UserNamesList.length > 1 ? setUserNamesList(UserNamesList.slice(0, -1)):""}></i>
		</div>:""}
		{type == 0 ? 
		UserNamesList.map((Item,index) => 
		(
			<Row className='mt-2'>
				<Col className='pr-0 d-flex  align-items-center' xl={3}>
					<h6> Username{index+1}:</h6>
				</Col>
				<Col className='pl-0  'xl={7}>
					<input type='text' value={Item.userName} onKeyPress={(e) => enter(e)} onChange={(e) => changehandler(e,index)} className=' form-control  text-black  ' style={{height:"45px"}} id={"insert_input"} placeholder='Tag List Name' />
				</Col>
			</Row>
		))
		
:
				<Row className='mt-2'>
				<Col className='pr-0 d-flex  align-items-center' xl={3}>
					<h6> Username:</h6>
				</Col>
				<Col className='pl-0  'xl={7}>
					<input type='text' value={RowData.userName} onKeyPress={(e) => enter(e)} onChange={(e) => ChangeHandler("userName",e.target.value,setRowData,RowData)} className=' form-control  text-black  ' style={{height:"45px"}} id={"insert_input"} placeholder='Tag List Name' autoFocus/>
				</Col>
			</Row>
		}

		</Modal.Body>
		<Modal.Footer>
		{Loader ? <Spinner animation="border" variant="primary" /> : "" }
          <Button variant="outline-primary" onClick={handleClose}>
            cancel
          </Button>
          <Button variant="primary" onClick={AddNew}>
           {type ? "Update":"Add"}
          </Button>
        </Modal.Footer>
      </Modal>
		</>
	)
	
}