import { useEffect, useState } from "react";
import { Card, Col, Nav, Tab } from "react-bootstrap";
import { AxiosGetRequest } from "../../../../services/Connections";
import { Urls } from "../../../../services/ServerandUrls";
import { ToastError } from "../../../../services/Toasts";
import TweetForm from "./TweetForm";
import TweetsList from "./TweetTable/TweetList";



export default function Tweets(props) {
  const [TweetList, setTweetList] = useState([]);
  const [Loader, setLoader] = useState(false)
	const [RefreshData, setRefreshData] = useState(false);
    const tabData = [
        {
          name: 'Tweet Form',
          icon: 'plus',
          content:<TweetForm RefreshData={RefreshData} setRefreshData={setRefreshData} />
        },
        {
          name: 'Tweets List',
          icon: 'list',
          content:
            <TweetsList Loader={Loader} setLoader={setLoader} setTweetList={setTweetList} TweetList={TweetList} RefreshData={RefreshData} setRefreshData={setRefreshData} />
        },
        
      ]

      useEffect(() => {
        setLoader(true)
        AxiosGetRequest(Urls.Tweet.view,[{"HeadKey":"pageSize","HeadValue":1500}]).then(response => {
          if(response[0])
          {
            setTweetList(response[1].data)
            setLoader(false)
          }
          else
            ToastError("Tweet List didnt loaded")
        })
        if(JSON.parse(sessionStorage.getItem("TrendTypes")) == null )
        {
          AxiosGetRequest(Urls.Tweet.trendMethods).then(response => {
            if(response[0])
            {
              var List = []
              for (let i = 0; i < response[1].data.length; i++) {
                List.push({"value":response[1].data[i].name , "label":response[1].data[i].name})
              }
              sessionStorage.setItem("TrendTypes" , JSON.stringify(List))
            }

          })
        }

      }, [RefreshData])

      
    return(
        <Col xl={12}>
          <Card>
            <Card.Body>
              {/* <!-- Nav tabs --> */}
              <div className='default-tab'>
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                  <Nav as='ul' className='nav-tabs'>
                    {tabData.map((data, i) => (
                      <Nav.Item as='li' key={i}>
                        <Nav.Link eventKey={data.name.toLowerCase()}>
                          <i className={`la la-${data.icon} mr-2`} />
                          {data.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content className='pt-0'>
                    {tabData.map((data, i) => (
                      <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                        {data.content}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Card.Body>
          </Card>
        </Col>
    )
}