import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Card, Col, Modal, Nav, OverlayTrigger, Row, Spinner, Tab, Tooltip } from "react-bootstrap";
import Select  from "react-select";
import { ToasSucces, ToastError } from "../../../services/Toasts";
import { NumberDetect } from "../../../services/MathMatics"
import NoImage from "../../../images/avatar/NotFound.png";
import { AddTweetKey, ChangeHandler, GenderCheckbox, Getmultiselect, ImageConvertion, TrendVals } from "./Functions";
import { AxiosGetRequest, AxiosPostRequest } from "../../../services/Connections";
import { Urls } from "../../../services/ServerandUrls";
import { useTable, usePagination, useRowSelect ,useBlockLayout } from 'react-table';
import { formatDistance} from 'date-fns';


export function MultiSelectELM(props) {
   const CustomClearText = () => "clear all";


   //  const ClearIndicator = (props) => {
   //     const {
   //        children = <CustomClearText />,
   //        getStyles,
   //        innerProps: { ref, ...restInnerProps },
   //     } = props;

       
   //     return (
   //        <div
   //           {...restInnerProps}
   //           ref={props.Reff}
   //           style={getStyles("clearIndicator", props)}
   //           id={"ClearSelect"}
   //        >
   //           <div  style={{ padding: "0px 5px" }} >{children}</div>
             
   //        </div>
   //     );
   //  };

    const ClearIndicatorStyles = (base, state) => ({
       ...base,
       cursor: "pointer",
       color: state.isFocused ? "blue" : "black",
    });



   
   return (
      <Select
         closeMenuOnSelect={false}
         styles={{ clearIndicator: ClearIndicatorStyles }}
         isMulti
         options={props.Data}
         isDisabled={props.dis}
         onChange={props.ChangeFunc}
         onBlur={props.BlurFunc}
         id={props.id}
         defaultValue={props.defaultVal}
      />
   );
}

export const CheckboxSelectionAll =  React.forwardRef(({indeterminate,page,currentPage,...rest}, ref)=>{
   const defaultRef = React.useRef()
   const resolvedRef = ref || defaultRef
   

   React.useEffect(()=>{
       resolvedRef.current.indeterminate = indeterminate
   },[resolvedRef,indeterminate])


   

   return(
       <>
       <div className="d-flex justify-content-center align-items-center">
         <input type="checkbox" className="c-pointer " ref={resolvedRef} {...rest} /> 
       </div>
       </>
   )
})

export const CheckboxSelection1 =  React.forwardRef(({indeterminate,...rest}, ref)=>{
   const defaultRef = React.useRef()
   const resolvedRef = ref || defaultRef
   

   React.useEffect(()=>{
       resolvedRef.current.indeterminate = indeterminate
   },[resolvedRef,indeterminate])

  

   return(
       <>
       <div className="d-flex justify-content-center align-items-center">
         <input type="checkbox" className="c-pointer " ref={resolvedRef} {...rest}/> 
       </div>
       </>
   )
})

export const ImageGallery = (props) => {


const CheckImage = (Img,index,Typee,Kind) => {
   if(!(props.selectedImages.some(Item => Item.Type == Kind)))
   {
      if(!(props.selectedImages.some(Item => Item.Numb == Typee+index)))
      {
         if(props.selectedImages.length < 4)
         {
         props.setselectedImages(current => [...current, {Img:Img,Numb:Typee+index,Type:Typee}])
         }
         else
         ToastError("You can't add more than 4 Files",5)
      }
      else
      {
         const FilteredList = props.selectedImages.filter((item) => item.Numb !== Typee+index);
         props.setselectedImages(FilteredList)
      }
   }
   else
   ToastError("You can't add both video and image",5)
}

const selectImage = (Img,index,Typee) => {
   
   if(Typee == "2")
   {
      CheckImage(Img,index,Typee,"1")
   }
   else if(Typee == "1")
   {
      CheckImage(Img,index,Typee,"2")
   }
 };

   return(
      <>
      <div className=" w-100 border border-pimary mb-3" style={{height:"10rem" , overflow:"hidden" }}>
         <div className="h-100 Media-Gallery no-gutters p-1 " style={{overflowY:"auto" }}>
            {props.ListType ? 
            <>
            {props.Vids.map((image,index) => {
               return(
                  <>
                     <div key={index}  onClick={(e) => selectImage(image,index,'1')} className="d-flex justify-content-center align-items-center c-pointer Gallery-item overflow-hidden position-relative  " style={{width:"75px", height:"75px",borderRadius:"0.3rem"}}>
                        <video id="video"className="w-100 h-100  img-thumbnail" style={{display:"block"}} src={URL.createObjectURL(image)} type="video/mp4" controls={false}/>
                        {
                           props.selectedImages.map(selected => selected.Numb == "1"+index ? <i className="fa fa-check-circle position-absolute text-primary" style={{bottom:"5px",right:"5px"}}></i> :"")
                        }
                     </div>
                  </>
               )
            })}
         </>
            :
            <>
               {props.Images.map((image,index) => 
               {
                  return(
                     <>
                        <div key={index} onClick={(e) => selectImage(image,index,'2')} className={"d-flex justify-content-center align-items-center c-pointer Gallery-item overflow-hidden position-relative " + (props.selectedImages.length === 2 || props.selectedImages.includes(index))}  style={{width:"75px", height:"75px",borderRadius:"0.3rem"}}> 
                           <img   onLoad={() => { URL.revokeObjectURL(image) }} className={"w-100 h-100  img-thumbnail "}alt="thumb" style={{display:"block"}} src={URL.createObjectURL(image)}></img>
                           {
                              props.selectedImages.map(selected => selected.Numb == "2"+index ? <i className="fa fa-check-circle position-absolute text-primary" style={{bottom:"5px",right:"5px"}}></i> :"")
                           }
                        </div> 
                     </>
                  )
               })}
            </>
            }
         </div>
      </div>
      </>
   )
}

export const RenderTooltip = (props,text) => (
   <Tooltip id="button-tooltip" {...props} >
     {text}
   </Tooltip>
);

export const OperationColumn = (props) => {

   const [List, setList] = useState([])


   useEffect(() => {
      if(JSON.parse(localStorage.getItem("OperationList")) !== null )
      setList(JSON.parse(localStorage.getItem("OperationList")))
   }, [])

   return(
      <>
         <Col className="d-flex align-items-center"  xl={1}>
            <div className=' form-check pl-0'>
               <label htmlFor="Operation" className='form-check-label'>
                  Operation:
               </label>
            </div>
         </Col>
         <Col xl={3}>
            <div className='form-group mt-3'>
               <Select isClearable onChange={(e) => ChangeHandler('operationId',e !=null ? e.value:null,props.setOptions,props.Options)} options={List}/>
            </div>
         </Col>
      </>
   )
}

export const SubjectColumn = (props) => {

   const [List, setList] = useState([])


   useEffect(() => {
      if(JSON.parse(localStorage.getItem("SocialList")) !== null )
      setList(JSON.parse(localStorage.getItem("SocialList")))
   }, [])
   
   const getAvailAcconts = () => {
      props.setLoader(true)
      if(props.Options.socialAreas.length > 0)
      {
         if(props.double)
         {
            AxiosGetRequest(Urls.Acounts.AvailAccountsBySubject,[{"HeadKey":"SocialAreas","HeadValue":props.Options.socialAreas},{"HeadKey":"GenderType","HeadValue":props.Options.genderType},{"HeadKey":"orderType","HeadValue":props.type1}]).then(response => {
               if(response[0])
               {
                 props.setAvailAccounts1(response[1].data)
               }
               else
                 ToastError("An error has been acquired")
             })
             AxiosGetRequest(Urls.Acounts.AvailAccountsBySubject,[{"HeadKey":"SocialAreas","HeadValue":props.Options.socialAreas},{"HeadKey":"GenderType","HeadValue":props.Options.genderType},{"HeadKey":"orderType","HeadValue":props.type2}]).then(response => {
               props.setLoader(false)
               if(response[0])
               {
                 props.setAvailAccounts2(response[1].data)
               }
               else
                 ToastError("An error has been acquired")
             })
         }
         else
            AxiosGetRequest(Urls.Acounts.AvailAccountsBySubject,[{"HeadKey":"SocialAreas","HeadValue":props.Options.socialAreas},{"HeadKey":"GenderType","HeadValue":props.Options.genderType},{"HeadKey":"orderType","HeadValue":props.type}]).then(response => {
               props.setLoader(false)
               if(response[0])
               {
               props.setAvailAccounts(response[1].data)
               }
               else
               ToastError("An error has been acquired")
            }    )
      }
      else
      {
         if(props.double)
         {

            AxiosGetRequest(Urls.Acounts.AvailAccounts,[{"HeadKey":"orderType","HeadValue":props.type1}]).then(response => {
               if(response[0])
               {
                  props.setAvailAccounts1(response[1].data)
               }
               else
                  ToastError("An error has been acquired")
               })
               AxiosGetRequest(Urls.Acounts.AvailAccounts,[{"HeadKey":"orderType","HeadValue":props.type2}]).then(response => {
                  props.setLoader(false)
                  if(response[0])
                  {
                     props.setAvailAccounts2(response[1].data)
                  }
                  else
                     ToastError("An error has been acquired")
                  })
         }
         else
         AxiosGetRequest(Urls.Acounts.AvailAccounts,[{"HeadKey":"orderType","HeadValue":props.type}]).then(response => {
            props.setLoader(false)
            if(response[0])
            {
               props.setAvailAccounts(response[1].data)
            }
            else
               ToastError("An error has been acquired")
            })
      }
      

   } 



   return(
      <>
         <Col className="d-flex align-items-center"   xl={1}>
            <div className=' form-check  pl-0'>
               <label htmlFor="Subject" className='form-check-label'>
                  Subject:
               </label>
            </div>
         </Col>
         <Col xl={3}>
                    <div className='form-group mt-3'>
                     <MultiSelectELM ChangeFunc={(e)=>Getmultiselect(e,props.setOptions,props.Options,"socialAreas")} BlurFunc={() => getAvailAcconts()} id={props.ID}  Data={List}></MultiSelectELM>
                  </div>
         </Col>
      </>
   )
}

export const GenderColumn = (props) => {


   return(
      <Col  xl={props.xl ? props.xl:4} className={"ml-0 p-0"}>
         <div className={"d-flex flex-row align-items-center h-100 " + (props.CustomClass ? props.CustomClass:"")} >
            <Col xl={3} className={"p-0"} id={"Gender" + props.customID}>
               <h5 className="col-lg-2 mb-0 text-nowrap">Gender:</h5>
            </Col>
            <div style={{zIndex:0}} className='custom-control custom-checkbox mr-3 ml-3'>
               <input
               type='checkbox'                      
               className='custom-control-input'
               id={props.customID}
               ref={props.MaleRef}
               onChange={(e)=>GenderCheckbox("genderType",props.FemaleRef,props.MaleRef,props.setOptions,props.Options)}
               checked={(props.Options.genderType == "Male" ? true:false)|| (props.Options.genderType == "FemaleMale" ? true:false)}
               />
               <label
               className='custom-control-label'
               htmlFor={props.customID}
               >
               Male
               </label>
            </div>
            <div style={{zIndex:0}}  className='custom-control custom-checkbox mr-3 ml-3'>
               <input
               type='checkbox'
               className='custom-control-input'
               id={'FemaleCheckBox11'+(props.customID)}
               ref={props.FemaleRef}
               checked={(props.Options.genderType == "Female" ? true:false)|| (props.Options.genderType == "FemaleMale" ? true:false)}
               onChange={(e)=>GenderCheckbox("genderType",props.FemaleRef,props.MaleRef,props.setOptions,props.Options)}
               />
               <label
               className='custom-control-label'
               htmlFor={'FemaleCheckBox11'+(props.customID)}
               >
               Female
               </label>
            </div>
         </div>
      </Col>)
}

export const AccountColumn = (props) => {

   const [List, setList] = useState([])


   useEffect(() => {
      if(JSON.parse(localStorage.getItem("AccountsList")) !== null )
      setList(JSON.parse(localStorage.getItem("AccountsList")))
   }, [])

   return(
      <>
         <Col className="d-flex align-items-center"  xl={"1"}>
            <div className=' form-check pl-0'>
               <label htmlFor="SingleAccount" className='form-check-label'>
               Acounts:
               </label>
            </div>
         </Col>
         <Col xl={3} className="mt-3">
            <Select  isClearable onChange={(e) => ChangeHandler('privateRobotAccountId',e !=null ? e.value:null,props.setOptions,props.Options)} options={List}/>
         </Col>
      </>
   )
}

export const TagListColumn = (props) => {
   
   const [List, setList] = useState([])


   useEffect(() => {
      AxiosGetRequest(Urls.TagLists.getByType,[{"HeadKey":"collectionType","HeadValue":"Tags"}]).then(response => 
         {
         if(response[0])
         {
         
           var TempList = []
            for (let i = 0; i < response[1].data.length; i++) 
            {
               TempList.push({"value":response[1].data[i].id , "label":response[1].data[i].name})
            }
          
           setList(TempList)
         }
         })
   }, [])
   
   return (
      <>
      <Col className="d-flex align-items-center"  xl={1}>
         <div className=' form-check pl-0'>
            <label htmlFor="Operation" className='form-check-label'>
            Tag List:
            </label>
         </div>
      </Col>
      <Col xl={3}>
         <div className='form-group mt-3'>
            <Select  isClearable  onChange={(e) => ChangeHandler('tagCollectionId',e !=null ? e.value:null,props.setOptions,props.Options)} options={List}/>
         </div>
      </Col>
      </>
   )
}

export const HashtagSourceSelect = (props) => {
   const options = [
      { value: 'Trend24', label: 'Trend24' },
      { value: 'getdaytrends', label: 'getdaytrends' },
      { value: 'Twitter', label: 'Twitter' },
    ]
  const SelectRef = useRef(null)
    useEffect(() => {
      if(!props.DisabledTrigger && SelectRef.current!==null)
         {
            SelectRef.current.clearValue()
         }
   }, [props.DisabledTrigger])
    return (
      <>
      <Select ref={SelectRef} placeholder={"Select Source"} isDisabled={!props.DisabledTrigger}  onChange={(e) => ChangeHandler('TrendSuorce',e !=null ? e.value:null,props.setOptions,props.Options)} options={options}> </Select>
      </>
    )
}

export const HashtagCountOptions = (props) => {

    return (
      <>
      <h5 className={" border-0 m-0  "}>Skip Hashtag</h5>
      <div className={"form-control input-default d-flex justify-content-start align-items-center p-0 mr-3 " + (!props.DisabledTrigger ? "disabled-item":"") }style={{width:"5rem",height:"2.5rem"}}>
         <input 
         disabled={!props.DisabledTrigger} defaultValue={1} 
         className={' form-control input-default h-100 border-0 w-75 ' + (!props.DisabledTrigger? " ":" text-black")}
         onChange={(e) => ChangeHandler('CountSkipeHashtag',e !=null ? e.target.value:0,props.setOptions,props.Options)} 
         type={"number"} value={props.Options ? props.Options.CountSkipeHashtag:""} 
         placeholder="1">
         </input>
      </div>
      <h5 className={" border-0 m-0  "}>Hashtag Numbers</h5>
      <div className={"form-control input-default d-flex justify-content-start align-items-center p-0 " + (!props.DisabledTrigger ? "disabled-item":"") } style={{width:"5rem",height:"2.5rem"}}>
         <input 
         disabled={!props.DisabledTrigger} 
         defaultValue={1} 
         className={' form-control input-default h-100 border-0 w-75 ' + (!props.DisabledTrigger? " ":" text-black")} 
         onChange={(e) => ChangeHandler('CountTrandHastagAdd',e !=null ? e.target.value:1,props.setOptions,props.Options)} 
         type={"number"} 
         value={props.Options ? props.Options.CountTrandHastagAdd:""} 
         placeholder="1">

         </input>
      </div>
      </>
    )
}


export  function TweetCard(props) {
    
    return (
        <div className='card tweet-card w-100 mb-0 border'>
                        <div className='card-body  rounded p-2'>
                          <div className='d-flex flex-column Top-Tweet-inner-container'> 
                            <div className='d-flex align-items-center Top-tweet-card-profile-container pb-3 pt-3 border-bottom'>
                              <div  className='rounded-circle border Top-tweet-card-profile-pic' >
                                <img className='rounded-circle' src={props.Tweets.imageUrl != "" ? props.Tweets.imageUrl : NoImage} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src=NoImage;}} alt="tweetpic" style={{width:"100%",height:"100%"}}></img>
                              </div>
                                <a href={"https://twitter.com/"+props.Tweets.userName} rel='noreferrer' target='_blank' className='m-0 font-w700 c-pointer'>
                                  {props.Tweets.userName}
                                </a> 
                                <a className='font-w600 m-0 position-absolute' style={{right:"1.2rem"}} href={props.Tweets.link}  rel='noreferrer' target='_blank'>
                                  Link
                                </a>
                            </div>
                            <div className='Top-tweet-card-text-container mt-1' style={{height:"7rem"}}>
                              {/* <p className='m-0 h-100 fs-15 ' dir="rtl">
                              </p> */}
                              <textarea className='pr-2 pl-2 m-0 h-100 w-100 fs-15 border-0 TextAreaTweetCard'  dir="auto" readOnly value={props.Tweets.massege}>

                              </textarea>
                            </div>
                            <div className='Top-Tweets-card-icon-container d-flex justify-content-around border-top'>
                                <div className='col-3 text-center d-flex justify-content-center align-items-center'> 
                                    <a className='font-w600 m-0 c-pointer' onClick={() => props.OpenDetail(props.Tweets)}>More</a>
                                </div>
                                <div className='col-3 text-center d-flex flex-column justify-content-center'>
                                    <p className='m-1'>{NumberDetect(props.Tweets.likes)}</p> <h6 className='m-0 font-w600 text-nowrap'>LIKE</h6>
                                </div>
                                <div className='col-3 text-center d-flex flex-column justify-content-center'> 
                                    <p className='m-1'>{NumberDetect(props.Tweets.impressions)}</p> <h6 className='m-0 font-w600 text-nowrap'>IMP</h6>
                                </div>
                                <div className='col-3 text-center d-flex flex-column justify-content-center'> 
                                    <p className='m-1'>{NumberDetect(props.Tweets.retweets)}</p> <h6 className='m-0 font-w600 text-nowrap'>RETW</h6>
                                </div>
                            </div>
                          </div>
                          <div className='col-3 text-center d-flex justify-content-center align-items-center'> </div>
                          <div> </div>
                        </div>
                      </div>
    )
} 

export function TweetTextbox (props){


   return(
      <div className="  d-flex flex-column align-items-center">
      <h6 style={{position:"absolute",right:"1.6rem"}} >{(props.Index+1)}/{props.TweetList.length}</h6>
            <div className=" w-100 d-flex flex-row align-items-center">
                  <textarea onKeyDown={e => AddTweetKey(e,props.TweetList,props.setTweetList)}  dir="auto" value={props.Tweet.Text} onChange={(e) => props.ChangeTweet(e,props.Index)} className="form-control input-default rounded-lg text-black fs-16" placeholder="Reply Text"  rows="2"></textarea>
                  <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 100 }}overlay={RenderTooltip(1,"Remove this Tweet")}>
                     <div onClick={()=>props.RemoveTweet(props.Index)} className="p-1 rounded-circle d-flex justify-content-center align-items-center c-pointer ml-3" style={{width:"20px",height:"20px",background:"#9c0606"}}>
                        <i className="fa-light fa-xmark text-white"></i>
                     </div>
                  </OverlayTrigger>
            </div>
            <div className="w-100 d-flex flex-row">
                  <span className=" text-grey">{props.Tweet.Text.length}/280</span>
            </div>
         </div>
   )
}

export function TweetWithMedia (props){

   return(
      <>
      <div className="  d-flex flex-column align-items-center " >
      <h6 style={{position:"absolute",right:"1.6rem"}} >{(props.Index+1)}/{props.TweetList.length}</h6>
         <div className=" w-100 d-flex flex-row align-items-center">
               <textarea onFocus={()=> props.setfocused(props.Index)}   onKeyDown={e => AddTweetKey(e,props.TweetList,props.setTweetList)} value={props.Tweet.Text} onChange={(e) => props.ChangeTweet(e,props.Index)} className="form-control input-default rounded-lg text-black fs-18 font-weight-bold" dir="auto" placeholder="Tweet Text" rows="4"></textarea>
               <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 100 }}overlay={RenderTooltip(props,"Add media")}>
               <i onClick={() => props.handleModal(props.Index,props.Tweet.TempMedia,false)} className="fa-light fa-image c-pointer ml-3 mr-3 fs-20"></i>
               </OverlayTrigger>         
               <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 100 }}overlay={RenderTooltip(props,"Remove this Tweet")}>
               <div onClick={()=>props.RemoveTweet(props.Index,props.Tweet)} className="p-1 rounded-circle d-flex justify-content-center align-items-center c-pointer" style={{width:"20px",height:"20px",background:"#9c0606"}}>
                  <i className="fa-light fa-xmark text-white"></i>
               </div>
               </OverlayTrigger>
         </div>
         <div className="w-100 d-flex flex-row align-items-center">
               <span className=" text-grey">{props.Tweet.Text.length}/280</span>
               {props.Tweet.TempMedia.map(media => {
         return(
            <>
            {
               media.Type == "2" ? 
            <img alt="thumbs" className="m-1 border " style={{width:"70px",height:"70px",borderRadius:"10px"}} src={URL.createObjectURL(media.Img)}></img>
            :
            <VideoPreview media={media}></VideoPreview>
            }
            </>)})}
         </div>
      </div>
      </>
   )
}

const VideoPreview = (props) => {
   // Use useMemo to prevent unnecessary re-renders
   const videoSrc = useMemo(() => URL.createObjectURL(props.media.Img), [props.media.Img]);
 
   return (
     <video
       id="video"
       className="img-thumbnail"
       style={{ width: "70px", height: "70px", borderRadius: "10px" }}
       src={videoSrc}
       type="video/mp4"
       controls={false}
     />
   );
 };
 
 export default React.memo(VideoPreview);

export const ContentColumn = (props) => {

   const [List, setList] = useState([])


   useEffect(() => {

   }, [])
   

   return(
      <>
         <Col className="d-flex align-items-center"  xl={1}>
                    <div className=' form-check pl-0'>
                        <label htmlFor="SingleAccount" className='form-check-label'>
                            Contents:
                        </label>
                    </div>
                  </Col>
                  <Col xl={3}>
                  <MultiSelectELM Data={List}></MultiSelectELM>
            </Col>
      </>
   )
}


export const ProggressBar = (props) => {

   return(
      <div className="WizardProggressContainer">
         <div className="WizardProggressBar" style={{width:"40%"}}></div>
      </div>
   )
}


export const CardWithTabs = (props) => {

   return(
      <Col xl={12}>
        <Card>
          <Card.Body>
            <div className='default-tab'>
              <Tab.Container defaultActiveKey={props.tabData[0].name.toLowerCase()}>
                <Nav as='ul' className='nav-tabs'>
                  {props.tabData.map((data, i) => (
                    <Nav.Item as='li' key={i}>
                      <Nav.Link eventKey={data.name.toLowerCase()}>
                        <i className={`fa-light fa-${data.icon} mr-2`} />
                        {data.name}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
                <Tab.Content className='pt-4'>
                  {props.tabData.map((data, i) => (
                    <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                      {data.content}
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Tab.Container>
            </div>
          </Card.Body>
        </Card>
      </Col>
  )

}

export const PostFunction = (Kind,calledUrl,Options,setRefreshData,RefreshData,Loader,) => {
   Loader(true)
   let url = calledUrl
   if(calledUrl.split('.').length > 1)
      url = calledUrl.split('.').reduce((obj, key) => (obj && obj[key] !== 'undefined') ? obj[key] : undefined, Urls);
   AxiosPostRequest(url,[Options]).then(response => {
     Loader(false);
     if(response[0])
     {
       ToasSucces(`${Kind} has been submitted`)
     }
     else
       ToastError(`${Kind} has not been submitted`)
   })
 }

export const Loader = () => {

   return(
      <>
      <Spinner animation="border" variant="primary" className="mt-2" />
      </>
   )
 }

export const AccountEditModal = (props) => {
   return(
      <>
      <Modal.Header closeButton>
		<Modal.Title>Edit Row</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			{Object.keys(props.EditedRow).map((row,index) => 
				props.writeEdit(row,index)
			)}
			<Row  className={"mb-1"}>
				<Col className='pr-0 d-flex  align-items-center' xl={4}>
					<h6>Social Subjects:</h6>
				</Col>
				<Col className='pl-0  'xl={7}>
					<MultiSelectELM ChangeFunc={(e)=>Getmultiselect(e,props.setselectedSubList,props.SelectedSubList,"socialAreeas")} id={props.ID}  Data={props.SubjectList} defaultVal={props.AccountSubList} ></MultiSelectELM>
				</Col>
			</Row>
		</Modal.Body>
		<Modal.Footer>
		{props.Loader ? <Spinner animation="border" className='mb-3' variant="primary" /> : "" }
		<Button variant="outline-primary" onClick={props.handleClose}>
			cancel
		</Button>
		<Button variant="primary" onClick={props.SendItem}>
			Save Changes
		</Button>
		</Modal.Footer>
      </>
      
   )
}

export const AccountLogTable = (props) =>
{
   const Tablecolumns = [
      {
         Header : ' Created date',
         Footer : 'Created date',
         accessor: 'createdDate',
         Cell: ({ value }) => {return formatDistance(new Date(value), new Date())},
      },
      {
         Header : 'State',
         Footer : 'State',
         accessor: 'state',
      },
      {
         Header : 'Order Type',
         Footer : 'Order Type',
         accessor: 'orderType',
      },
      {
         Header : 'Description',
         Footer : 'Description',
         accessor: 'description'
      },
      {
         Header : 'ScreenShot',
         Footer : 'ScreenShot',
         accessor: 'screenShot',
         Cell: ({ value }) => {return value ?  <i className="fa-solid fa-image c-pointer" onClick={()=>window.open(ImageConvertion(value), '_blank')}></i>:""},
      }
   ]
//  console.log(props.DataList)
	const columns = useMemo( () => Tablecolumns, [] )
	const data = useMemo( () => props.DataList, [props.DataList] )
   const tableInstance = useTable({
		columns,
		data,	
		initialState : {pageIndex : 0,pageSize:200}
	}, useBlockLayout , usePagination , useRowSelect,
	(hooks)=>{
		hooks.visibleColumns.push((columns)=>{
			return [
				{
					id:"Counter",
					Header:({getToggleAllRowsSelectedProps}) => (
						<div >
						#
						</div>
					),
					Cell:({row}) => (
						<div>
							{row.index +1 }
						</div>
					),
					width:50
				},
				...columns
			]
		})
	}
	);

   const { 
		getTableProps, 
		getTableBodyProps, 
		headerGroups, 
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
	} = tableInstance;
	const {pageSize} = state

   return(
      <>
         <Modal.Header closeButton>
            <Modal.Title>Logs List</Modal.Title>
         </Modal.Header>
         <Modal.Body>
         <div className='TableContainer '>
							<div className="tableBlock" {...getTableProps()}>
								<table className="table filtering-table table-responsive-lg TableCustom">
									<thead >
									{headerGroups.map(headerGroup => (
											<tr {...headerGroup.getHeaderGroupProps()} className="HeaderRow" >
												{headerGroup.headers.map(column => (
													<th {...column.getHeaderProps()} style={{minWidth:"200px",textAlign:"center"}}>
														{column.render('Header')}
														{column.canFilter ? column.render('Filter') : null}
													</th>
												))}
											</tr>
									))}
									</thead> 
									<tbody {...getTableBodyProps()} id="TableBodyId" >
										{
										page.length > 0 ? 
										page.map((row,index) => {
											prepareRow(row)
											return(
												<tr {...row.getRowProps()}>
													{row.cells.map((cell) => {
														return <td {...cell.getCellProps()} style={{minWidth:"200px",textAlign:"center"}}> {cell.render('Cell')} </td>
													})}
												</tr>
											)
										}):<p className='d-flex w-100 justify-content-center'> No items to show</p>}
									</tbody>
								</table>
							</div>
						</div>
		   </Modal.Body>
         <Modal.Footer>
         <div className="text-center" style={{marginBottom:"1.1rem",marginRight:"28%"}}>	
							<div className="filter-pagination  mt-3">
								<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
								<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
									Previous
								</button>
								<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
									Next
								</button>
								<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
							</div>
						</div>
		      <Button variant="outline-primary" onClick={props.handleClose}>
			      Close
		      </Button>
		   </Modal.Footer>
      </>
   )
}

export const HashtaghCheckbox = (props) =>{

return(
      <div style={{zIndex:0}}  className='custom-control custom-checkbox mr-3 ml-3 mt-2'>
                          <input
                          type='checkbox'
                          className='custom-control-input'
                          id='Trending'
                          onChange={(e) => TrendVals(props.setTrendVal,props.setOptions,props.Options,props.SelectRef,e.target.checked)}
                          />
                          <label
                          className='custom-control-label '
                          htmlFor='Trending'
                          >
                          Use Trending Hashtags
                          </label>
      </div>
)
}

export const HashtagSelectOptions = (props) =>{
 
   return(
      <>
         <Select 
            isDisabled={!props.TrendVal} 
            ref={props.SelectRef}  
            defaultValue={props.selectedOption} 
            placeholder="Position" 
            onChange={(e)=>ChangeHandler('trendMethodAddType',e !=null ? e.value:null,props.setOptions,props.Options)} 
            options={props.TrendTypes}/>
               <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 100 }}overlay={RenderTooltip(1,"Default Value is Top")}>
                  <i className="d-flex justify-content-center align-items-center c-pointer ml-2 pb-3 fa-regular fa-exclamation-circle"></i>
               </OverlayTrigger>
            <div style={{zIndex:0}}  className='custom-control custom-checkbox mr-3 ml-3 mt-2'>
               <input
               type='checkbox'
               className='custom-control-input'
               id='hashtaqg'
               onChange={(e)=>ChangeHandler('IsNeedUseJustFristHashtag',e !=null ? e.value:null,props.setOptions,props.Options)} 
               disabled={!props.TrendVal}
               />
               <label
               className='custom-control-label '
               htmlFor='hashtaqg'
               >
                  Use 1st hashtag
               </label>
            </div>
      </>
   )
}
// export const RepeatColumn = (props) => {

//    const [List, setList] = useState([])


//    useEffect(() => {

//    }, [])
   

//    return(
//       <>
//          <Col  xl={4}>
//             <div className="d-flex flex-row mt-3">
//                <div style={{zIndex:0}}  className='custom-control custom-checkbox mr-3 ml-3 mt-2 mb-2'>
//                   <input type='checkbox' className='custom-control-input ' id='Repeat' value={props.Options.isNeedtextRepetition} onChange={(e) => RepeatCheckBoxFunction('isNeedtextRepetition',e.target.checked,setOptions,Options)}/>
//                   <label className='custom-control-label ' htmlFor='Repeat'>Repeat:</label>
//                </div>
//                <div style={{zIndex:0,marginTop:"-0.4rem"}}  className='d-flex custom-control custom-checkbox mr-3 pl-0'>
//                   <input className='form-control input-default w-50 ' onChange={(e) => ChangeHandler('countRepetition',e.target.value,setOptions,Options)} value={Options.countRepetition} placeholder='Amount' type="number" disabled={!RepeatVal}/>
//                   <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 100 }}overlay={RenderTooltip(props,"If checked, tweets will be repeated with slighlty changed text")}>
//                   <i className="d-flex justify-content-center align-items-center c-pointer ml-2 fa-regular fa-question-circle"></i>
//                   </OverlayTrigger>
//                </div>
//             </div>
//          </Col>
//       </>
//    )
// }