import { Card, Col, Nav, Tab } from "react-bootstrap";
import CoverForm from "./CoverForm";
import HashtagCoverForm from "./HashtagCoverForm";
import TableElement from "../../FilteringTable/TableElment";
import { CoverCOLUMNS, HashtagCoverCOLUMNS } from "./Columns";
import { useEffect, useState } from "react";
import { AxiosGetRequest } from "../../../../services/Connections";
import { Urls } from "../../../../services/ServerandUrls";
import { ToastError } from "../../../../services/Toasts";
// import { setNumber, getNumber } from "../../../../store/actions/NumberSlice";







export default function CoverContainer(props) {
  const [ List, setList] = useState([]);
  const [ List1, setList1] = useState([]);
  const [Loader, setLoader] = useState(false);
	const [RefreshData, setRefreshData] = useState(false);
  

    const tabData = [
        {
          name: 'Cover',
          icon: 'ballot',
          content:<CoverForm Loader={Loader} setLoader={setLoader} RefreshData={RefreshData} setRefreshData={setRefreshData} />
        },
        {
          name: 'Covers List',
          icon: 'list',
          content:<TableElement 
          ExcludedFields={["userName","createdDate","expiryDate","operationName","scheduledAdjustmentName","orderState","finishedDate"]} 
            Headers={CoverCOLUMNS}
            Type={"Cover"}
            hasEdit={true}
            RefreshData={RefreshData} 
            setRefreshData={setRefreshData}
            Loader={Loader}
            setLoader={setLoader}
            hasDelete={true} 
            DataList={List}
            setDataList={setList}
            />
        },
        {
          name: 'Hashtag Cover',
          icon: 'hashtag',
          content:
          <HashtagCoverForm Loader={Loader} setLoader={setLoader} RefreshData={RefreshData} setRefreshData={setRefreshData}/>
        },
        {
          name: 'Hashtag Covers List',
          icon: 'list',
          content:<TableElement 
          Headers={HashtagCoverCOLUMNS} 
          Type={"HashtagCovers"} 
          hasEdit={false} 
          Loader={Loader} 
          RefreshData={RefreshData} 
          setRefreshData={setRefreshData}
          setLoader={setLoader} 
          hasDelete={true} 
          DataList={List1}
          setDataList={setList1}
          />
        },
        
      ]

      
      useEffect(() => {

        setLoader(true);
        AxiosGetRequest(Urls.Cover.view,[{"HeadKey":"pageSize","HeadValue":1500}]).then(response => 
          {
          setLoader(false);
          if(response[0])
          {
            setList(response[1].data)
          }
          else
            ToastError("Covers List List didnt loaded")
          })
          AxiosGetRequest(Urls.HashtagCovers.view,[{"HeadKey":"pageSize","HeadValue":1500}]).then(response => 
            {
            setLoader(false);
            if(response[0])
            {
              setList1(response[1].data)
            }
            else
              ToastError("Hashtag Covers List didnt loaded")
            })
      }, [RefreshData])

    return(
        <Col xl={12}>
          <Card>  
            <Card.Body>
              {/* <!-- Nav tabs --> */}
              <div className='default-tab'>
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                  <Nav as='ul' className='nav-tabs'>
                    {tabData.map((data, i) => (
                      <Nav.Item as='li' key={i}>
                        <Nav.Link eventKey={data.name.toLowerCase()}>
                          <i className={`fa-light fa-${data.icon} mr-2`} />
                          {data.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content className='pt-4'>
                    {tabData.map((data, i) => (
                      <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                        {data.content}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Card.Body>
          </Card>
        </Col>
    )
}