import {format} from 'date-fns';

import { ColumnFilter } from '../../FilteringTable/ColumnFilter';

export const TagListColumns = [
	{
		Header : 'Name',
		Footer : 'Name',
		accessor: 'name',
		Filter: ColumnFilter,
	},
	{
		Header : 'Type',
		Footer : 'Type',
		accessor: 'collectionType',
		Filter: ColumnFilter,
	}
]

export const UsernamesCOLUMNS = [
	
	{
		Header : 'Username ',
		Footer : 'Username',
		accessor: 'userName',
		Filter: ColumnFilter,
	}
	,
	{
		Header : 'Count Used ',
		Footer : 'Count Used',
		accessor: 'countUsed',
		Filter: ColumnFilter,
	}
]
