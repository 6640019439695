import { CheckboxSelection1 } from "../../Elements/Elements";
import React,{ useMemo } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination, useRowSelect,useFlexLayout } from 'react-table';
import { GlobalFilter } from "../GlobalFilter";
import { ColumnFilter } from "../ColumnFilter";
import { Spinner } from "react-bootstrap";


export default function SingleTable (props) {
    const columns = useMemo( () => props.Headers, [props.Headers] )
	const data = useMemo( () => props.DataList, [props.DataList] )

    const tableInstance = useTable({
		columns,
		data,	
		initialState : {pageIndex : 0,pageSize:25}
	}, useFilters, useGlobalFilter, usePagination , useRowSelect,useFlexLayout ,
	(hooks)=>{
		hooks.visibleColumns.push((columns)=>{
			return [
				{
					id:"selection",
					Filter: ColumnFilter,
					Header:({getToggleAllRowsSelectedProps}) => (
						<div style={{paddingTop:"2.5rem"}}>
						<CheckboxSelection1 {...getToggleAllRowsSelectedProps()}/>
						</div>
					),
					Cell:({row}) => (
						<CheckboxSelection1 {...row.getToggleRowSelectedProps()}/>
					),
					width:30
				},
				...columns
			].filter(Boolean);
		})
	}
	)

    const { 
		getTableProps, 
		getTableBodyProps, 
		headerGroups, 
		prepareRow,
		setPageSize,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance

    
	const {globalFilter, pageIndex,pageSize} = state

    return(
        <div className="table-responsive">
        <div className='d-flex  w-100 align-items-end' style={{gap:"1.5rem"}}>
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            {props.Loader ? <Spinner animation="border" className='mb-3' variant="primary" /> : "" }
        </div>
        <table {...getTableProps()} className="table filtering-table table-responsive-lg">
            <thead>
               {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        <th style={{paddingTop:"3.2rem",width:"25px"}}  >#</th>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>
                                {column.render('Header')}
                                {column.canFilter ? column.render('Filter') : null}
                            </th>
                        ))}
                    </tr>
               ))}
            </thead> 
            <tbody {...getTableBodyProps()} className="" >
                {
                page.length > 0 ? 
                    page.map((row,index) => {
                        prepareRow(row)
                        return(
                            <tr {...row.getRowProps()} className={row.isSelected ? "TableRows SelectedRow":"TableRows"}>
                                    <td >{pageIndex * pageSize + index + 1}</td>
                                {row.cells.map((cell) => {
                                    return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>
                                })}
                            </tr>
                        )
                    })
                    :
                    <p className='d-flex w-100 justify-content-center'> No items to show</p>}
            </tbody>
        </table>
        <div className="d-flex justify-content-between">
            <span>
                Page{' '}
                <strong>
                    {pageIndex + 1} of {pageOptions.length}
                </strong>{''}
            </span>
            <span className="table-index">
                Rows Count : {' '}
                <select
                value={pageSize}
                onChange={e => {
                    setPageSize(Number(e.target.value))
                }}
                >
                {[10, 25, 50, 100,200].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                    </option>
                ))}
                </select>
            </span>
            <span className="table-index">
                Go to page : {' '}
                <input type="number" 
                    className="ml-2"
                    defaultValue={pageIndex + 1} 
                    onChange = {e => { 
                        const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
                        gotoPage(pageNumber)
                    } } 
                />
            </span>
        </div>
        <div className="text-center">	
            <div className="filter-pagination  mt-3">
                <button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
                
                <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                    Previous
                </button>
                <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                    Next
                </button>
                <button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
            </div>
        </div>
    </div>
    )
}