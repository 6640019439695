import React, { createRef, Fragment, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import WizardTweetContainer from "./WizardTweetsContainer";
import Confirm from "./ConfirmPage";
import OperationWizard from "./OperationWizard";




const pages = 
[
  { component: <OperationWizard />, title: "Operation" },
  { component: <WizardTweetContainer />, title: "Tweets" },
  { component: <Confirm  />, title: "Final Results" }
];

const Wizard = () => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [Options, setOptions] = useState({
    "name": "",
    "combineBotOrder": false,
    "isActive": true,
    "startDate":new Date(),
    "endDate":new Date(),
    "Hashtagh":""
  })
  const [TweetList, setTweetList] = useState([
    {
        Text:"",
        Files:[],
        TempMedia:[],
        id:0,
        nodeRef: createRef(null),
    },

  ]);


  const currentPage = pages[currentPageIndex];

  const handleNext = () => {
    setCurrentPageIndex(currentPageIndex + 1);
  };

  const handlePrev = () => {
    setCurrentPageIndex(currentPageIndex - 1);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-header pt-2 pb-2">
              <h4 className="card-title font-weight-bold">{currentPage.title}</h4>
            </div>
        <ProgressBar striped now={(100/pages.length)*(currentPageIndex+1)} ></ProgressBar>
            <div className="card-body d-flex flex-column" style={{ height: "32rem" }}>
              <div style={{ height: "89%", width: "100%" }}>
                {React.cloneElement(currentPage.component, { Options: Options, setOptions: setOptions, setTweetList:setTweetList, TweetList:TweetList })}
              </div>
              <div className="d-flex flex-row pt-3 mt-1 " style={{ height: "11%", width: "100%", direction: "rtl",borderTop:"1px solid silver" }}>
                {currentPageIndex < pages.length - 1 && <button style={nextStyle} onClick={handleNext}>Next</button>} 
                {currentPageIndex > 0 && <button style={prevStyle} onClick={handlePrev}>Previous</button>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const prevStyle = {
  background: "#F7FAFC",
  borderWidth: "0px",
  color: "#333333",
  borderRadius: "4px",
  fontSize: "14px",
  fontWeight: "600",
  padding: "12px 18px",
  height: "50px",
  border: "1px solid #EEEEEE",
  marginRight: "1rem",
};

const nextStyle = {
  background: "#1EAAE7",
  borderWidth: "0px",
  height: "50px",
  color: "#fff",
  borderRadius: "4px",
  fontSize: "14px",
  fontWeight: "600",
  padding: "12px 18px",
};

export default Wizard;
