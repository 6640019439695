import React from "react";
import { Card,  OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import {format} from 'date-fns';
const Confirm = (props) => {

  console.log(props.TweetList)
  console.log(props.Options)
  const OptionsArray = [
    {Title:"Operation",Value:props.Options.name,Icon:"fa-light fa-cogs"},
    {Title:"Hashtag",Value:props.Options.Hashtagh,Icon:"fa-light fa-hashtag"},
    {Title:"Start",Value:format(new Date(props.Options.startDate), 'dd/MM/yyyy HH:mm'),Icon:"fa-light fa-chess-clock"},
    {Title:"Finish",Value:format(new Date(props.Options.endDate), 'dd/MM/yyyy HH:mm'),Icon:"fa-light fa-chess-clock-flip"},
    {Title:"Active",Value:props.Options.isActive,Icon:"fa-light fa-check"},
    {Title:"Combine",Value:props.Options.combineBotOrder,Icon:"fa-light fa-merge"},
    {Title:"Tweets",Value:props.TweetList.length,Icon:"fa-brands fa-twitter"},
    
  ]

  
  
  return (
    <div className="d-flex flex-column">
        <Row className="d-flex  flex-row  justify-content-around px-4">
          {OptionsArray.map(item => {
            return(
              <Card className="border " style={{ width: '11rem',height:"10rem",overflow:"hidden" }}>
            <Card.Body className="d-flex flex-column  align-items-center p-3 pr-1 pl-1">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <i className={item.Icon + " mb-2 "} style={{fontSize:"2rem"}}></i>
                <h4 className="font-weight-bold">
                  {item.Title}
                </h4>  
              </div>
              {typeof(item.Value) == "boolean" ?
               <>
               {item.Value ? <i className="fa-solid fa-circle-check " style={{color:"#09ac0d",fontSize:"1.8rem"}}></i>:<i className="fa-solid fa-circle-xmark" style={{color:"rgb(200,0,50)",fontSize:"1.8rem"}}></i>}
               </> : 
              <OverlayTrigger  placement="bottom" delay={{ show: 500, hide: 100 }}overlay={<Tooltip id="button-tooltip">{item.Value}</Tooltip>}>
                <h5 className="text-center w-100" style={{overflow:"hidden"}}>
                  {item.Value}
                </h5>
              </OverlayTrigger>}
            </Card.Body>
          </Card>
            )
          })}
        </Row>
      {/* <div className="d-flex justify-content-center">
        <Button size="sm" variant="danger">
          <span>Cancel</span>
          <i className="fa fa-xmark"></i>
        </Button>
        <Button size="sm">
          <span>Send</span>
          <i className="fa fa-check"></i>
        </Button>
      </div> */}
    </div>
  );
};

export default Confirm;
