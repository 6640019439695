import React, { useEffect, useState } from 'react';
import GradientArea from '../../charts/Chartjs/gradinetArea';
import LineChart from './Chart';


var Charts = {
    Tweets: {
        CountTweets: [],
        CountTextTweets: [],
        CountImageTweets: [],
        CountVideoTweets: []
    },
    Comments: {
        CountComments: [],
        CountTextComments: [],
        CountImageComments: [],
        CountVideoComments: [],
        CountRetweetWithComments: []
    },
    Covers: {
        CountCoverHashtags: [],
        CountLikes: [],
        CountRetweets: [],
        CountLikeFromDirectOrder: [],
        CountRetweetFromDirectOrder: [],
        CountLikeFromCoverHashTag: [],
        CountRetweetFromCoverHashTag: []
    },
    Follows: {
        CountFollows: [],
        CountUnFollows: [],
        CountFollowBack: [],
        CountFollowFromInfluencerPage: [],
        CountFollowFromCoverHashTag: []
    },
    Others: {
        CountDirectMasseges: [],
        CountVoites: [],
        CountPulls: []
    },
    Auto: {
        CountCheckTimeLinePage: [],
        CountCheckInfluencerPage: [],
        CountCollectTweetResults: [],
        CountCheckProfilePage: [],
        CountCoverInfluencer: [],
        CountCheckNotifyPage: []
    },
    Reports: {
        CountReport: [],
        CountReportTweet: [],
        CountReportTwitterAccount: [],
        CountReportHashTag: []
    }
};

const dataMapping = {
    Tweets: ['CountTweets', 'CountTextTweets', 'CountImageTweets', 'CountVideoTweets'],
    Comments: ['CountComments', 'CountTextComments', 'CountImageComments', 'CountVideoComments', 'CountRetweetWithComments'],
    Covers: ['CountCoverHashtags', 'CountLikes', 'CountRetweets', 'CountLikeFromDirectOrder', 'CountRetweetFromDirectOrder', 'CountLikeFromCoverHashTag', 'CountRetweetFromCoverHashTag'],
    Follows: ['CountFollows', 'CountUnFollows', 'CountFollowBack', 'CountFollowFromInfluencerPage', 'CountFollowFromCoverHashTag'],
    Others: ['CountDirectMasseges', 'CountVoites', 'CountPulls'],
    Auto: ['CountCheckTimeLinePage', 'CountCheckInfluencerPage', 'CountCollectTweetResults', 'CountCheckProfilePage', 'CountCoverInfluencer', 'CountCheckNotifyPage'],
    Reports: ['CountReport', 'CountReportTweet', 'CountReportTwitterAccount', 'CountReportHashTag']
};


export default function ChartContainer({ChartData,ChartOptions}) {
	const [Chartinfo, setChartinfo] = useState(Object.entries(Charts["Tweets"]));
    
    
    const ChartChange = (chartType) => 
    {
        setChartinfo(Object.entries(Charts[chartType]));
    }
    useEffect(() => {
        ChartData.sort((a, b) => a.Day - b.Day);
        ChartData.forEach(data => {
            for (const category in Charts) {
                if (Charts.hasOwnProperty(category)) {
                    const properties = dataMapping[category];
                    properties.forEach(prop => {
                        Charts[category][prop].push(data[prop] || 0); // Use 0 if the property does not exist in data
                    });
                }
            }
        });
    }, [ChartData])
    
    return(
        <>
        {Object.entries(Charts).map(([key, value]) => (
            <div className="row" key={key}>
                <div className="card border" style={{ width: "100%", maxHeight: "18rem" }}>
                    <div className="card-body">
                        <div className="row justify-content-between">
                            <h5 className="text-black font-w600">{key} Reports</h5>
                            <select
                                onChange={(event) => ChartChange(event.target.value)}
                                className="form-select"
                                id="PerformanceSelectMenu"
                                aria-label="Default select example"
                            >
                                {Object.entries(value).map(([subKey, subValue]) => (
                                    <option value={subKey} key={subKey}>
                                        {subKey}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {/* Ensure that ChartOptions and Chartinfo are defined correctly */}
                        <LineChart ChartOptions={ChartOptions} ChartData={Object.entries(Charts[key])} />
                    </div>
                </div>
            </div>
        ))}
    </>
    )
    
}


// {Object.entries(Charts).map((Chart,index)=>{
//             return (
//                 <div className='card border' style={{width:"100%" }}>
//                     <div className='card-header'> 
//                         <h6>{Chart[0]}</h6>
//                     </div>
//                     <div className='card-body p0' >
//                         <LineChart ChartOptions={ChartOptions} ChartData={Chart} /> 
//                     </div>
//                 </div>
//             )
//         })}