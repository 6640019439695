import { saveToLocalStorage } from '../../jsx/components/Elements/Functions';
import {
    formatError,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
} from '../../services/AuthService';
import { axiosLogin } from '../../services/Connections';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';

export function signupAction(email, password, history) {
    return (dispatch) => {
        signUp(email, password)
        .then((response) => {
            saveTokenInLocalStorage(response.data);
            runLogoutTimer(
                dispatch,
                response.data.expiresIn * 1000,
                history,
                "from signupAction"
            );
            dispatch(confirmedSignupAction(response.data));
            history.push('/');
        })
        .catch((error) => {
            const errorMessage = formatError(error.response.data);
            dispatch(signupFailedAction(errorMessage));
        });
    };
}

export function logout(history,message) {
    if (message)
        console.log(message)
    localStorage.removeItem('userDetails');
    history.push('/login');
    return {
        type: LOGOUT_ACTION,
    };
}


export function loginAction(Url, Data, Head, history,setloader,setError) {
    return (dispatch) => {
        axiosLogin(Url,Data,Head)
            .then((response) => {
                setloader(false)
                setError(false)
                saveTokenInLocalStorage(response.data);
                saveToLocalStorage("AccountEmail",Data.Email)
                dispatch(loginConfirmedAction(response.access_token));
				history.push('/dashboard');
                runLogoutTimer(
                    dispatch,
                    100000 * 1000000,
                    history,
                    "from loginAction"
                );
				// window.location.reload();
				// history.pushState('/index');
                
                return false
            }).catch(function (error) {
                setloader(false);
                setError(true)
                return false
              });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
