import { useEffect,useState } from "react";
import { Card, Col, Nav, Tab } from "react-bootstrap";
import { AxiosGetRequest } from "../../../services/Connections";
import { Urls } from "../../../services/ServerandUrls";
import { ToastError } from "../../../services/Toasts";
import AddAccounts from "./AddAcounts";
import InfluencerList from "./InfluencerList/InfluencerList";
import AcountsList from "./List/AcountsList";
import SocialList from "./SocialList/SocialList";
import HashtagList from "./HashtagList/HashtagList";



export default function AccountsContainer(props) {
	const [Subjectslist, setSubjectslist] = useState([]);
	const [AccountList, setAccountsList] = useState([]);
	const [InfluencersList, setInfluencersList] = useState([]);
	const [HashtagLists, setHashtagLists] = useState([]);
	const [RefreshData, setRefreshData] = useState(false);
	const [AccListRefreshData, setAccListRefreshData] = useState(false);
	const [LoaderACC, setLoaderACC] = useState(true);
	const [LoaderSocial, setLoaderSocial] = useState(true);
	const [LoaderInfluencer, setLoaderInfluencer] = useState(true);
	const [LoaderHashtag, setLoaderHashtag] = useState(true);

    const tabData = [
        {
          name: 'Add Account',
          icon: 'ballot',
          content:<AddAccounts SocialList={Subjectslist} setRefreshData={setAccListRefreshData} RefreshData={AccListRefreshData}/>
        },
        {
          name: 'Accounts List',
          icon: 'list',
          content:<AcountsList  
          Loader={LoaderACC} 
          SocialList={Subjectslist} 
          setLoader={setLoaderACC}  
          AccountList={AccountList} 
          setAccountsList={setAccountsList} 
          setRefreshData={setAccListRefreshData} 
          RefreshData={AccListRefreshData} />
        },
        {
          name: 'Social Subjects List',
          icon: 'list',
          content:<SocialList setRefreshData={setRefreshData} RefreshData={RefreshData} setLoader={setLoaderSocial} Loader={LoaderSocial}  SocialLists={Subjectslist}/>
        },
        {
          name: 'Influencer List',
          icon: 'list',
          content:<InfluencerList setRefreshData={setRefreshData} RefreshData={RefreshData} setLoader={setLoaderInfluencer} Loader={LoaderInfluencer}  List={InfluencersList}/>
        },
        {
          name: 'Hashtag List',
          icon: 'list',
          content:<HashtagList setRefreshData={setRefreshData} RefreshData={RefreshData} setLoader={setLoaderHashtag} Loader={LoaderHashtag}  List={HashtagLists}/>
        },
        
      ]
      useEffect(() => {
        AxiosGetRequest(Urls.SocialAreas.view).then(response => {
          setLoaderSocial(false);
          if(response[0])
          {
            var List = []
            for (let i = 0; i < response[1].data.length; i++) {
               List.push({"value":response[1].data[i].id , "label":response[1].data[i].title})
            }
            localStorage.setItem("SocialList" , JSON.stringify(List))
            setSubjectslist(List)
          }
          else
            ToastError("Subjects List didnt load")
        })
        
        AxiosGetRequest(Urls.Inlfuencers.view).then(response => {
          setLoaderInfluencer(false);
          if(response[0])
          {
            setInfluencersList(response[1].data)
          }
          else
            ToastError("Influencers List didnt load")
        })
        AxiosGetRequest(Urls.Hashtags.view).then(response => {
          setLoaderHashtag(false);
          if(response[0])
          {
            setHashtagLists(response[1].data)
          }
          else
            ToastError("Hashtags List didnt load")
        })
      }, [RefreshData])

      useEffect(() => {
        setLoaderACC(true);
        AxiosGetRequest(Urls.Acounts.view).then(response => {
          setLoaderACC(false);
          if(response[0])
          {
            var List = []
            for (let i = 0; i < response[1].data.length; i++) {
               List.push({"value":response[1].data[i].id , "label":response[1].data[i].userName})
            }
            localStorage.setItem("AccountsList" , JSON.stringify(List))
            setAccountsList(response[1].data)
            
          }
          else
            ToastError("Acounts List didnt load")
        })

      }, [AccListRefreshData])
      
    return(
        <Col xl={12}>
          <Card>
            <Card.Body>
              <div className='default-tab'>
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                  <Nav as='ul' className='nav-tabs'>
                    {tabData.map((data, i) => (
                      <Nav.Item as='li' key={i}>
                        <Nav.Link eventKey={data.name.toLowerCase()}>
                          <i className={`fa-light fa-${data.icon} mr-2`} />
                          {data.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content className='pt-4'>
                    {tabData.map((data, i) => (
                      <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                        {data.content}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Card.Body>
          </Card>
        </Col>
    )
}