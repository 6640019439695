import { Card, Col, Nav, Tab } from "react-bootstrap";
import OPAddForm from "./OPAddForm";
import OPList from "./FilteringTable/OperationList";
import { useEffect, useState } from "react";
import { AxiosGetRequest } from "../../../../services/Connections";
import { Urls } from "../../../../services/ServerandUrls";
import { ToastError } from "../../../../services/Toasts";
import OperationDetails from "./OperationDetail";
import OperationReport from "./OperationReport/OperationReport";



export default function Operations(props) {
  
	const [OperationList, setOperationList] = useState([]);
	const [RefreshData, setRefreshData] = useState(false);
	const [reportLoader, setLoader] = useState(true);

    const tabData = [
        {
          name: 'Add Operation',
          icon: 'plus',
          content:
          <OPAddForm setRefreshData={setRefreshData} RefreshData={RefreshData}/>
        },
        {
          name: 'Operation List',
          icon: 'list',
          content:
            <OPList  setRefreshData={setRefreshData} RefreshData={RefreshData} setLoader={setLoader} reportLoader={reportLoader}  OperationList={OperationList}/>
        },
        {
          name: 'Operation Details',
          icon: 'list',
          content:
            <OperationDetails OperationList={OperationList}/>
        },
        {
          name: 'Operation Report',
          icon: 'list',
          content:
            <OperationReport  setRefreshData={setRefreshData} RefreshData={RefreshData} setLoader={setLoader} reportLoader={reportLoader}  OperationList={OperationList}/>
        },
        
      ]


      useEffect(() => {
        AxiosGetRequest(Urls.Operation.view,[{"HeadKey":"pageSize","HeadValue":500}]).then(response => {
          setLoader(false);
          if(response[0])
          {
            setOperationList(response[1].data)
          }
          else
            ToastError("Operation List didnt loaded")
        })
        AxiosGetRequest(Urls.Operation.Active).then(response => 
          {
            var List = []
            for (let i = 0; i < response[1].data.length; i++) 
            {
              List.push({"value":response[1].data[i].id , "label":response[1].data[i].name})
            }
            localStorage.setItem("OperationList" , JSON.stringify(List))
          })
      }, [RefreshData])
      

    return(
        <Col xl={12}>
          <Card>
            <Card.Body>
              <div className='default-tab'>
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                  <Nav as='ul' className='nav-tabs'>
                    {tabData.map((data, i) => (
                      <Nav.Item as='li' key={i}>
                        <Nav.Link eventKey={data.name.toLowerCase()}>
                          <i className={`fa fa-${data.icon} mr-2`} />
                          {data.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content className='pt-4'>
                    {tabData.map((data, i) => (
                      <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                        
                        {data.content}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Card.Body>
          </Card>
        </Col>
    )
}