import {format} from 'date-fns';
import { ColumnFilter } from '../../FilteringTable/ColumnFilter';
export const COLUMNS = [
	{
		Header : 'Reply Text ',
		Footer : 'Reply Text',
		accessor: 'first_name',
		Filter: ColumnFilter,
	},
	{
		Header : 'Created Date ',
		Footer : 'Created Date ',
		accessor: 'createdDate',
		Cell: ({ value }) => {return format(new Date(value), 'dd/MM/yyyy hh:mm a')},
		Filter: ColumnFilter,
	},
	{
		Header : 'Expire Date ',
		Footer : 'Expire Date ',
		accessor: 'expiryDate',
		Cell: ({ value }) => {return format(new Date(value), 'dd/MM/yyyy hh:mm a')},
		Filter: ColumnFilter,
	},
	{
		Header : ' Tweet Type',
		Footer : 'Tweet Type',
		accessor: 'robotAccountTweetType',
		Filter: ColumnFilter,
	},
	{
		Header : 'Operation Name',
		Footer : 'Operation Name',
		accessor: 'operationName',
		Filter: ColumnFilter,
	}
]