import { ColumnFilter } from '../../FilteringTable/ColumnFilter';

export const COLUMNS = [

	{
		Header : 'Influencer Username',
		Footer : 'Influencer Username',
		accessor: 'userName',
		Filter: ColumnFilter,
	},
	{
		Header : 'Social Subject',
		Footer : 'Social Subject',
		accessor: 'socialAreaTitle',
		Filter: ColumnFilter,
	},
]
