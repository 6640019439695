import { useState } from "react";
import { Button, Card, Col, Row, } from "react-bootstrap";
import Select from "react-select";
import TimeRangePicker from "@wojtekmaj/react-timerange-picker/dist/TimeRangePicker";
import { ChangeHandler } from "../../Elements/Functions";
 

export default function StylesForm (props) {
  const [selectedOption, setSelectedOption] = useState(false);
  const [TimeValue, setTimeValue] = useState(["00:00", "00:00"]);
  const [Options, setOptions] = useState(
    {
      Title: [],
      Max:"",
      Frequency:"",
      TimeRange:["00:00", "00:00"]
    }
  );
    
    
      const DropDownOptions = 
      [
        { value: '1', label: 'Daily' },
        { value: '2', label: 'Monthly', },
        { value: '3', label: 'Yearly' },
      ]



    return (
        <>
        <div className=" mobile-send-button">
          <Button >Send </Button>
        </div>
        <Card>
        <div className="d-flex flex-row justify-content-between">
          <h4>Contents</h4>
        </div>
                <Row className="w-100 ml-0 pt-3 mt-4"  style={{borderTop:"1px solid #dbdbdb"}}>
                    <Col className="d-flex align-items-center"   xl={2}>
                    <div className=' form-check'>
                        <label htmlFor="Title" className='form-check-label'>
                        Title
                        </label>
                    </div>
                    </Col>
                    <Col xl={4}>
                        <div className='form-group'>
                            <input
                                    type='text'
                                    className='form-control input-default p-2 mt-3 text-black'
                                    placeholder="Title"
                                    id="Title"
                                    style={{height:"2.4rem"}}
                                    onChange={(e) => ChangeHandler('Title',e.target.value,setOptions,Options)}
                                />
                        </div>
                    </Col>
                    <Col className="d-flex align-items-center"   xl={2}>
                        <div className=' form-check'>
                            <label htmlFor="Kind" className='form-check-label'>
                            Kind
                            </label>
                        </div>
                    </Col>
                    <Col xl={4}>
                        <div className='form-group'>
                            <Select className="mt-3" id="Kind" isClearable isSearchable defaultValue={selectedOption} onChange={(e) => ChangeHandler('Kind',e !=null ? e.value:null,setOptions,Options)}  options={DropDownOptions}/>
                        </div>
                    </Col>
                </Row>
                <Row className="w-100 ml-0 pt-3 mt-4"  style={{borderTop:"1px solid #dbdbdb"}}>
                    <Col className="d-flex align-items-center"   xl={2}>
                    <div className=' form-check'>
                        <label htmlFor="Subject" className='form-check-label'>
                        Time Range:
                        </label>
                    </div>
                    </Col>
                    <Col xl={4}>
                    <div className='form-group' style={{marginTop:"2rem"}}>
                    <TimeRangePicker className="pb-1" clockIcon={null} disableClock={true} clockClassName={" position-relative "} format={"HH:mm"} onChange={setTimeValue} value={TimeValue} />
                    </div>
                    </Col>
                    <Col xl={5}>
                        <Row>
                        <Col>
                            <div className='form-group'>
                            <label htmlFor="Max"><i className="fa fa-dial-max mr-1"></i>Max:</label>
                            <input
                                type='number'
                                className='form-control input-default p-2  text-black'
                                placeholder="Max"
                                id="Max"
                                style={{height:"2.4rem"}}
                                onChange={(e) => ChangeHandler('Max',e.target.value,setOptions,Options)}
                            />
                            </div>
                        </Col>
                        <Col>
                        <div className='form-group'>
                        <label htmlFor="Frequency"><i className="fa fa-wave-square mr-1"></i>Frequency:</label>
                        <input
                            type='number'
                            className='form-control input-default p-2  text-black'
                            placeholder="Frequency"
                            id="Frequency"
                            style={{height:"2.4rem"}}
                            onChange={(e) => ChangeHandler('Frequency',e.target.value,setOptions,Options)}
                        />
                        </div>
                        </Col>
                        </Row>
                    </Col>
                </Row>
                
        </Card>
       <Row className="flex-row-reverse" style={{gap:"0.6rem"}}>
          <Button>Send </Button>
          <Button variant='outline-primary '>Clear </Button>
       </Row>
        </>
    )
}