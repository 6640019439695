import { useRef } from "react";
import { useEffect, useState } from "react";
import { Button, Card, Col, ListGroup, OverlayTrigger, Row } from "react-bootstrap";
import Select from "react-select";
import { AxiosPostRequest } from "../../../../../services/Connections";
import { Urls } from "../../../../../services/ServerandUrls";
import { ToasSucces, ToastError } from "../../../../../services/Toasts";
import { AccountColumn, GenderColumn, OperationColumn, RenderTooltip, SubjectColumn } from "../../../Elements/Elements";
import { ChangeHandler, read_clipboard } from "../../../Elements/Functions";



export default function VoteForm (props) {
    
  const [SocialList, setSocialList] = useState([])
  const [OperationList, setOperationList] = useState([])
  const [AccountList1, setAccountList1] = useState([])
  const MaleCheckRef = useRef(null)
  const FemaleCheckRef = useRef(null)
	const [Loader, setLoader] = useState(false);
  const [Options, setOptions] = useState(
    {
      targetLink:"",
      operationId:"",
      socialAreas:[],
      privateRobotAccountId:"",
      genderType:"FemaleMale",
      optionLocation: 0,
      countSupport:0
    }
  )
 
  

      const Answer = 
      [
        { value: '1', label: 'Option 1' },
        { value: '2', label: 'Option 2', },
        { value: '3', label: 'Option 3' },
        { value: '4', label: 'Option 4' },
      ]
      

      const sendVote = () => {
        console.log(Options)
        AxiosPostRequest(Urls.Votes.add,[Options]).then(response => {
          props.setRefreshData(!props.RefreshData)
          setLoader(false);
          if(response[0])
          {
            ToasSucces("Vote has beeen submitted")
          }
          else
            ToastError("Vote couldn't be send")
        })

      }
      useEffect(() => {
        if(JSON.parse(localStorage.getItem("SocialList")) !== null )
            setSocialList(JSON.parse(localStorage.getItem("SocialList")))
        
          if(JSON.parse(localStorage.getItem("OperationList")) !== null )
            setOperationList(JSON.parse(localStorage.getItem("OperationList")))
  
            if(JSON.parse(localStorage.getItem("AccountsList")) !== null )
            setAccountList1(JSON.parse(localStorage.getItem("AccountsList")))
      }, [])

    return(
        <>
        <div className=" mobile-send-button">
        <Button onClick={()=>sendVote()}>Send </Button>
        </div>
        <Card>
            <ListGroup >
                <div className="d-flex flex-row justify-content-between">
                    <h4>Vote form</h4>
                </div>
                <div className='input-group  mb-3 input-primary ' >
                      <div className='input-group-prepend'>
                        <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 100 }}overlay={RenderTooltip(1,"Paste from Clipboard")}>
                          <span onClick={() =>read_clipboard('targetLink',setOptions,Options)} className='input-group-text c-pointer'><i className="fa-thin fa-paste"></i></span>
                        </OverlayTrigger>
                      </div>
                      <input type='text' className='form-control input-default text-black' placeholder="targetLink" value={Options.targetLink}onChange={(e) => ChangeHandler('targetLink',e !=null ? e.target.value:null,setOptions,Options)}/>
                </div>
            </ListGroup>
                <Row className="w-100 ml-0 pt-3 mt-4"  style={{borderTop:"1px solid #dbdbdb"}} >
                  <Col   xl={4}>
                  <div className='form-group'>
                    <label  htmlFor="Answer"> <i className="fa fa-clipboard-list-check mr-1"></i> Answer Number :</label>
                    <Select id={"Answer"}  onChange={(e) => ChangeHandler('optionLocation',e !=null ? e.value:null,setOptions,Options)} options={Answer}/>
                  </div>
                  </Col>
                  <Col xl={4}>
                  <div className='form-group'>
                    <label  htmlFor="MaxVal" ><i className="fa fa-user mr-1"></i>Users Count:</label>
                    <input
                      type='number'
                      className='form-control input-default p-2  text-black'
                      id="MaxVal"
                      onChange={(e) => ChangeHandler('countSupport',e !=null ? e.target.value:null,setOptions,Options)}
                      placeholder="Participated Users "
                      style={{height:"2.4rem"}}/>
                  </div>
                  </Col>
                  <GenderColumn customID={2} CustomClass={"mt-2"} Options={Options} setOptions={setOptions}  MaleRef={MaleCheckRef} FemaleRef={FemaleCheckRef} />
                </Row>
                <Row className="w-100 ml-0 pt-3 mt-4 pb-3"  style={{borderTop:"1px solid #dbdbdb"}} >
                  <OperationColumn OperationList={OperationList} Options={Options} setOptions={setOptions}/>
                  <SubjectColumn SocialList={SocialList} Options={Options} setOptions={setOptions}/>
                  <AccountColumn AccountList={AccountList1} Options={Options} setOptions={setOptions}/>
                </Row>

        </Card>
       <Row className="flex-row-reverse" style={{gap:"0.6rem"}}>
          <Button onClick={()=>sendVote()}>Send </Button>
          <Button variant='outline-primary '>Clear </Button>
       </Row>
       </>
    )
}