import { Tooltip } from "react-bootstrap";

export const NumberDetect = (NumbeR) => {
    var Card_Round = NumbeR/1000;

	if(Card_Round >= 1 && Card_Round<1000){
		if(NumbeR%1000>=100)
		return (Card_Round).toFixed(1) + "K";
		else
		return Math.floor(Card_Round) + "K";
	}
	else if(Card_Round >=1000){
		if(NumbeR%1000000 > 100000)
		return (Card_Round/1000).toFixed(2) + "M";
		else
		return Math.floor(Card_Round/1000) + "M";
	}
    else
    return NumbeR;

}


export const PreventNegative = (e) => {
	if (e.code === 'Minus') {
		e.preventDefault();
	}
  }

