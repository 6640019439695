import React,{Fragment, useState , useEffect,useRef, createRef} from 'react';
import { Carousel, Modal} from "react-bootstrap";

import  "./../../../css/Dashboard/Main.css";
import { ToastError } from '../../../services/Toasts';
import GradientArea from '../charts/Chartjs/gradinetArea';
import { AxiosGetRequest } from '../../../services/Connections';
import { Urls } from '../../../services/ServerandUrls';
import { NumberDetect } from '../../../services/MathMatics';
import { TweetCard } from '../Elements/Elements';

import CircleLoader from './CircleLoader';
import MockData from "./MockData.json"
import RadarChart from '../charts/Chartjs/radar';
import PerformanceCard from './PerformanceCard';

//Images
import profile from './../../../images/avatar/1.jpg';
import Wave1 from './../../../images/SVG/Wave.svg';
import Wave2 from './../../../images/SVG/Wave2.svg';
import ActionListContainer from './ActionListContainer';
import { getFromLocalStorage } from '../Elements/Functions';


import noIMG from "../../../images/avatar/NotFound.png";
import ChartContainer from './Charts/ChartContainer';



const Home = (props) => {


  const [count, setCount] = useState(0);
	const [CardVal1, setCardVal1] = useState("statics");
	const [TimeLineVal, setTimeLineVal] = useState("All");
	const [CircleColor, setCircleColor] = useState("navy");
  const lottieRef = useRef();
	// const [PerformanceData, setPerformanceData] = useState(MockData.PerformanceData);
	const [ReportData, setReportData] = useState({});
	const [TopTweets, setTopTweets] = useState(MockData.TopTweets);
	const [RadarData, setRadarData] = useState([]);
	const [LineChartsData, setLineChartsData] = useState([]);
	const [ActionNumbers, setActionNumbers] = useState([0,0,0]);
	const [AcountDetails, setAcountDetails] = useState({countFollowers:0,countFollowing:0,accountEmail:"user@user.com"});
  const [show, setShow] = useState(false);
  const reportContainer = useRef(null);
	const [reportLoader, setLoader] = useState(false);
  const [ActionList, setActionList] = useState({
    Data:[],
    Condition:false,
    Init:false,
    Kind:""
  });


  const handleClose = () => setShow(false);

  const handleShow = (Data) => {
    setRadarData(Data)
    setShow(true)
    
  };


	const [distributionData, setdistributionData] = useState(MockData.distributionData);
  const [ChartOptions, setChartOptions] = useState({
    Data:[0,0,0,0,0,0,0,0,0,0,0,0],
    Max:100,
    stepSize:25,
    Label:"Analytics",
    plugins:{
      legend: false,
    },
  })

const ChartChange = (Val) => {
  var List = {
    Data:[0,0,0,0,0,0,0,0,0,0,0,0],
    Max:100,
    stepSize:25,
    Label:Val,
    plugins:{
      legend: false,
    },
  }
  for (let i = 0; i < ReportData.monthsOfThisYearPerformance.length; i++) 
  {
    List.Data[ReportData.monthsOfThisYearPerformance[i].month-1] = ReportData.monthsOfThisYearPerformance[i][Val];
  }
  if (Math.max(...List.Data) > 0) 
  {
  List.Max = Math.max(...List.Data);
  List.stepSize = Math.floor(List.Max/4);
  }
  else
  List.Data = [0,0,0,0,0,0,0,0,0,0,0,0];
  setChartOptions(List)
}

const performanceCardNumberChange = (Val) =>{
	var ProfileVisit = document.getElementById("PerformanceProfileVisit");
	var Impression = document.getElementById("PerformanceImpression");
	var Engagement = document.getElementById("PerformanceEngagement");
  if(Object.keys(ReportData).length > 0)
  {
    ProfileVisit.innerText = NumberDetect(ReportData[Val].profileVisits) ;
    Impression.innerText = NumberDetect(ReportData[Val].impressions);
    Engagement.innerText = NumberDetect(ReportData[Val].engagements);
  }
}

const ChangePerformanceCard = (Val) => {
  var cards = document.getElementsByClassName("Performance_Card");
  for (let i = 0; i < cards.length; i++) 
  {
    cards[i].classList.remove("FadeoutCard");
  
    setTimeout(() => 
    {	
    cards[i].classList.add("FadeoutCard");
    },(150*i))
  
  
    setTimeout(() => {cards[i].classList.add("FadeInCard")},(550))
    cards[i].classList.remove("FadeInCard");
  
  }
  setTimeout(() => 
  {
    performanceCardNumberChange(Val)
  },(500))

}

const ChangeCard = (Color,kind) => {  

  if(distributionData.firstchange == undefined)
  {
  setCardVal1(NumberDetect(distributionData[kind]));
  setCircleColor(Color)
  }
  else
  {
    ToastError("Please Select a time period",0)
  }
}


const ReportCards = (Value,isSecondTime) => {
let List = 
{
    Data:[],
    Condition:false,
    Init:true,
    Kind:""
};

if(isSecondTime == undefined)
{
  setActionList(List);
  setLoader(true);
}

setTimeLineVal(Value)
AxiosGetRequest(Urls.Reports[Value]).then(Res => 
  {
    setLoader(false);
    if(Res[0])
    {
      if(Res[1].data.orderReports.length>0)
      { 

        List.Data = Res[1].data.orderReports.reverse();
        for (let i = 0; i < List.Data.length; i++) 
        {
          List.Data[i].nodeRef = createRef(null)
        }

      }
      else{
        List.Condition = true;
      }
      setActionNumbers([Res[1].data.countLastHour,Res[1].data.countThisDay,Res[1].data.countThisMonth])
      setActionList(List)
    }
    else
    {
      ToastError("Report Data couldn't be Loaded")
    }
  })
}

  useEffect(() => {

    AxiosGetRequest(Urls.Reports.RobotAccountActivitys.week).then(response => {
      if(response[0])
      {
        setLineChartsData(response[1].data)
      }
    })

    AxiosGetRequest(Urls.Tweet.TopTweets).then(response => {
      if(response[0])
      {
        if(response[1].data.length > 0)
        setTopTweets(response[1].data);
      }
      else
        ToastError("Top Tweets didnt loaded")
    })
    AxiosGetRequest(Urls.Operation.Active).then(response => 
      {
        var List = []
        for (let i = 0; i < response[1].data.length; i++) 
        {
          List.push({"value":response[1].data[i].id , "label":response[1].data[i].name})
        }
        localStorage.setItem("OperationList" , JSON.stringify(List))
    })
    AxiosGetRequest(Urls.Performance.AllInOne).then(response => 
    {
      if(response[0])
      setReportData(response[1].data)
    }) 
    AxiosGetRequest(Urls.Reports.AccountActivitys).then(response => 
      {
      if(response[0])
      {
        if(response[0])
        {
          setAcountDetails((prevDetails) => ({
            ...prevDetails, 
            countFollowers: response[1].data.countFollowers, 
            countFollowing: response[1].data.countFollowing, 
            accountEmail:getFromLocalStorage("AccountEmail")
          }));
        };

      }
        
      }) 
    ReportCards("All")
    
  }, [])

  useEffect(() => {
  ChangePerformanceCard("thisMonthPerformance")

  }, [ReportData])

  useEffect(() => {
    
    const interval = setInterval(() => 
      {
        ReportCards(TimeLineVal,true)
        
      }, 15000);

    return () => {
      clearInterval(interval);
    }
  }, [ActionList])



  

  
 
	return(
		<>
			<div className="row">   
        <div className="col-xl-7 ">
          <div className="form-head mb-4">
            <h3 className="text-black font-w600 mb-0">
            <div className="d-flex">
              <select defaultValue={"3"} onChange={(event) => ChangePerformanceCard(event.target.value)} style={{marginTop:"-5px"}}  className="form-select DaysSelectPerformance" id="PerformanceSelectMenu" aria-label="Default select example">
                <option value="thisMonthPerformance" >30 Days</option>
                <option value="last90DayPerformance">90 Days </option>
                <option value="last180DayPerformance">180 Days</option>
                <option value="last365DayPerformance">1 year</option>
              </select>
              <h3 className="text-black font-w600 mb-0" > Performance</h3>
            </div>
            </h3>
          </div>
        <div className="row">
          <PerformanceCard CardName={"Profile Visit"} TextID={"PerformanceProfileVisit"}/>
          <PerformanceCard CardName={"Impression"} TextID={"PerformanceImpression"} />
          <PerformanceCard CardName={"Engagement"}  TextID={"PerformanceEngagement"}/>
        </div>
    <div className="row pr-3">
      <div className="col-xl-12 ">
        <div className="form-head mb-4">
          <h4 className="text-black font-w600 mb-0">Analytics</h4>
        </div>
        <div className="row">
          <div className="card border" style={{ flex: 2,maxHeight:"15rem" }} >
            <div className="card-body">
              <div className="row justify-content-between">
                <h5 className="text-black font-w600 ">Yearly View</h5>
                <select onChange={(event) => ChartChange(event.target.value)} className="form-select "   id="PerformanceSelectMenu" aria-label="Default select example">
                  <option  defaultValue={true} hidden>select</option>
                  <option value="profileVisits" >Profile Visit</option>
                  <option value="impressions">Impression </option>
                  <option value="engagements">Engagement</option>
                  <option value="getLikes">Likes</option>
                  <option value="tweets">Tweets</option>
                </select>
              </div>
              <GradientArea ChartOptions={ChartOptions} />
            </div>
          </div>
        </div>
          <ChartContainer ChartData={LineChartsData} ChartOptions={ChartOptions} /> 
        {/* <div className="row Dashboard-operation-cards" style={{ justifyContent: "space-between" }}>
          <div className="card border col-lg-12 position-relative ">
            <div className="position-absolute" style={{ right: "0.7rem", top: "0.7rem", fontSize: "0.7rem" }}>
              <i className="fa fa-pause p-2 text-white c-pointer  rounded-circle " style={{ background: "#0084B4" }}/>
            </div>
            <div className="card-body d-flex flex-column pl-1 pr-6 pt-3 pb-3  justify-content-center align-items-center" style={{ gap: "1.5rem" }}>
              <div className="text-center rounded  d-flex justify-content-center  align-items-center bg-black " style={{ width: "6rem", height: "4.5rem" }}></div>
                <h5 className="text-black fs-12 Distribution-title font-w600 ">
                  Operations
                </h5>
            </div>
          </div>
        </div> */}
      </div>
      {/* <div className="col-xl-5">
        <div className="form-head mb-3">
          <h4 className="text-black font-w600 mb-0 text-center pt-2">
            Events Distribution
          </h4>
        </div>
        <div className="card border " style={{ height: "90%" }}>
          <div className=" card-body  d-flex flex-column ">
          <div className="d-flex justify-content-center">
            <select style={{color:"#0210a5",marginTop: "-0.2rem"}} onChange={(event) => setdistributionData(ReportData[event.target.value])} className="form-select DaysSelectPerformance" id="PerformanceSelectMenu" aria-label="Default select example">
              <option defaultValue={true} hidden >select</option>
              <option value="thisMonthPerformance" >30 Days</option>
              <option value="last90DayPerformance">90 Days </option>
              <option value="last180DayPerformance">180 Days</option>
              <option value="last365DayPerformance">1 year</option>
            </select>
            <h5 className=" font-w600  mb-0 text-center" style={{color:"#0210a5"}}> Performance</h5>
          </div>
            <div style={{ height: "40%", position: "relative", zIndex: 2 }} className=" Cardd-container pb-0 d-flex justify-content-center align-items-center ">
              <CircleLoader outterCircle={CircleColor}/>
              <p className='Event_Text'> {CardVal1}</p>
            </div>
            <div className=" row justify-content-center" style={{zIndex: 2,position: "relative",backgroundColor: "white", flex: 1}}>
              <h5 className="text-black font-w600 mb-0 text-center">
                Events Distribution
              </h5>
              <div className="row justify-content-start  w-100 Distributions-options-container" style={{ gap: 5 }}>
                <div className="col-lg-5 d-flex flex-column p-0 justify-content-around">
                  <div className=" align-items-center c-pointer Distributions" onClick={(el) => ChangeCard("#e5a600","followers")}>
                    <div className="Distributions-icon-container">
                      <div style={{ background: "#e5a600" }}>
                        <i className="fa-light fa-user-plus" />
                      </div>
                    </div>
                    <div style={{ width: "60%" }}>
                      <p onClick={()=>lottieRef.current.setSpeed(0.1)} className="m-0 font-w700  Distribution-title text-nowrap">
                        Followers
                      </p>
                    </div>
                  </div>
                  <div className=" align-items-center c-pointer Distributions" onClick={(el) => ChangeCard("#ed207d","likes")}>
                    <div className="Distributions-icon-container">
                      <div style={{ background: "#ed207d" }}>
                        <i className="fa-brands fa-twitter" />
                      </div>
                    </div>
                    <div style={{ width: "60%" }}>
                      <p className="m-0 font-w700  Distribution-title text-nowrap">
                        Likes
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center c-pointer Distributions" onClick={(el) => ChangeCard("#18d5d1","reports")}>
                    <div className="Distributions-icon-container">
                      <div style={{ background: "#18d5d1" }}>
                        <i className="fa fa-arrow-down-arrow-up" />
                      </div>
                    </div>
                    <div style={{ width: "60%" }}>
                      <p className="m-0 font-w700  Distribution-title text-nowrap">
                        View All
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 d-flex flex-column p-0 justify-content-around">
                  <div className=" align-items-center c-pointer Distributions" onClick={(el) => ChangeCard("#000da4","following")}>
                    <div className="Distributions-icon-container">
                      <div style={{ background: "#000da4" }}>
                        <i className="fa-light fa-user-check" />
                      </div>
                    </div>
                    <div style={{ width: "60%" }}>
                      <p className="m-0 font-w700  Distribution-title text-nowrap">
                        Follower
                      </p>
                    </div>
                  </div>
                  <div className=" align-items-center c-pointer Distributions" onClick={(el) => ChangeCard("#893fb4","retweets")}>
                    <div className="Distributions-icon-container">
                      <div style={{ background: "#893fb4" }}>
                        <i className="fa-light fa-arrows-rotate" />
                      </div>
                    </div>
                    <div style={{ width: "60%" }}>
                      <p className="m-0 font-w700  Distribution-title text-nowrap">
                        Retweet
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center c-pointer Distributions"onClick={(el) => ChangeCard("#32a804","replays")}>
                    <div className="Distributions-icon-container">
                      <div style={{ background: "#32a804" }}>
                        <i className="fa-light fa-users" />
                      </div>
                    </div>
                    <div style={{ width: "60%" }}>
                      <p className="m-0 font-w700 Distribution-title  text-nowrap"style={{ fontSize: "0.7vw" }}>
                        All Followings
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
    {/* <div className="row flex-column">
      <div className="row ">
        <div className="form-head mb-4 ">
          <h3 className="mt-3	text-black text-center font-w600 mb-0"  style={{paddingLeft:"20px"}}>
            Statistics
          </h3>
        </div>
      </div>
      <div className="row ">
        <div className="col-6 ">
          <div
            className="card p-0 d-flex rounded  position-relative Week-card  overflow-hidden"
            style={{ flex: 1 }}
          >
            <div className="p-4 pb-5 card-body">
              <h3 className=" fs-15 font-w800"  onDoubleClick={}>Weekly New Followers</h3>
              <h3 className=" fs-12 mb-0 font-w600">
                <span id="Weekly_Achived">50</span>% Achived
              </h3>
            </div>
            <img alt="" src={Wave1} className="DashboardCardWave" />
          </div>
        </div>
        <div className="col-6 ">
          <div
            className="card p-0  d-flex rounded  position-relative Week-card  overflow-hidden"
            style={{ flex: 1, boxSizing: "content-box" }}
          >
            <div className="p-4 pb-5 card-body">
              <h3 className=" fs-15 font-w800 text-gray">
                Monthly New Followers
              </h3>
              <h3 className=" fs-12 mb-0 font-w600 text-gray">
                <span id="Monthly_Achived">25</span>% Achived
              </h3>
            </div>
            <img alt="" src={Wave2} className="DashboardCardWave" />
          </div>
        </div>
      </div>
    </div> */}
  </div>
  <div className="col-xl-5 d-flex flex-column ">
    <div className="form-head mb-4 TopTweetsHeadder">
      <h3 className="text-black text-center font-w600 mb-0"> Profile</h3>
    </div>
    <div className="row" style={{ flex: 1 }}>
      <div className="card border  ProfileCardContainer" style={{ flex: 1 ,height:"fit-content"}}>
        <div className="card-body pb-1">
          <div
            className="d-flex align-content-center  flex-column"
            style={{ height: "100%" }}
          >
            <div className="d-flex align-content-center justify-content-center">
              <div className="Profile-Card-Pic">
                <img alt='profile' src={noIMG} />
              </div>
            </div>
            <h5 className="text-black font-w700 text-center mt-3 mb-3 "> {AcountDetails.accountEmail}</h5>
            <h5 className="text-black font-w700 text-center"> Moein App</h5>
            <div className="d-flex w-100 align-content-between mt-2 justify-content-around">
              <div className="Profile-Card-statics-container d-flex flex-column">
                <h4 className="font-w700 fs-15 mb-0">{NumberDetect(AcountDetails.countFollowers)}</h4>
                <p className="fs-13 user-select-none">Followers</p>
              </div>
              <div className="Profile-Card-statics-container d-flex flex-column">
                <h4 className="font-w700 fs-15 mb-0">{NumberDetect(AcountDetails.countFollowing)}</h4>
                <p className="fs-13 user-select-none">Followings</p>
              </div>
            </div>
            <div className="d-flex w-100 align-content-between justify-content-center mt-2">
              {/* <div className="d-flex justify-content-center Profile-Card-buttons-container">
                <div  className="Profile-Card-buttons View-Profile-button border d-flex justify-content-center align-items-center c-pointer">
                  <h6 className="m-0 text-white"> View Profile</h6>
                </div>
              </div> */}
              {/* <div className="d-flex justify-content-center Profile-Card-buttons-container">
                <div className="Profile-Card-buttons border d-flex justify-content-center align-items-center c-pointer">
                  <h6 className="m-0"> Edit Profile</h6>
                </div>
              </div> */}
            </div>
            <div className="row mt-3 justify-content-center flex-column"style={{ gap: "0.2rem", flex: 1 }}>
            <h6 className=" text-black font-w600 text-nowrap text-center">Actions timeline</h6>
              <div className="row justify-content-center">
                <div className="card mb-0 border mr-1 ml-1 " style={{ width: "100%", height: "25rem", background: "#f6f6f65e" }}>
                  <div className="card-body p-2 d-flex flex-column">
                    <div className="row justify-content-around">
                    <h6 className=" text-black font-w500 text-nowrap ">
                        LastHour: {ActionNumbers[0]}
                      </h6>
                      <h6 className=" text-black font-w500 text-nowrap ">
                        Today: {ActionNumbers[1]}
                      </h6>
                      <h6 className=" text-black font-w500 text-nowrap">
                        Monthly: {ActionNumbers[2]}
                      </h6>
                      <div className=" d-flex justify-content-center align-content-center align-items-center ">
                        <select
                          className="form-select "
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            marginTop: "-0.3rem"
                          }}
                          id="ActionstimelineSelect"
                          aria-label="Default select example"
                          onChange={(el) => ReportCards(el.target.value)}>
                            <option value={"All"} id={"TweetItem"} defaultValue={true} >
                              All
                            </option>
                            <option value={"Tweets"} id={"TweetItem"}>
                              Tweets
                            </option>
                            <option value="Retweets" id={"RetweetItem"}>
                              Retweets
                            </option>
                            <option value="Likes" id={"likeitem"}>
                              Likes
                            </option>
                            <option value="Follows" id={"FollowItem"}>
                              Follows
                            </option>
                            <option value="AutoFollow" id={"UnfollowItem"}>
                              Auto Follows
                            </option>
                            <option value="AutoTweets" id={"TweetItem"}>
                              Auto Tweets
                            </option>
                            <option value="Comment" id={"RetweetItem"}>
                              Comment
                            </option>
                            <option value="DirectMassege" id={"likeitem"}>
                              Direct Massege
                            </option>
                            <option value="Pulls" id={"FollowItem"}>
                            Pulls
                            </option>
                            <option value="RetweetsWithComment" id={"TweetItem"}>
                              Retweets With Comment
                            </option>
                            <option value="SupportHashtag" id={"RetweetItem"}>
                              Support Hashtag
                            </option>
                            <option value="Unfollows" id={"likeitem"}>
                              Unfollows
                            </option>
                            <option value="Timeline" id={"FollowItem"}>
                              Timeline
                            </option>
                            <option value="Influencer" id={"likeitem"}>
                              Influencer Follows
                            </option>
                            <option value="Votes" id={"RetweetItem"}>
                              Votes
                            </option>
                        </select>
                      </div>
                    </div>
                    <div
                      className="row justify-content-center ListContainerr"
                      style={{ flex: 1, overflow: "hidden" }}>
                        <ActionListContainer count={count} setCount={setCount} ActionList={ActionList} reportContainer={reportContainer} reportLoader={reportLoader} />
                    </div>
                  </div>
                </div>
                <div className="form-head w-100">
                    <h5 className="text-black font-w600 mb- mt-3 pl-3">Top Tweets</h5>
                  </div>
              <div className="w-100" style={{ flex: 1 }}>
                <div
                  id="carouselExampleControls"
                  className="carousel slide d-flex flex-row-reverse h-100"
                  data-interval="false">
                  <div className="carousel-inner pl-2 pr-2" id="CarrouselInner">
                    <Carousel interval={null} indicators={false}>
                          {TopTweets.length > 0 ? (TopTweets.map((Tweet,index) => 
                          <Carousel.Item key={index}>
                            {<TweetCard OpenDetail={handleShow} Tweets={Tweet}/>}
                          </Carousel.Item>
                          )):""}
                    </Carousel>
                  </div>
                </div>
              </div>
              </div>
              {/* <div className="form-head w-100">
                <h5 className="text-black font-w600 mb-0">Top Tweets</h5>
              </div>
              <div className="w-100" style={{ flex: 1 }}>
                <div
                  id="carouselExampleControls"
                  className="carousel slide d-flex flex-row-reverse h-100"
                  data-interval="false">
                  <div className="carousel-inner" id="CarrouselInner">
                    <Carousel interval={null} indicators={false}>
                          {TopTweets.length > 0 ? (TopTweets.map((Tweet,index) => 
                          <Carousel.Item key={index}>
                            {<TweetCard OpenDetail={handleShow} Tweets={Tweet}/>}
                          </Carousel.Item>
                          )):""}
                    </Carousel>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>More Details</Modal.Title>
        </Modal.Header>
        <Modal.Body><RadarChart RadarData={RadarData}/></Modal.Body>
      </Modal>
		</>
	)
}
export default Home;
