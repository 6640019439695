import axios from 'axios';
import swal from "sweetalert";
import {
    loginConfirmedAction,
    logout,
} from '../store/actions/AuthActions';

export function signUp(email, password) {
    //axios call

    const postData = {
        email,
        password,
        returnSecureToken: true,
    };

    return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
        postData,
    );
}

export function login(email, password) {
    var PostBody = new URLSearchParams({
        'UserName': email,
        'Password': password,
        'grant_type': 'password'
    })
    

    return axios.post(
        `http://45.148.145.110/token`,
        PostBody,
    );
}

export function formatError(errorResponse) {
    console.log(errorResponse)
    switch (errorResponse.error.message) {
        case 'EMAIL_EXISTS':
            //return 'Email already exists';
            swal("Oops", "Email already exists", "error");
            break;
        case 'EMAIL_NOT_FOUND':
            //return 'Email not found';
           swal("Oops", "Email not found", "error",{ button: "Try Again!",});
           break;
        case 'INVALID_PASSWORD':
            //return 'Invalid Password';
            swal("Oops", "Invalid Password", "error",{ button: "Try Again!",});
            break;
        case 'USER_DISABLED':
            return 'User Disabled';

        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, history,message) {

    setTimeout(() => {
        console.log(message);
        dispatch(logout(history,"from runLogoutTimer"));
    }, timer);
}

export function checkAutoLogin(dispatch, history) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(logout(history,"from tokenDetailsString"));
        return;
    }
    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date(tokenDetails.expires_in);
    let todaysDate = new Date();
    if (todaysDate > expireDate) {
        dispatch(logout(history,"from todaysDate"));
        return;
    }
    dispatch(loginConfirmedAction(tokenDetails));
    const timer = expireDate.getTime() - todaysDate.getTime();
    
    // runLogoutTimer(dispatch, timer, history);
}
