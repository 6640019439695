import {format} from 'date-fns';
import { ColumnFilter } from '../../FilteringTable/ColumnFilter';
import { ReadTableState } from '../../Elements/Functions';


export const ReportColumns = 
{
	acounts:
    [
        {
            Header : 'Username',
            Footer : 'Username',
            accessor: 'userName',
            Filter: ColumnFilter,
        },
        {
            Header : 'target UserName',
            Footer : 'target UserName',
            accessor: 'targetUserName',
            Filter: ColumnFilter,
        },
        {
            Header : 'Order State',
            Footer : 'Order State',
            accessor: 'orderState',
            Cell: ({ value }) => {return ReadTableState(value)},
            Filter: ColumnFilter,
        },
        {
            Header : 'Operation Name',
            Footer : 'Operation Name',
            accessor: 'operationName',
            Filter: ColumnFilter,
        },
        {
            Header : 'Created Date ',
            Footer : 'Created Date ',
            accessor: 'createdDate',
            Cell: ({ value }) => {return format(new Date(value), 'dd/MM/yyyy hh:mm a')},
            Filter: ColumnFilter,
        },
        {
            Header : 'Expire Date ',
            Footer : 'Expire Date ',
            accessor: 'expiryDate',
            Cell: ({ value }) => {return format(new Date(value), 'dd/MM/yyyy hh:mm a')},
            Filter: ColumnFilter,
        }
    ]
    ,
    tweets:
    [
        {
            Header : 'Username',
            Footer : 'Username',
            accessor: 'userName',
            Filter: ColumnFilter,
        },
        {
            Header : 'Target Username',
            Footer : 'Target Username',
            accessor: 'targetUserName',
            Filter: ColumnFilter,
        },
        {
            Header : 'targetLink',
            Footer : 'target Link',
            accessor: 'targetLink',
            Filter: ColumnFilter,
            Cell: ({ value }) => {return <>
            {value.length > 0 ? <a href={value} target="_blank" rel='noreferrer'> <i className="fa fa-link"></i> </a>:<h6>No Link!</h6>}
            </>},
        },
        {
            Header : 'Order State',
            Footer : 'Order State',
            accessor: 'orderState',
            Cell: ({ value }) => {return ReadTableState(value)},
            Filter: ColumnFilter,
        },
        {
            Header : 'Operation Name',
            Footer : 'Operation Name',
            accessor: 'operationName',
            Filter: ColumnFilter,
        },
        {
            Header : 'Created Date ',
            Footer : 'Created Date ',
            accessor: 'createdDate',
            Cell: ({ value }) => {return format(new Date(value), 'dd/MM/yyyy hh:mm a')},
            Filter: ColumnFilter,
        },
        {
            Header : 'Expire Date ',
            Footer : 'Expire Date ',
            accessor: 'expiryDate',
            Cell: ({ value }) => {return format(new Date(value), 'dd/MM/yyyy hh:mm a')},
            Filter: ColumnFilter,
        }
    ]
    ,
    hashtags:
    [
        {
            Header : 'Username',
            Footer : 'Username',
            accessor: 'userName',
            Filter: ColumnFilter,
        },
        {
            Header : 'Title',
            Footer : 'Title',
            accessor: 'title',
            Filter: ColumnFilter,
        },
        {
            Header : 'Order State',
            Footer : 'Order State',
            accessor: 'orderState',
            Cell: ({ value }) => {return ReadTableState(value)},
            Filter: ColumnFilter,
        },
        {
            Header : 'Operation Name',
            Footer : 'Operation Name',
            accessor: 'operationName',
            Filter: ColumnFilter,
        },
        {
            Header : 'Created Date ',
            Footer : 'Created Date ',
            accessor: 'createdDate',
            Cell: ({ value }) => {return format(new Date(value), 'dd/MM/yyyy hh:mm a')},
            Filter: ColumnFilter,
        },
        {
            Header : 'Expire Date ',
            Footer : 'Expire Date ',
            accessor: 'expiryDate',
            Cell: ({ value }) => {return format(new Date(value), 'dd/MM/yyyy hh:mm a')},
            Filter: ColumnFilter,
        }
    ]
}