import {format} from 'date-fns';
import { ReadTableState } from '../../Elements/Functions';
import { ColumnFilter } from '../../FilteringTable/ColumnFilter';
export const PollsCOLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header : 'Tweet Text ',
		Footer : 'Tweet Text',
		accessor: 'first_name',
		Filter: ColumnFilter,
	},
	{
		Header : ' Accounts Group',
		Footer : 'Selected Accounts Group',
		accessor: 'last_name',
		Filter: ColumnFilter,
	},
	{
		Header : 'Order State ',
		Footer : 'Order State',
		accessor: 'orderState',
		Cell: ({ value }) => {return ReadTableState(value)},
		Filter: ColumnFilter,
	},
	{
		Header : 'Operation Name',
		Footer : 'Combined',
		accessor: 'email',
		Filter: ColumnFilter,
	}

]

export const VotesCOLUMNS = [
	{
		Header : 'User Name',
		Footer : 'User Name',
		accessor: 'userName',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header : 'Target Link ',
		Footer : 'Target Link',
		accessor: 'targetLink',
		Filter: ColumnFilter,
	},
	{
		Header : 'Order State ',
		Footer : 'Order State',
		accessor: 'orderState',
		Cell: ({ value }) => {return ReadTableState(value)},
		Filter: ColumnFilter,
	},
	{
		Header : 'Create Date ',
		Footer : 'Create Date ',
		accessor: 'createdDate', 
		Cell: ({ value }) =>{return value ? format(new Date(value), 'dd/MM/yyyy hh:mm a'):""},
		Filter: ColumnFilter,
	},
	{
		Header : 'Finished Date ',
		Footer : 'Finished Date ',
		accessor: 'finishedDate',
		Cell: ({ value }) =>{return value ? format(new Date(value), 'dd/MM/yyyy hh:mm a'):""},
		Filter: ColumnFilter,
	},
	{
		Header : 'Expire Date ',
		Footer : 'Expire Date ',
		accessor: 'expiryDate',
		Cell: ({ value }) => {return value ? format(new Date(value), 'dd/MM/yyyy hh:mm a'):""},
		Filter: ColumnFilter,
	}
]