import axios from 'axios';
import { HeaderIretate } from '../jsx/components/Elements/Functions';
import { Server } from './ServerandUrls';



export function axiosLogin(Adress,Body,Header) {
    return axios.post(
        Server.ApiServer+Adress,
        Body,
        Header
    );
}

export async function AxiosGetRequest(Adress,HeadersList) {
    // console.log('Server :>> ', Server.ApiServer);
    let token = '';
    token = JSON.parse(localStorage.getItem("userDetails"));
    let HeaderOBJ = {"Authorization":"bearer " + (token.access_token)};
    if(HeadersList)
        HeaderIretate(HeadersList,HeaderOBJ);
    let DataCond = [false,[]]
    await axios.get
    (
        Server.ApiServer+Adress,
        {
            headers:HeaderOBJ,
        }
    ).then(res =>
        {
            // console.log(Adress, res);
            DataCond = [true,res];
        }
    ).catch(function (error) {
        console.log(error.response);
      });
      return DataCond; 
}

export async function AxiosPostRequest(Adress, Body, customHeaders = {}) {
    console.log(Body);
    let token = '';
    token = JSON.parse(localStorage.getItem("userDetails"));
    let DataCond = [false, []];
    
    // Combine default headers with custom headers
    const headers = {
        "Authorization": "bearer " + (token.access_token),
        ...customHeaders // Spread operator to include custom headers
    };

    await axios.post(
        Server.ApiServer + Adress,
        Body,
        { headers: headers } // Use the combined headers
    ).then(res => {
        DataCond = [true, res];
        console.log(res);
    }).catch(function (error) {
        console.log(error.response);
    });

    return DataCond;
}

export async function AxiosDeleteRequest(Adress,Body) {
    let token = '';
    console.log(Body)
    token = JSON.parse(localStorage.getItem("userDetails"));
    let DataCond = [false,[]]
    await axios.delete(
        Server.ApiServer+Adress, { headers: { "Authorization": "bearer " + (token.access_token) } , data: Body }
        // {headers: {  
        //     "Authorization":"bearer " + (token.access_token),
        //   }},
        // {data:Body}
    ).then(res =>
        DataCond = [true,res]
    ).catch(function (error) {
        console.log(error.response);
      });
      return DataCond;
}

export async function AxiosPutRequest(Adress,Body) {
    let token = '';
    console.log(Body)
    token = JSON.parse(localStorage.getItem("userDetails"));
    let DataCond = [false,[]]
    await axios.put(
        Server.ApiServer+Adress,
        Body,
        {headers: {
            "Authorization":"bearer " + (token.access_token),
          }},
    ).then(res =>
        DataCond = [true,res]
    ).catch(function (error) {
        console.log(error.response);
      });
      return DataCond;
}