import { useEffect, useState } from "react";
import { Card, Col, Nav, Tab } from "react-bootstrap";
import { AxiosGetRequest } from "../../../../services/Connections";
import { Urls } from "../../../../services/ServerandUrls";
import { ToastError } from "../../../../services/Toasts";
import TableElement from "../../FilteringTable/TableElment";
import PollForm from "./Polls/PollForm";
import { PollsCOLUMNS, VotesCOLUMNS } from "./Columns";
import VoteForm from "./Votes/VoteForm";


export default function PollsVotes(props) {
  const [Loader, setLoader] = useState(false);
  const [RefreshData, setRefreshData] = useState(false);
  const [PollssList, setPollsList] = useState([]);
  const [VotesList, setVotesList] = useState([]);
  const [SocialList, setSocialList] = useState([])
  const [OperationList, setOperationList] = useState([])
  const [TagList, setTagList] = useState([])
  const [AccountList, setAccountList] = useState([])
  
    const tabData = [
        {
          name: 'Polls Form',
          icon: 'ballot',
          content:<PollForm AccountList={AccountList} TagList={TagList} OperationList={OperationList} SocialList={SocialList} />
        },
        {
          name: 'Polls List',
          icon: 'list',
          content:
          <TableElement 
          ExcludedFields={["userName","createdDate","expiryDate","operationName","scheduledAdjustmentName","orderState","finishedDate"]} 
          Headers={PollsCOLUMNS} 
          Type={"Polls"}   
          RefreshData={RefreshData} 
          setRefreshData={setRefreshData} 
          hasEdit={PollssList.length> 0} 
          Loader={Loader} 
          setLoader={setLoader} 
          hasDelete={true} 
          DataList={PollssList}
          setDataList={setPollsList}
          />
        },
        {
          name: 'Votes Form',
          icon: 'clipboard-list-check',
          content:<VoteForm RefreshData={RefreshData} setRefreshData={setRefreshData}  AccountList={AccountList} TagList={TagList}  OperationList={OperationList} SocialList={SocialList}/>
          
        },
        {
          name: 'Votes List',
          icon: 'list',
          content:
          <TableElement 
          Headers={VotesCOLUMNS} 
          Type={"Votes"}   
          RefreshData={RefreshData} 
          setRefreshData={setRefreshData} 
          hasEdit={false}  
          Loader={Loader} 
          setLoader={setLoader} 
          hasDelete={true} 
          DataList={VotesList}
          setDataList={setVotesList}
          />
        },
        
      ]
      useEffect(() => {
          setLoader(true);
          AxiosGetRequest(Urls.Polls.view,[{"HeadKey":"pageSize","HeadValue":500}]).then(response => {
            setLoader(false);
            if(response[0])
            {
              setPollsList(response[1].data)
            }
            else
              ToastError("Polls List List didn't load")
          })
          AxiosGetRequest(Urls.Votes.view,[{"HeadKey":"pageSize","HeadValue":500}]).then(response => {
            setLoader(false);
            if(response[0])
            {
              setVotesList(response[1].data)
            }
            else
              ToastError("Votes List List didn't load")
          })

      }, [RefreshData])


    return(
        <Col xl={12}>
          <Card>
            <Card.Body>
              <div className='default-tab'>
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                  <Nav as='ul' className='nav-tabs'>
                    {tabData.map((data, i) => (
                      <Nav.Item as='li' key={i}>
                        <Nav.Link eventKey={data.name.toLowerCase()}>
                          <i className={`fa-light fa-${data.icon} mr-2`} />
                          {data.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content className='pt-4'>
                    {tabData.map((data, i) => (
                      <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                        {data.content}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Card.Body>
          </Card>
        </Col>
    )
}