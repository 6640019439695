import { useState ,useRef} from "react";
import { Button, Card, Col, ListGroup, OverlayTrigger, Row, Spinner } from "react-bootstrap";
import { ChangeHandler, CheckEmpty, ClearData, read_clipboard } from "../../../Elements/Functions";
import { AccountColumn, GenderColumn, OperationColumn, PostFunction, RenderTooltip, SubjectColumn } from "../../../Elements/Elements";
import { PreventNegative } from "../../../../../services/MathMatics";



export default function ReportTweetForm(props) {
    const [SocialList, setSocialList] = useState([])
    const [OperationList, setOperationList] = useState([])
    const [AccountList, setAccountList] = useState([])
    const MaleCheckRef1 = useRef(null)
    const FemaleCheckRef1 = useRef(null)
    const [Loader, setLoader] = useState(false);
    const [Options, setOptions] = useState(
        {
          targetLink:"",
          countLikes:0,
          countReports:0,
          countRetweets:0,
          operationId:null,
          socialAreas:[],
          privateRobotAccountId:null,
          genderType:"FemaleMale",
        }
      );


    const SendReport = () => 
      {
          if(CheckEmpty(Options.targetLink,"Link","1"))
            if(CheckEmpty(Options.socialAreas.length,"Subject","1"))
              if(CheckEmpty(Options.genderType,"Gender","1"))
                    PostFunction("Tweet Report",'ReportOrder.tweets',Options,props.setRefreshData,props.RefreshData,setLoader)
      }
      const CleanData = 
      {
        targetLink:"",
        countLikes:0,
        countReports:0,
        countRetweets:0,
        operationId:null,
        socialAreas:[],
        privateRobotAccountId:null,
        genderType:null
      }

    return(
        <>
<div className=" mobile-send-button">
    <Button onClick={()=>SendReport()}>Send </Button>
</div>
<Card>
    <ListGroup >
        <div className="d-flex flex-row justify-content-between">
            <h4>Tweet Report form</h4>
        </div>
        <div className='input-group  mb-3 input-primary ' >
              <div className='input-group-prepend'>
                <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 100 }}overlay={RenderTooltip(1,"Paste Clipboard")}>
                  <span onClick={() =>read_clipboard("targetLink",setOptions,Options)} className='input-group-text c-pointer'><i className="fa-thin fa-paste"></i></span>
                </OverlayTrigger>
              </div>
              <input
                type='text'
                className='form-control input-default text-black'
                placeholder="Link"
                id={"Link2"}
                value={Options.targetLink} 
                onChange={(e) => ChangeHandler('targetLink',e.target.value,setOptions,Options)}/>
        </div>
    </ListGroup>
          <Row className={"w-100 ml-0 pt-3 pb-4 mt-4 " }  style={{borderTop:"1px solid #dbdbdb",justifyContent: "space-between"}} >
            <Col xl={3}>
            <div className='form-group'>
            <label><i className="fa fa-flag   mr-1"></i>Report(s) Count:</label>
              <input
                type='number'
                className='form-control input-default p-2  text-black'
                value={Options.countReports}
                id={"Reports1"}
                placeholder="Report"
                style={{height:"2.4rem"}}
                onChange={(e)=>ChangeHandler('countReports',e.target.value,setOptions,Options)}
                onKeyPress={(e)=>PreventNegative(e)}
              />
            </div>
            </Col>
          <GenderColumn CustomClass={"mt-1"} customID={2}  xl={"3"} Options={Options} setOptions={setOptions} MaleRef={MaleCheckRef1} FemaleRef={FemaleCheckRef1} />
        </Row>
        <Row className="w-100 ml-0 pt-3 pb-2"  style={{borderTop:"1px solid #dbdbdb"}} >
          <OperationColumn OperationList={OperationList} Options={Options} setOptions={setOptions}/>
          <SubjectColumn ID={"Subject1"} SocialList={SocialList} Options={Options} setOptions={setOptions}/>
          <AccountColumn AccountList={AccountList} Options={Options} setOptions={setOptions} />
        </Row>

</Card>
<Row className="flex-row-reverse" style={{gap:"0.6rem"}}>
  <Button onClick={()=> SendReport()}>Send </Button>
  <Button variant='outline-primary ' onClick={()=> ClearData(setOptions,CleanData)}>Clear </Button>
  {Loader ? <Spinner animation="border" variant="primary" /> : "" }
</Row>
</>
    )
}