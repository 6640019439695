import { useState ,createRef, useRef} from "react";
import { Button, Card, Col, ListGroup, Modal, OverlayTrigger, Row, Spinner } from "react-bootstrap";
import { ToasSucces, ToastError } from "../../../../services/Toasts";
import { TransitionGroup,CSSTransition } from "react-transition-group";
import Select from "react-select";
import { ImageGallery, RenderTooltip, SubjectColumn, TweetWithMedia } from "../../Elements/Elements";
import { AddTweet, ChangeHandler, GenderCheckbox } from "../../Elements/Functions";
import { AxiosPostRequest } from "../../../../services/Connections";
import { Urls } from "../../../../services/ServerandUrls";
import { useDropzone } from "react-dropzone";
 

export default function ContentForm (props) {
  const [selectedOption, setSelectedOption] = useState(false);
  const [StylesList, setStylesList] = useState([]);
  const [ListType, setListType] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const MaleCheckRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [Vids, setVids] = useState([]);
  const [selectedImages, setselectedImages] = useState([]);
  const [selectedTweet, setselectedTweet] = useState(0);
  const FemaleCheckRef = useRef(null);
  const [TweetList, setTweetList] = useState([
        {
            Text:"",
            Files:[],
            TempMedia:[],
            Media:null,
            id:0,
            nodeRef: createRef(null),
        },

    ]);
  const [Options, setOptions] = useState(
        {
          socialAreas: [],
          Style:"",
          genderType:"",
        }
      );

      
      const {getRootProps, getInputProps} = useDropzone({
        multiple:true ,
        accept: {
          'image/jpg': [".jpg"],
          'video/mp4': [".mp4"]
        },
        onDrop: acceptedFiles => {
          setLoader(true);
          for (let i = 0; i < acceptedFiles.length; i++) {
            let formData = new FormData();
            formData.append('File', acceptedFiles[i]);
            AxiosPostRequest(Urls.Upload,formData).then(response => {
              setLoader(false);
              if(response[0])
              {
                ToasSucces(acceptedFiles[i].name + " uploaded")
                Object.assign(acceptedFiles[i], {Address: response[1].data[0].savedFilePath});
                if(acceptedFiles[i].type == "video/mp4")
                  setVids(current => [...current, acceptedFiles[i]])
                else
                  setFiles(current => [...current, acceptedFiles[i]])
              }
              else
                ToastError(acceptedFiles[i].name + " didnt upload")
            })
            
          }
        }
      });


    // const AddTweet = () =>{
    //     const newTweet = 
    //     { 
    //     Text:"",
    //     Media:null,
    //     id:TweetList.length,
    //     nodeRef: createRef(null),
    //     }
    //     setTweetList(TweetList => [...TweetList, newTweet]);
    // }

    const RemoveTweet = (index) =>
    {       

        if(TweetList.length>1){
        const ActionItemsList = [...TweetList];
        ActionItemsList.splice(index, 1);
        setTweetList(ActionItemsList);
        }
        else
        ToastError("At least One Tweet is needed",10)
    }

    const ChangeTweet = (e,index) =>
    {
         if(e.target.value.length < 281){
          const List = [...TweetList];
          List[index].Text = e.target.value;
          setTweetList(List);
        }
        else
        return
    }

    const SendTweets = () => 
    {
      var TweetsLists = []
      for (let i = 0; i < TweetList.length; i++) 
        {
          var Tweeet = {}
          Tweeet.text = TweetList[i].Text;
          Tweeet.files = TweetList[i].Files;
          if(TweetList[i].TempMedia.length > 0)
          {
            if(TweetList[i].TempMedia[0].Type == "1")
            Tweeet.robotAccountTweetType = "VideoTweet";
            else
            Tweeet.robotAccountTweetType = "ImageTweet";
  
          }
          else
          Tweeet.robotAccountTweetType = "Tweet";
          Object.assign(Tweeet, Options);
  
          TweetsLists.push(Tweeet)
        }
        setLoader(true);
        AxiosPostRequest(Urls.Contents.add,TweetsLists).then(response=>{
          setLoader(false);
            if(response[0])
            {
              ToasSucces("Content(s) has been added successfully")
              props.setRefreshData(!props.RefreshData)
  
            }
            else
              ToastError("Content(s)  didn't Add")
        })
    }

	  const handleClose = () =>{
        setListType(false)
        setShow(false)
      }
    
    const handleModal = (index,mediaList) => 
      {
        setselectedImages(mediaList) 
        setShow(true);
        setselectedTweet(index)
      }

    const AddMedia = () => {
        let TempList = []
        selectedImages.forEach(element => {
          TempList.push(element.Img.Address)
        });
        const newState = TweetList.map((obj,index) => {
          if (index === selectedTweet) {
            return {...obj, Files: TempList ,TempMedia:selectedImages };
          }
          return obj;
        });
        setTweetList(newState)
        setListType(false)
        setShow(false)
      }
    

    return (
        <>
        <div className=" mobile-send-button">
          <Button onClick={()=>SendTweets()}>Send </Button>
        </div>
        <Card>
            <ListGroup >
                <div className="d-flex flex-row justify-content-between">
                    <h4>Contents</h4>
                    <div className="d-flex" style={{gap:"0.5rem"}}>
                    <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 100 }}overlay={RenderTooltip(props,<p className="p-0 m-0">Add new Tweet <br/> (after selecting a textbox, you can add Tweets by pressing CTRL + Q)</p>)}>
                    <div onClick={()=>AddTweet(TweetList,setTweetList)} className="p-1 rounded-circle d-flex justify-content-center align-items-center c-pointer" style={{width:"25px",height:"25px",background:"#026e31"}}>
                        <i className="fa-solid fa-plus text-white" ></i>
                    </div>
                    </OverlayTrigger>
                    </div>
                </div>
                <TransitionGroup>
                {TweetList.map((Tweet,index) =>(
                    <CSSTransition
                    key={index}
                    nodeRef={Tweet.nodeRef}
                    timeout={500}
                    classNames="item"
                  >
                    <ListGroup.Item style={{background:"#b5e0f117"}} className="rounded mb-2 mt-2 border border-primary"  ref={Tweet.nodeRef}>
                      <TweetWithMedia  TweetList={TweetList} setTweetList={setTweetList} Tweet={Tweet} Index={index} ChangeTweet={ChangeTweet} RemoveTweet={RemoveTweet} handleModal={handleModal} />
                    </ListGroup.Item>
                   </CSSTransition> 
                    ))}
                </TransitionGroup>
            </ListGroup>
                <Row className="w-100 ml-0 pt-3 mt-4"  style={{borderTop:"1px solid #dbdbdb"}}>
                    <SubjectColumn  Options={Options} setOptions={setOptions}/>
                </Row>
                <Row className="w-100 ml-0 pt-4 pb-4 mt-4"  style={{borderTop:"1px solid #dbdbdb"}}>
                  <Col className="d-flex align-items-center"  xl={2}>
                    <div className=' form-check'>
                        <label htmlFor="SingleAccount" className='form-check-label'>
                        Style:
                        </label>
                    </div>
                  </Col>
                  <Col xl={4}>
                  <Select isClearable isSearchable defaultValue={selectedOption} onChange={(e) => ChangeHandler('Style',e !=null ? e.value:null,setOptions,Options)}  options={StylesList}/>
                  </Col>
                  <Col  xl={6}>
                    <div className="d-flex flex-row mt-2">
                      <h5 className="col-lg-2 mb-0 text-nowrap">Gender:</h5>
                      <div style={{zIndex:0}} className='custom-control custom-checkbox mr-3 ml-3'>
                          <input 
                          type='checkbox' 
                          className='custom-control-input'
                          id='customCheckBox1' 
                          onChange={(e)=>GenderCheckbox("genderType",FemaleCheckRef,MaleCheckRef,setOptions,Options)}/>
                          <label className='custom-control-label' htmlFor='customCheckBox1'>
                            Male
                          </label>
                      </div>
                      <div style={{zIndex:0}}  className='custom-control custom-checkbox mr-3 ml-3'>
                          <input
                          type='checkbox'
                          className='custom-control-input'
                          id='customCheckBox2'
                          onChange={(e)=>GenderCheckbox("genderType",FemaleCheckRef,MaleCheckRef,setOptions,Options)}
                          />
                          <label className='custom-control-label' htmlFor='customCheckBox2'>
                          Female
                          </label>
                      </div>
                    </div>
                    </Col>
                </Row>
        </Card>
       <Row className="flex-row-reverse" style={{gap:"0.6rem"}}>
          <Button onClick={()=>SendTweets()}>Send </Button>
          <Button variant='outline-primary '>Clear </Button>
          {Loader ? <Spinner className="mt-3" animation="border" variant="primary" /> : "" }

       </Row>
              <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
          <Modal.Title>Add Media</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <section className="container ">
              <div {...getRootProps({className: 'dropzone border mb-3 d-flex c-pointer justify-content-center align-items-center '},)}>
                <input {...getInputProps()} />
                <p className="mb-0 pl-2 pr-2 pb-3 pt-3 text-center">Drag and drop media files here, or click to select files<br></br> <span className="fs-15 text-black">only Jpg and mp4</span></p>
              </div>
              <div class="custom-control custom-switch mb-2">
                <input type="checkbox" class="custom-control-input CustomInputSwitch" onChange={(e) => setListType(e.target.checked)} id="customSwitch1"/>
                <label class="custom-control-label CustomLabelSwitch" for="customSwitch1">{ListType ? "Video" : "Image"}</label>
              </div>
              <aside>
                <ImageGallery ListType={ListType} selectedImages={selectedImages} setselectedImages={setselectedImages}  Images={files} Vids={Vids} selectedTweet={selectedTweet}></ImageGallery>
              </aside>
            </section>
          </Modal.Body>
          <Modal.Footer>
          {Loader ? <Spinner animation="border" variant="primary" /> : "" }
        
          <Button onClick={() => handleClose()} variant="outline-primary">
            cancel
          </Button>
          <Button onClick={() =>AddMedia()} variant="primary" >
            Save Changes
          </Button>
          </Modal.Footer>
	      </Modal>

        </>
    )
}