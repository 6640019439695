
import { useEffect, useState } from "react";
import { Card, Col, Nav, Tab } from "react-bootstrap";
import { AxiosGetRequest } from "../../../../services/Connections";
import { Urls } from "../../../../services/ServerandUrls";
import { ToastError } from "../../../../services/Toasts";
import TableElement from "../../FilteringTable/TableElment";
import { RTCOLUMNS } from "./Columns";
import RetweetsForm from "./RetweetFrom";



export default function RetweetsWithComment(props) {
	const [Loader, setLoader] = useState(false);
  const [DataList, setDataList] = useState([]);
	const [RefreshData, setRefreshData] = useState(false);

    const tabData = [
        {
          name: 'Retweets With Comment Form',
          icon: 'ballot',
          content:<RetweetsForm RefreshData={RefreshData} setRefreshData={setRefreshData} />
        },
        {
          name: 'Retweets With Comment List',
          icon: 'list',
          content:
          <TableElement 
          ExcludedFields={["userName","createdDate","expiryDate","operationName","scheduledAdjustmentName","orderState","finishedDate"]} 
          Headers={RTCOLUMNS} 
          Type={"Retweets"}   
          RefreshData={RefreshData} 
          setRefreshData={setRefreshData} 
          hasEdit={DataList.length> 0} 
          Loader={Loader} 
          setLoader={setLoader} 
          hasDelete={true} 
          DataList={DataList}
          setDataList={setDataList}
          
          />
        },
        
      ]

      useEffect(() => {
        setLoader(true);
        AxiosGetRequest(Urls.Retweets.view,[{"HeadKey":"pageSize","HeadValue":500}]).then(response => {
          setLoader(false);
          if(response[0])
          {
            setDataList(response[1].data)
          }
          else
            ToastError("Tweet List didn't load")
        })
      
      }, [RefreshData])
      



    return(
        <Col xl={12}>
          <Card>
            <Card.Body>
              {/* <!-- Nav tabs --> */}
              <div className='default-tab'>
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                  <Nav as='ul' className='nav-tabs'>
                    {tabData.map((data, i) => (
                      <Nav.Item as='li' key={i}>
                        <Nav.Link eventKey={data.name.toLowerCase()}>
                          <i className={`fa-light fa-${data.icon} mr-2`} />
                          {data.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content className='pt-4'>
                    {tabData.map((data, i) => (
                      <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                        {data.content}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Card.Body>
          </Card>
        </Col>
    )
}