import { useState , useEffect, useRef} from "react";
import { Button, Card, Col, ListGroup, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
// import { ToastError } from "../../../../services/Toasts";
import { PreventNegative } from "../../../../services/MathMatics";
import { ChangeHandler, CheckEmpty, ClearData, read_clipboard } from "../../Elements/Functions";
import { AccountColumn, GenderColumn, OperationColumn, SubjectColumn } from "../../Elements/Elements";
import { AxiosGetRequest, AxiosPostRequest } from "../../../../services/Connections";
import { Urls } from "../../../../services/ServerandUrls";
import { ToasSucces, ToastError } from "../../../../services/Toasts";




export default function HashtagCoverForm (props) {

    const [ViewOnlyVal1, setViewOnlyVal1] = useState(false);
    const [SocialList, setSocialList] = useState([])
    const [AvailAccounts1, setAvailAccounts1] = useState(0)
    const [OperationList, setOperationList] = useState([])
    const [AccountList1, setAccountList1] = useState([])
    const MaleCheckRef = useRef(null)
    const FemaleCheckRef = useRef(null)
    const [Loader, setLoader] = useState(false);
    const [HashOptions, setHashOptions] = useState(
      {
        title:"",
        countLikes:0,
        countRetweets:0,
        countAccounts:0,
        operationId:null,
        socialAreas:[],
        NumSeeTweet:0,
        IsFromTopTweetPage:false,
        isNeedFollowInteractions:false,
        isJustSee:false,
        privateRobotAccountId:null,
        genderType:"FemaleMale"
      }
    );
   

    const CleanData = {
      title:"",
      countLikes:0,
      NumSeeTweet:0,
      IsFromTopTweetPage:false,
      countRetweets:0,
      countAccounts:0,
      operationId:null,
      socialAreas:[],
      privateRobotAccountId:null,
      genderType:null
    }

    const ViewOnlyFunction = (Value) => {
      console.log(HashOptions)
      setViewOnlyVal1(Value)
      setHashOptions({
        ...HashOptions,
        countLikes: 0 ,
        countRetweets:0,
        isJustSee:Value
      });
    }

    const SendCover = () => {
      if(HashOptions.countAccounts <=  AvailAccounts1)
      {
      if(CheckEmpty(HashOptions.title,"Hashtag","1"))
        if(CheckEmpty(HashOptions.socialAreas.length,"Subject","2"))
          if(CheckEmpty(HashOptions.genderType,"Gender","2"))
               {
                setLoader(true)
                AxiosPostRequest(Urls.HashtagCovers.add,[HashOptions]).then(response => {
                  setLoader(false);
                  if(response[0])
                  {
                    ToasSucces("Cover has beeen submitted")
                    props.setRefreshData(!props.RefreshData);
                  }
                  else
                    ToastError("Cover couldn't be send")
                })
               }
        }
        else
        {
          ToastError("Not enough accounts to run command",1)
        }
    }

      useEffect(() => {
        if(JSON.parse(localStorage.getItem("SocialList")) !== null )
            setSocialList(JSON.parse(localStorage.getItem("SocialList")))
        
          if(JSON.parse(localStorage.getItem("OperationList")) !== null )
            setOperationList(JSON.parse(localStorage.getItem("OperationList")))

            if(JSON.parse(localStorage.getItem("AccountsList")) !== null )
            setAccountList1(JSON.parse(localStorage.getItem("AccountsList")))
            setLoader(true)
          AxiosGetRequest(Urls.Acounts.AvailAccounts,[{"HeadKey":"orderType","HeadValue":"Supporthashtag"}]).then(response => {
          setLoader(false)
          console.log('response[1].data :>> ', response[1]);
            if(response[0])
            {
              setAvailAccounts1(response[1].data)
            }
          })
          
      }, [])

    return(
        <>
        <div className=" mobile-send-button">
          <Button onClick={()=>SendCover()}>Send </Button>
        </div>
        <Card>
            <ListGroup >
                <div className="d-flex flex-row justify-content-between">
                    <h4>Hashtag Cover form</h4>
                </div>
                <div className='input-group  mb-3 input-primary ' >
                  <div className='input-group-prepend'>
                    <OverlayTrigger placement="bottom" delay={{ show: 500, hide: 100 }}overlay={
                      <Tooltip id="button-tooltip">
                        Paste Clipboard
                      </Tooltip>
                    }>
                      <span onClick={() =>read_clipboard("title",setHashOptions,HashOptions)} className='input-group-text c-pointer'><i className="fa-thin fa-paste"></i></span>
                    </OverlayTrigger>
                  </div>
                  <input
                    type='text'
                    id={"Hashtag1"}
                    className='form-control input-default text-black'
                    placeholder="#Hashtag"
                    value={HashOptions.title}
                    onChange={(e) => ChangeHandler('title',e.target.value,setHashOptions,HashOptions)}/>
                </div>
            </ListGroup>
            <Row className="w-100 ml-0 pt-3 mt-4"  style={{borderTop:"1px solid #dbdbdb"}} >
              <Col xl={3}>
                <div className='form-group'>
                  <label  htmlFor={"Likes2"}><i className="fa fa-heart mr-1"></i>Likes:</label>
                  <input
                    type='number'
                    className='form-control input-default p-2  text-black '
                    placeholder="Likes"
                    id={"Likes2"}
                    style={{height:"2.4rem"}}
                    value={HashOptions.countLikes}
                    onKeyPress={(e)=>PreventNegative(e)}
                    onChange={(e)=>ChangeHandler('countLikes',e.target.value,setHashOptions,HashOptions)}
                    disabled={ViewOnlyVal1}
                  />
                </div>
              </Col>
              <Col xl={3}>
                <div className='form-group'>
                <label htmlFor={"Retweets2"}><i className="fa fa-retweet mr-1"></i>Retweet:</label>
                  <input
                    type='number'
                    id={"Retweets2"}
                    className='form-control input-default p-2  text-black '
                    placeholder="Retweet"
                    value={HashOptions.countRetweets}
                    style={{height:"2.4rem"}}
                    onKeyPress={(e)=>PreventNegative(e)}
                    onChange={(e)=>ChangeHandler('countRetweets',e.target.value,setHashOptions,HashOptions)}
                    disabled={ViewOnlyVal1}
                  />
                </div>
              </Col>
              <Col xl={3}>
                <div className='form-group'>
                <label htmlFor={"Users_Count"}><i className="fa fa-user mr-1"></i>Users Count:</label>
                  <input
                    type='number'
                    htmlFor={"Users_Count"}
                    className='form-control input-default p-2  text-black'
                    placeholder="Users Count"
                    value={HashOptions.countAccounts}
                    onKeyPress={(e)=>PreventNegative(e)}
                    onChange={(e)=>ChangeHandler('countAccounts',e.target.value,setHashOptions,HashOptions)}
                    style={{height:"2.4rem"}}
                  />
                </div>
              </Col>
              <Col xl={2}>
                <div className='form-group'>
                <label htmlFor={"Observer_accounts"}><i className="fa fa-user mr-1"></i>Max tweets checked in hashtag:</label>
                  <input
                    type='number'
                    htmlFor={"Observer_accounts"}
                    className='form-control input-default p-2  text-black'
                    placeholder="Observer accounts"
                    value={HashOptions.NumSeeTweet}
                    onKeyPress={(e)=>PreventNegative(e)}
                    onChange={(e)=>ChangeHandler('NumSeeTweet',e.target.value,setHashOptions,HashOptions)}
                    style={{height:"2.4rem"}}
                  />
                </div>
              </Col>
            </Row>
                <Row className="w-100 ml-0 pt-3"  style={{borderTop:"1px solid #dbdbdb"}} >
                  <OperationColumn OperationList={OperationList} Options={HashOptions} setOptions={setHashOptions}/>
                  <SubjectColumn ID={"Subject2"} SocialList={SocialList} Options={HashOptions} setOptions={setHashOptions} setAvailAccounts={setAvailAccounts1} setLoader={setLoader} type={"Supporthashtag"}/>
                  <AccountColumn AccountList={AccountList1} Options={HashOptions} setOptions={setHashOptions} />
                </Row>
                <Row className="w-100 ml-0 pt-4 pb-4 mt-4"  style={{borderTop:"1px solid #dbdbdb"}}>
                  <Col xl={3} sm={8}  className="pt-0">
                    <GenderColumn customID={2} xl={"none"} CustomClass={"mt-0"} Options={HashOptions} setOptions={setHashOptions} MaleRef={MaleCheckRef} FemaleRef={FemaleCheckRef} />
                  </Col>
                  <Col xl={6} className={"d-flex"}>
                  <label> Additional Options: </label>
                      <Col>
                        <div style={{zIndex:0}} className='custom-control custom-checkbox mr-3 ml-3'>
                            <input
                            type='checkbox'                      
                            className='custom-control-input'
                            id='View_Only2'
                            onChange={(e)=>ViewOnlyFunction(e.target.checked)}
                            />
                            <label
                            className='custom-control-label text-nowrap'
                            htmlFor='View_Only2'
                            >
                            View Only
                            <OverlayTrigger  placement="bottom" delay={{ show: 500, hide: 100 }}overlay={
                              <Tooltip id="button-tooltip">
                                Just viewing tweets and adding to their impression.
                                this will disable Likes and Retweet actions.
                              </Tooltip>
                            }>
                            <i class="fa-thin fa-exclamation-circle c-pointer ml-1"></i>
                            </OverlayTrigger>
                            </label>
                        </div>
                      </Col>
                      <Col>
                        <div style={{zIndex:0}} className='custom-control custom-checkbox mr-3 ml-3'>
                            <input
                            type='checkbox'                      
                            className='custom-control-input'
                            id='Follow_interactions1'
                            onChange={(e)=>ChangeHandler('isNeedFollowInteractions',e.target.checked,setHashOptions,HashOptions)}
                            />
                            <label
                            className='custom-control-label text-nowrap'
                            htmlFor='Follow_interactions1'
                            >
                            Follow hashtag interactions
                            <OverlayTrigger  placement="bottom" delay={{ show: 500, hide: 100 }}overlay={
                              <Tooltip id="button-tooltip">
                                Follow users who has interactions(like,retweet,etc...) with this Hashtag.
                              </Tooltip>
                            }>
                            <i class="fa-thin fa-exclamation-circle c-pointer ml-1"></i>
                            </OverlayTrigger>
                            </label>
                        </div>
                      </Col>
                  </Col>
                  <Col xl={3} sm={8}  className="pt-0">
                  <div style={{display:"flex",gap:"1rem"}}>
                      <label>
                        <input
                          type="radio"
                          className="mr-2"
                          checked={HashOptions.IsFromTopTweetPage === false}
                          onChange={() => (
                            setHashOptions({
                              ...HashOptions,
                              IsFromTopTweetPage:false
                            })
                          )}
                        />
                        Latest Tweet
                      </label>
                      <label>
                        <input
                          type="radio"
                          className="mr-2"
                          checked={HashOptions.IsFromTopTweetPage === true}
                          onChange={() => (
                            setHashOptions({
                              ...HashOptions,
                              IsFromTopTweetPage:true
                            })
                          )
                        }
                        />
                        Top Tweet
                      </label>
                    </div>
                  </Col>
                </Row>
        </Card>
       <Row className="flex-row-reverse" style={{gap:"0.6rem"}}>
          <Button onClick={()=>SendCover()}>Send </Button>
          <Button variant='outline-primary ' onClick={()=> ClearData(setHashOptions,CleanData)} >Clear </Button>
          <h5 className="mt-3 mr-2"> Available Accounts : {AvailAccounts1}</h5>
          {Loader ? <Spinner animation="border" variant="primary" /> : "" }
       </Row>
       </>
    )
}